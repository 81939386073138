import React, { useEffect, useState } from "react";
import {
  formatDateHourToBR,
  saveData,
} from "../../../functions/functionsUtility";
import { DescriptionPeriodValue } from "../../../layout";
import { getData } from "../../../functions/request";
import { dateMask } from "../../../functions/utils";
const dayjs = require("dayjs");

export default function InterboisMercadoFuturo() {
  const [cotacoesData, setCotacoesData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getData("boi_futuro", "getBoiFuturo", "insertBoiFuturo")
      .then((data) => {
        setCotacoesData(data);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setHasError(err?.message ?? "Ocorreu um erro.");
        console.log(err);
      });
  }, []);

  const [date, setDate] = useState({
    month: "",
    year: dayjs().year().toString(),
  });
  const onChange = (value, type) => {
    if (type === "month") {
      setDate((prevState) => ({ ...prevState, month: value }));
    } else if (type === "year") {
      setDate((prevState) => ({ ...prevState, year: value }));
    }
  };

  useEffect(() => {
    setCotacoesData((prevState) => ({
      ...prevState,
      fut_periodo: dayjs(`01/${date.month}/${date.year}`, "DD/MM/YYYY").format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      ),
    }));
  }, [date]);

  return (
    <>
      <DescriptionPeriodValue
        title={"Interbois - Mercado Futuro"}
        onClickButton={() => saveData(cotacoesData)}
        date={formatDateHourToBR(cotacoesData.fut_data)}
        datePickerValue={date.year}
        datePickerOnChange={onChange}
        // inputValue={cotacoesData["fut_valor"]}
        state={cotacoesData}
        setState={setCotacoesData}
        stateKey={"fut_valor"}
        isLoading={isLoading}
        hasError={hasError}
      />
    </>
  );
}
