import { Form, Typography } from "antd";
import { Content } from "antd/es/layout/layout";

export function ConsultForm({
  title,
  layout = "vertical",
  onFinish,
  resetStyles = false,
  style,
  extra,
  children,
  ...props
}) {
  return (
    <Form
      bordered
      layout={layout}
      onFinish={(values) => onFinish(values)}
      style={{
        width: "100%",
        height: "100%",
        padding: 10,
        borderRight: "1px solid lightgray",
        zIndex: 10,
      }}
      className="slide-right"
      {...props}
    >
      <Content
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 10,
        }}
      >
        <Typography.Title
          level={4}
          style={{ color: "#303030", margin: 0, paddingBottom: 10 }}
        >
          {title}
        </Typography.Title>
        {extra}
      </Content>
      {children}
    </Form>
  );
}
