import { Button, Input, Select } from "antd";
import React, { useState } from "react";
import { getDataWithEndpoint } from "../../../functions/request";
import DescriptionsLayout from "../../../layout/descriptions/DescriptionsLayout";
import { updateData } from "../../../functions/functionsUtility";
import { FaSave } from "react-icons/fa";

const cotacoesLabel = [
  { value: "Gado Gordo", label: "Gado Gordo", span: 3 },
  {
    value: "Boi Gordo (Máxima a Vista)",
    label: "Boi Gordo (Máxima a Vista)",
    span: 3,
  },
  {
    value: "Vaca Gorda (Máxima a Vista)",
    label: "Vaca Gorda (Máxima a Vista)",
    span: 3,
  },
  { value: "Boi Gordo (a Vista)", label: "Boi Gordo (a Vista)", span: 3 },
  { value: "Vaca Gorda (a Vista)", label: "Vaca Gorda (a Vista)", span: 3 },
  { value: "Gado Magro", label: "Gado Magro Nelore Macho", span: 2 },
  { value: "Gado Magro", label: "Gado Magro Nelore Fêmea", span: 2 },
  { value: "Bezerro - 200Kg", label: "Bezerro - 200Kg", span: 2 },
  { value: "Bezerra - 180Kg", label: "Bezerra - 180Kg", span: 2 },
  { value: "Garrote - 270Kg", label: "Garrote - 270Kg", span: 2 },
  { value: "Novilha - 230Kg", label: "Novilha - 230Kg", span: 2 },
  { value: "Garrote - 300Kg", label: "Garrote - 300Kg", span: 2 },
  { value: "Novilha - 275Kg", label: "Novilha - 275Kg", span: 2 },
  { value: "Boi Magro - 360Kg", label: "Boi Magro - 360Kg", span: 2 },
  { value: "Vaca - 360Kg", label: "Vaca - 360Kg", span: 2 },
];

export default function InterboisPorEstado() {
  const estados = [
    { value: "BA", label: "Bahia" },
    { value: "GO", label: "Goiás" },
    { value: "MA", label: "Maranhão" },
    { value: "MT", label: "Mato Grosso" },
    { value: "MN", label: "Mato Grosso (Norte)" },
    { value: "MS", label: "Mato Grosso do Sul" },
    { value: "MG", label: "Minas Gerais" },
    { value: "TR", label: "Minas Gerais (Triangulo Mineiro)" },
    { value: "PA", label: "Pará" },
    { value: "PR", label: "Paraná" },
    { value: "RJ", label: "Rio de Janeiro" },
    { value: "RS", label: "Rio Grande do Sul" },
    { value: "RF", label: "Rio Grande do Sul (Fronteira)" },
    { value: "SC", label: "Santa Catarina" },
    { value: "SP", label: "São Paulo" },
    { value: "RO", label: "Rondônia" },
    { value: "TO", label: "Tocantins" },
  ];
  const [cotacoes, setCotacoes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const items = [
    {
      label: "Estado",
      children: (
        <Select
          showSearch
          style={{ width: "100%" }}
          optionFilterProp="children"
          placeholder="Selecione um estado"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={estados}
          loading={isLoading}
          onChange={(value) => {
            setIsLoading(true);
            setCotacoes([]);
            getDataWithEndpoint(
              "boi_boicotacao",
              `getBoiCotacao?uf=${encodeURIComponent(value)}`
            )
              .then((res) => {
                const data = Object.values(res);
                data?.map(
                  (d) =>
                    //filtrando insert e path que vem da funcao
                    Array.isArray(d) &&
                    setCotacoes((prevState) => [...prevState, d])
                );
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
          allowClear={true}
        />
      ),
      span: 3,
    },
  ];

  const aux = cotacoesLabel.map((value, index) => {
    if (cotacoes.length > 0) {
      const cot = Object.entries(cotacoes).find(
        (element) => element[1][0]?.boi_nome === value.value
      );
      console.log(cot);
      if (cot) {
        return {
          label: value.label,
          span: value.span,
          children: (
            <Input
              value={cot[1][0]?.boi_valor}
              disabled={isLoading}
              onChange={(event) => {
                const inputValue = event.target.value;
                const formattedValue = inputValue;
                setCotacoes((prevState) => {
                  const newState = [...prevState];
                  newState[cot[0]] = [...newState[cot[0]]];
                  newState[cot[0]][0] = {
                    ...newState[cot[0]][0],
                    boi_valor: formattedValue.trim(),
                  };
                  return newState;
                });
              }}
            />
          ),
        };
      } else {
        return {
          label: value.label,
          span: value.span,
        };
      }
    }
  });

  console.log(cotacoes);

  return (
    <>
      <DescriptionsLayout
        title={"Interbois - Cotações por Estado"}
        items={[...items, ...aux.filter(Boolean)]}
        extra={
          <div style={{ padding: 10, display: "flex", gap: 10 }}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              icon={<FaSave />}
              disabled={isLoading}
              onClick={async () => {
                updateData({
                  ...cotacoes,
                  endpoint: "updateBoiCotacao",
                  path: "boi_boicotacao",
                });
              }}
            >
              Atualizar
            </Button>
          </div>
        }
      />
    </>
  );
}
