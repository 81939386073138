import React, { useEffect, useState } from "react";
import { request } from "../../functions/request";
import { Input, Select, Button, Space, Descriptions, Image } from "antd";
import { formatDateToBR, optionsTag } from "../../functions/functionsUtility";
import { toast } from "react-toastify";
import { Checkbox, Radio } from "antd";
import { LuStarOff, LuStar } from "react-icons/lu";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";
import { FaSave, FaTrash } from "react-icons/fa";
import Editor from "../../components/Jodit/EditorTexto";
import DrawerImages from "../../components/ui/Drawer/DrawerImages";
import { useLocation, useNavigate } from "react-router-dom";

function InserirNoticias() {
  const [data, setData] = useState(null);
  const [content, setContent] = useState("");
  const [titlePage, setTitlePage] = useState("");
  const [title, setTitle] = useState("");
  const [tag, setTag] = useState("");
  const [lead1, setLead1] = useState("");
  const [lead2, setLead2] = useState("");
  const [secao, setSecao] = useState("");
  const [importancia, setImportancia] = useState("0"); // Alteração aqui
  const [optionsAdicional, setOptionsAdicional] = useState({
    Destaque: false,
    Espanhol: false,
    Tv: false,
    "Rel.Research": false,
    "Rural Biz(G:30)(C:20)": false,
  });
  const [drawer, setDrawer] = useState({
    isOpen: false,
    data: null,
  });
  const [formattedDate, setFormattedDate] = useState("");
  const [notImagem, setNotImagem] = useState(null);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const optionsSection = [
    { value: false, label: "Todos" },
    { value: 1, label: "Boi" },
    { value: 2, label: "Frango" },
    { value: 3, label: "Grãos" },
    { value: 4, label: "Leite" },
    { value: 5, label: "Suinos" },
    { value: 8, label: "Agroações" },
    { value: 9, label: "Bolsas e Opções" },
  ];

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        setTitlePage("Inserir Nova Notícia");
        setContent("");
        setData(null);
        setTitle("");
        setTag("");
        setLead1("");
        setLead2("");
        setSecao("");
        setImportancia("0");
        setFormattedDate("");
        setNotImagem(null);
        setOptionsAdicional({
          Destaque: false,
          Espanhol: false,
          Tv: false,
          "Rel.Research": false,
          "Rural Biz(G:30)(C:20)": false,
        });

        const currentPathWithQuery = pathname + search;

        const response = await request(currentPathWithQuery, {
          method: "GET",
        });

        if (response.length === 0) {
          navigate("/noticia");
        }

        setTitlePage("Editar Notícia");

        console.log("response onEdit:", response);

        setData(response[0]);

        setContent(response[0]?.not_texto || "");

        setTitle(response[0]?.not_titulo || "");

        setSecao(response[0]?.not_secao || "");

        setTag(response[0]?.not_tag || "");

        setLead1(response[0]?.not_lead || "");

        setLead2(response[0]?.not_lead_curto || "");

        setOptionsAdicional({
          Destaque: response[0]?.not_destaque === 1,
          Espanhol: response[0]?.not_espanhol === 1,
          Tv: response[0]?.not_tv === 1,
          "Rel.Research": response[0]?.not_relresearch === 1,
          "Rural Biz(G:30)(C:20)": response[0]?.not_biz === 1,
        });

        setImportancia(response[0]?.nivel.toString() || "0");

        const date = new Date(response[0]?.not_data);

        setFormattedDate(
          `${formatDateToBR(date)} às ${date.getHours()}:${date.getMinutes()}`
        );

        await request(`/imagem/getImagemByCodigo/${response[0]?.not_imagem}`, {
          method: "GET",
        }).then((res) => {
          console.log(res);
          setNotImagem((prevState) => ({
            ...prevState,
            key: response[0]?.not_imagem,
            img_arquivo: res[0]?.img_arquivo ?? null,
          }));
        });
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    };
    fetchTableData();
  }, [pathname, search]);

  const handleSave = () => {
    const newData = {
      not_titulo: title.trim().replace(/\s+/g, " "),
      not_lead: lead1.trim().replace(/\s+/g, " "),
      not_lead_curto: lead2.trim().replace(/\s+/g, " "),
      not_secao: secao,
      nivel: importancia,
      not_site: 0,
      not_estacao: 0,
      not_espanhol: optionsAdicional["Espanhol"],
      not_biz: optionsAdicional["Rural Biz(G:30)(C:20)"],
      not_tv: optionsAdicional["Tv"],
      not_destaque: optionsAdicional["Destaque"],
      not_relresearch: optionsAdicional["Rel.Research"],
      not_tag: tag,
      not_imagem: notImagem?.key || null,
      not_texto: content || "",
    };

    if (newData.not_titulo === "" || newData.not_texto === "" || !secao) {
      toast.error("Preencha o Título, Notícia e Seção!");
      return;
    }

    let currentPathWithQuery;

    if (formattedDate) {
      currentPathWithQuery = `/noticia/updateNoticia/${data?.not_numero}`;
    } else {
      currentPathWithQuery = `/noticia/insertNoticia`;
    }

    console.log("newData:", newData);
    request(currentPathWithQuery, {
      method: "POST",
      body: JSON.stringify({ newData: newData }),
    }).then(() => {
      toast.success("Notícia salva com sucesso!");
      refreshPage();
    });
  };

  const deleteItem = () => {
    const currentPathWithQuery = `/noticia/deleteNoticia/${data?.not_numero}`;
    if (window.confirm("Deseja realmente apagar esta notícia?")) {
      request(currentPathWithQuery, {
        method: "DELETE",
      }).then(() => {
        toast.success("Analise deletada com sucesso!");
        refreshPage("/noticia/inserir_noticias");
      });
    }
  };

  function refreshPage(path = 0, timeout = 1000) {
    setTimeout(() => {
      navigate(path, { replace: true });
    }, timeout);
  }

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleSecaoChange = (value) => {
    setSecao(value);
  };

  const handleTagChange = (value) => {
    setTag(value);
  };

  const handleImportanciaChange = (e) => {
    setImportancia(e.target.value);
  };

  const handleLead1Change = (event) => {
    setLead1(event.target.value);
  };

  const handleLead2Change = (event) => {
    setLead2(event.target.value);
  };

  const openPopup = () => {
    const popup = window.open(
      "/multimidia/banco_imagens_popup",
      "",
      "popup,width=925,height=600"
    );

    window.onChooseImage = (data) => {
      setNotImagem(data);
      popup.close();
    };
  };

  const onClearImage = () => {
    setNotImagem(null);
  };

  return (
    <Content>
      <DrawerImages
        open={drawer.isOpen}
        onClose={() =>
          setDrawer((prevState) => ({ ...prevState, isOpen: false }))
        }
        data={drawer.data}
      />
      <Descriptions
        title={titlePage}
        bordered
        style={{ padding: 10 }}
        extra={
          <div style={{ padding: 10, display: "flex", gap: 10 }}>
            {formattedDate && (
              <Button
                type="primary"
                danger
                size="large"
                icon={<FaTrash />}
                onClick={deleteItem}
              >
                Excluir
              </Button>
            )}
            <Button
              type="primary"
              size="large"
              icon={<FaSave />}
              onClick={handleSave}
            >
              {formattedDate ? "Atualizar" : "Inserir"}
            </Button>
          </div>
        }
      >
        <Descriptions.Item
          label={"Titulo"}
          span={3}
          children={
            <Input
              style={{ width: "100%" }}
              placeholder="Digite o título da notícia"
              onChange={handleTitleChange}
              value={title}
            />
          }
        />
        <Descriptions.Item
          label={"Sub-lead 1"}
          span={2}
          children={
            <Input
              style={{ width: "100%" }}
              placeholder="Digite o título do Sub-lead"
              value={lead1}
              onChange={handleLead1Change}
            />
          }
        />
        <Descriptions.Item
          label={"Sub-lead 2"}
          span={2}
          children={
            <Input
              style={{ width: "100%" }}
              value={lead2}
              onChange={handleLead2Change}
              placeholder="Digite o título do Sub-lead"
            />
          }
        />
        <Descriptions.Item
          label={"Imagem"}
          span={2}
          children={
            <Space style={{ display: "flex", alignItems: "flex-start" }}>
              {notImagem?.img_arquivo && (
                <Image
                  preview={{ height: "50%" }}
                  height={"70px"}
                  src={`${process.env.REACT_APP_BANCO_IMG_URL}${notImagem?.img_arquivo}`}
                />
              )}
              <Content
                style={{
                  display: "flex",
                  flexDirection: notImagem?.img_arquivo ? "column" : "row",
                  gap: 10,
                }}
              >
                <Button
                  onClick={() => openPopup()}
                  type="primary"
                  disabled={notImagem?.img_arquivo && notImagem.key}
                >
                  {notImagem?.img_arquivo && notImagem.key
                    ? notImagem.img_arquivo
                    : "Escolher imagem"}
                </Button>
                <Button
                  icon={<UploadOutlined />}
                  onClick={() =>
                    setDrawer((prevState) => ({ ...prevState, isOpen: true }))
                  }
                >
                  Adicionar Nova Imagem
                </Button>
              </Content>
              {notImagem?.img_arquivo && (
                <Button
                  danger
                  type="primary"
                  icon={<DeleteOutlined />}
                  disabled={!notImagem}
                  onClick={onClearImage}
                />
              )}
            </Space>
          }
        />
        <Descriptions.Item
          label={"Importância"}
          span={1}
          children={
            <Radio.Group
              defaultValue="0"
              buttonStyle="solid"
              value={importancia}
              onChange={handleImportanciaChange}
            >
              <Radio.Button value="0">
                <LuStarOff />
              </Radio.Button>
              <Radio.Button value="1">
                <LuStar />
              </Radio.Button>
              <Radio.Button value="2">
                <LuStar />
                <LuStar />
              </Radio.Button>
            </Radio.Group>
          }
        />

        <Descriptions.Item
          label={"Seção"}
          span={2}
          children={
            <Select
              placeholder="Selecione uma Seção"
              onChange={handleSecaoChange}
              value={secao}
              style={{ width: "100%" }}
            >
              {optionsSection.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          }
        />
        <Descriptions.Item
          label={"Tag"}
          span={1}
          children={
            <Select
              style={{ width: "100%" }}
              placeholder="Selecione uma tag"
              onChange={handleTagChange}
              value={tag}
              showSearch
              filterOption={(input, option) =>
                (option?.label.toLocaleLowerCase() ?? "").includes(
                  input?.toLocaleLowerCase()
                )
              }
              options={optionsTag}
            />
          }
        />
        <Descriptions.Item
          label={"Opções Adicionais"}
          span={1}
          children={Object.keys(optionsAdicional).map((key) => (
            <Checkbox
              key={key}
              checked={!!optionsAdicional[key]}
              onChange={(e) =>
                setOptionsAdicional({
                  ...optionsAdicional,
                  [key]: e.target.checked,
                })
              }
            >
              {key}
            </Checkbox>
          ))}
        />
      </Descriptions>

      <Editor content={content} setContent={setContent} />
    </Content>
  );
}

export default InserirNoticias;
