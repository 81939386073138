import React, { useState } from "react";
import { getDataWithEndpoint, request } from "../../../functions/request";
import {
  formatDateHourToBR,
  updateData,
} from "../../../functions/functionsUtility";
import { Button, Input, Select } from "antd";
import { DescriptionsLayout } from "../../../layout";
import { FaSave } from "react-icons/fa";

const estados = [{ value: "SP", label: "São Paulo" }];

const cotacoesLabel = [
  { label: "PA Boi c/ Osso Resfriado", value: "pa_boi_cosso" },
  { label: "PA Vaca c/ Osso Resfriado", value: "pa_vaca_cosso" },
  { label: "PA s/ Osso", value: "pa_sosso" },
  { label: "Dianteiro s/ Osso", value: "diant_sosso" },
  { label: "Diant. Boi c/ Osso Resfriado", value: "diant_boi_cosso" },
  { label: "Diant. Vaca c/ Osso Resfriado", value: "diant_vaca_cosso" },
  { label: "Carne Industrial", value: "carne_ind" },
  { label: "Carne Industrial Especial", value: "carne_ind_esp" },
  { label: "Carne Conserva", value: "carne_cons" },
  { label: "Carne Sangria", value: "carne_sang" },
  { label: "Músculo Dianteiro", value: "musc_diant" },
  { label: "Osso do Patinho", value: "osso_pat" },
];

export default function InterboisCarneIndustrializada() {
  const [cotacoesData, setCotacoesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const aux = Object.entries(cotacoesData).map(([key, value], index) => {
    if (key !== "boi_data" && key !== "boi_uf") {
      const cotLabel = cotacoesLabel.find((label) => label.value === key);
      return {
        label: cotLabel ? cotLabel.label : key,
        children: (
          <Input
            className="input-number"
            value={value}
            disabled={isLoading}
            onChange={(event) => {
              const inputValue = event.target.value;
              const formattedValue = inputValue;
              const newData = {
                ...cotacoesData,
                [key]: formattedValue,
              };
              setCotacoesData(newData);
            }}
          />
        ),
        span: 2,
      };
    }
  });

  const items = [
    {
      label: "Estado",
      children: (
        <Select
          showSearch
          style={{ width: "100%" }}
          optionFilterProp="children"
          placeholder="Selecione um estado"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={estados}
          loading={isLoading}
          onChange={(value) => {
            setIsLoading(true);
            setCotacoesData([]);
            getDataWithEndpoint(
              "boi_carne_ind",
              `getBoiCarneIndbyUf?uf=${encodeURIComponent(value)}`
            )
              .then((res) => {
                setCotacoesData(res);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
          allowClear={true}
        />
      ),
      span: 3,
    },
    {
      label: "Última Alteração",
      children: formatDateHourToBR(cotacoesData.boi_data),
      span: 3,
    },
  ];

  return (
    <>
      <DescriptionsLayout
        title={"Interbois - Carne Industrializada"}
        items={[...items, ...aux.filter(Boolean)]}
        extra={
          <div style={{ padding: 10, display: "flex", gap: 10 }}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              icon={<FaSave />}
              disabled={isLoading}
              onClick={async () => {
                updateData({
                  ...cotacoesData,
                  endpoint: "updateBoiCarneInd",
                  path: "boi_carne_ind",
                });
              }}
            >
              Atualizar
            </Button>
          </div>
        }
      />
    </>
  );
}
