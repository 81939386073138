import React from "react";
import logo from "../../assets/logorb.png";

function Aguarde() {
  return (
    <div className="logo-start">
      <img src={logo} alt="logo" style={{ width: "100%", maxWidth: "200px" }} />
      <hr className="line-start" />
    </div>
  );
}

export default Aguarde;
