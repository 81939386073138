import React, { useEffect, useState } from "react";
import { DescriptionMinMax } from "../../../layout";
import {
  formatDateHourToBR,
  updateData,
} from "../../../functions/functionsUtility";
import { request } from "../../../functions/request";

const fields = [{ label: "Valor", value: "cot_valor" }];

export default function IntergraosAlgodaoCotacao() {
  const [cotacoesData, setCotacoesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    request("/algodao_cotacao/getLastAlgodaoCotacao", { method: "GET" })
      .then((data) => {
        setCotacoesData(data);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setHasError(err?.message ?? "Ocorreu um erro.");
        console.log(err);
      });
  }, []);

  return (
    <>
      <DescriptionMinMax
        title={"Intergrãos - Algodão - Cotação"}
        buttonText={"Atualizar"}
        onClickButton={async () => {
          updateData({
            ...cotacoesData,
            endpoint: "updateAlgodaoCotacao",
            path: "algodao_cotacao",
          });
        }}
        data={fields}
        defaultValueFromApi
        date={formatDateHourToBR(cotacoesData.cot_data)}
        state={cotacoesData}
        setState={setCotacoesData}
        isLoading={isLoading}
        hasError={hasError}
        hasValue={false}
      />
    </>
  );
}
