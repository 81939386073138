import React, { useEffect, useState } from "react";
import Editor from "../../components/Jodit/EditorTexto";
import { request } from "../../functions/request";
import { Button, Descriptions, Input, Select, Typography } from "antd";
import {
  formatDateToBR,
  optionsSection,
} from "../../functions/functionsUtility";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Aguarde from "../../components/aguarde/aguarde";
import { Content } from "antd/es/layout/layout";
import { FaSave, FaTrash } from "react-icons/fa";
const { Text } = Typography;

function AnaliseItem() {
  const [data, setData] = useState(null);
  const [title, setTitle] = useState("");
  const [titlePage, setTitlePage] = useState("");
  const [secao, setSecao] = useState("");
  const [content, setContent] = useState("");
  const [formattedDate, setFormattedDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const fetchTableData = async () => {
    const currentPathWithQuery = pathname + search;
    if (pathname.toLowerCase() == "/comentario/insertcomentario") {
      setData({});
      setTitle("");
      setSecao("");
      setContent("");
      setFormattedDate("");
      setIsLoading(false);
      return;
    }
    try {
      setIsLoading(true);
      const response = await request(currentPathWithQuery, {
        method: "GET",
      });
      if (!response) {
        return;
      }
      if (response.length === 0) {
        navigate("/comentario/insertComentario");
      }

      setData(response[0]);
      setTitle(response[0]?.com_titulo || "");
      setSecao(response[0]?.com_secao || "");
      setContent(response[0]?.com_texto || "");
      const date = new Date(response[0]?.com_data);
      setFormattedDate(
        `${formatDateToBR(date)} às ${date.getHours()}:${date.getMinutes()}`
      );
      setIsLoading(false);
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
  };

  useEffect(() => {
    if (pathname.toLowerCase() == "/comentario/insertcomentario") {
      setTitlePage("Inserir Análise");
    } else {
      setTitlePage(
        `Editar Análise de ${
          optionsSection.find((option) => {
            return option.value === secao;
          })?.label
        }`
      );
    }
  }, [secao, pathname]);

  useEffect(() => {
    fetchTableData();
  }, [pathname, search]);

  function validation() {
    if (!title || title.replace(/\s+/g, "").length <= 3 || !secao) {
      toast.error("Preencha todos os campos!");
      return false;
    } else if (title.length > 80) {
      toast.error("O Título deve conter até 80 caracteres!");
      return false;
    } else {
      return true;
    }
  }

  const handleUpdate = async () => {
    if (validation()) {
      const newData = {
        com_numero: data.com_numero,
        com_titulo: title,
        com_secao: secao,
        com_texto: content,
      };
      request("/comentario/updateComentario", {
        method: "PUT",
        body: JSON.stringify({ newData: newData }),
      }).then(() => {
        toast.success("Análise atualizada com sucesso!");
      });
    }
  };

  const handleInsert = async () => {
    if (validation()) {
      const newData = {
        com_titulo: title,
        com_secao: secao,
        com_texto: content,
      };
      request("/comentario/insertComentario", {
        method: "POST",
        body: JSON.stringify({ newData: newData }),
      }).then(() => {
        toast.success("Análise inserida com sucesso!");
      });
    }
  };

  const deleteItem = () => {
    if (
      window
        .confirm(
          "Você tem certeza que deseja excluir essa análise? Essa ação será irreversível."
        )
        .valueOf() === true
    ) {
      const currentPathWithQuery = `/comentario/deleteComentario/${data?.com_numero}`;
      request(currentPathWithQuery, {
        method: "DELETE",
      }).then(() => {
        toast.success("Analise deletada com sucesso!");
        setTimeout(() => {
          window.location.href = "/comentario";
        }, 2000);
      });
    }
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };
  const handleSecaoChange = (value) => {
    setSecao(value);
  };

  return (
    <Content>
      <Descriptions
        title={titlePage}
        bordered
        size="large"
        style={{ padding: 10 }}
        extra={
          <div style={{ padding: 10, display: "flex", gap: 10 }}>
            {formattedDate && (
              <Button
                type="primary"
                danger
                size="large"
                icon={<FaTrash />}
                disabled={isLoading}
                onClick={deleteItem}
              >
                Excluir
              </Button>
            )}
            <Button
              type="primary"
              size="large"
              icon={<FaSave />}
              disabled={isLoading}
              onClick={formattedDate ? handleUpdate : handleInsert}
            >
              {formattedDate ? "Editar" : "Inserir"}
            </Button>
          </div>
        }
      >
        <Descriptions.Item
          label={"Titulo"}
          span={3}
          children={
            <Input
              type="text"
              style={{ width: "100%" }}
              value={title}
              disabled={isLoading}
              placeholder="Insira aqui o título da análise"
              onChange={handleTitleChange}
            />
          }
          // style={{ display: "flex", gap: 10, alignItems: "center" }}
        />
        <Descriptions.Item
          label={"Seção"}
          span={2}
          children={
            <Select
              style={{ flexGrow: 1, width: "100%" }}
              value={secao}
              onChange={handleSecaoChange}
              disabled={isLoading}
              loading={isLoading}
            >
              {optionsSection.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          }
        />
        <Descriptions.Item
          label={"Data de Postagem"}
          children={
            data?.com_data && (
              <Typography>
                <Text>{formattedDate}</Text>
              </Typography>
            )
          }
        />
      </Descriptions>
      {isLoading ? (
        <Aguarde />
      ) : (
        <Editor content={content} setContent={setContent} />
      )}
    </Content>
  );
}

export default AnaliseItem;
