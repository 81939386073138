// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-analise {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.section-filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

/* .input-title {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 5px;
    width: 100%;
    display: flex;
  } */
`, "",{"version":3,"sources":["webpack://./src/pages/Correcao/correcao.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,mBAAmB;AACrB;;AAEA;;;;;;;;;KASK","sourcesContent":[".search-analise {\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 20px;\n}\n.section-filter {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  margin-bottom: 20px;\n}\n\n/* .input-title {\n    border: 1px solid #ccc;\n    padding: 5px;\n    border-radius: 5px;\n    font-size: 1rem;\n    font-weight: bold;\n    margin-bottom: 5px;\n    width: 100%;\n    display: flex;\n  } */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
