import React, { useState, useRef } from "react";
import "./login.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from "js-cookie";
import logoImage from "../../assets/logorb.png";
import logoImageWhite from "../../assets/logorbwhite.png";
import { AiOutlineEye, AiFillEyeInvisible } from "react-icons/ai";

const baseURL = process.env.REACT_APP_API_RB + "/login/login ";

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const passwordInputRef = useRef(null);
  const handleLoginChange = (event) => {
    setLogin(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleFormSubmit = async (event) => {
    if (!login || !password) {
      console.log("Foi aqui");
      toast.error("Por favor, preencha todos os campos.", {
        position: "bottom-right",
      });
      return;
    }

    const formData = {
      user: login,
      password: password,
    };
    console.log("Dados do formulário:", formData);

    try {
      const response = await fetch(baseURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          // Adicione quaisquer outros headers necessários aqui
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.token;

        Cookies.set("x-access-token", token, { expires: 365 });
        console.log("Token:", token);
        window.location.href = "/";
      } else {
        toast.error(
          "Usuário ou senha incorretos. Verifique suas credenciais e tente novamente.",
          {
            position: "bottom-right",
          }
        );

        console.log("Falha na requisição:", response.status);
      }
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
  };

  const handleToggleShowPassword = () => {
    passwordInputRef.current.focus();
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-page">
      <div className="container-login">
        <div className="form-login">
          <span style={{}}>
            <h1
              style={{
                margin: "5px 0",
                color: "darkslategray",
                fontSize: "2rem",
              }}
            >
              Sistema Rural Business
            </h1>
            <p style={{ margin: 0, color: "slategray" }}>
              Para utilizar o Sistema de Atualização Rural Business, você
              precisa de um usuário e uma senha.
            </p>
          </span>

          <div className="login-form">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: "1rem",
              }}
            >
              <div className="login-content">
                <div>Usuário:</div>
                <input
                  autoFocus
                  placeholder="Digite seu usuário"
                  className="input-login"
                  type="text"
                  required
                  name="usr_login"
                  size="17"
                  value={login}
                  onChange={handleLoginChange}
                />
              </div>
              <div className="login-content">
                <div>Senha:</div>
                <div className="password-input-container">
                  <input
                    ref={passwordInputRef}
                    placeholder="Digite sua senha"
                    className="input-login password-input"
                    type={showPassword ? "text" : "password"}
                    required
                    name="usr_senha"
                    size="17"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  <button
                    type="button"
                    className="show-password-button"
                    onClick={handleToggleShowPassword}
                  >
                    {showPassword ? (
                      <AiFillEyeInvisible size={20} color="#000" />
                    ) : (
                      <AiOutlineEye size={20} color="#000" />
                    )}
                  </button>
                </div>
              </div>
            </div>
            <button
              type="submit"
              className="login-button"
              onClick={handleFormSubmit}
            >
              Entrar
            </button>
          </div>
          <p style={{ margin: 0, color: "darkslategrey" }}>
            Problemas com esta página? Envie uma mensagem para{" "}
            <a
              href="mailto:suporte@ruralbusiness.com.br"
              className="suport_login"
            >
              suporte@ruralbusiness.com.br
            </a>
          </p>
        </div>
      </div>
      <div className="login-bg">
        <img src={logoImageWhite} alt="Logo" width="auto" height="60px" />
        <p>O amanhã do agronegócio, Hoje.</p>
      </div>
    </div>
  );
};

export default LoginPage;
