import React from "react";
import { Button, Descriptions, Drawer, Form, Input, Select } from "antd";

const dados = [
  { name: "Nome", value: "usr_nome" },
  { name: "Login", value: "usr_login" },
  { name: "Senha", value: "usr_password" },
];

const tipos = [
  { name: "Usuário", value: 0 },
  { name: "Administrador", value: 4 },
  { name: "Jornalista", value: 3 },
  { name: "Vendedor", value: 5 },
  { name: "Investidor", value: 7 },
];
// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

function ModalUser(props) {
  return (
    <>
      <Drawer
        title={props.editing ? "Editar Usuário" : "Inserir Usuário"}
        open={props.isPreviewOpen}
        onClose={props.onRequestClose}
        placement="right"
        width={500}
        extra={
          <Button type="primary" onClick={() => props.saveData()}>
            Salvar Informações
          </Button>
        }
      >
        <Form layout="vertical">
          {dados.map((el, index) => (
            <Form.Item label={el.name}>
              <Input
                value={props?.previewContent[el.value]}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  const formattedValue = inputValue;
                  const newData = {
                    ...props?.previewContent,
                    [el.value]: formattedValue,
                  };
                  props.setPreviewContent(newData);
                }}
                readOnly={
                  (el.value === "usr_login" ? true : false) && props.editing
                    ? true
                    : false
                }
              />
            </Form.Item>
          ))}
          <Form.Item label={"Correção"}>
            <Select
              style={{ width: "100%" }}
              value={props?.previewContent["usr_correcao"]}
              placeholder={"Selecione"}
              onChange={(event) => {
                const inputValue = event;
                const formattedValue = inputValue;
                const newData = {
                  ...props?.previewContent,
                  usr_correcao: formattedValue,
                };
                props.setPreviewContent(newData);
              }}
            >
              <Select.Option key="1" value={1}>
                Sim
              </Select.Option>
              <Select.Option key="0" value={0}>
                Não
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={"Tipo"}>
            <Select
              style={{ width: "100%" }}
              value={props?.previewContent["usr_tipo"]}
              placeholder="Selecione"
              onChange={(event) => {
                const inputValue = event;
                const formattedValue = inputValue;
                const newData = {
                  ...props?.previewContent,
                  usr_tipo: formattedValue,
                };
                props.setPreviewContent(newData);
                console.log(newData);
              }}
            >
              {tipos.map((el, index) => {
                return (
                  <Select.Option key={index} value={el.value}>
                    {el.name}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default ModalUser;
