import { request } from "./request";
import { message } from "antd";
import moment from "moment";

// Função para formatar data para o padrão brasileiro
export function formatDateToBR(dateString) {
  return moment(dateString).format("DD/MM/YYYY");
}

// Função para formatar data e hora para o padrão brasileiro
export function formatDateHourToBR(dateTimeString) {
  return moment(dateTimeString).format("DD/MM/YYYY [às] HH:mm");
}

// Função para validar se um objeto possui dados válidos
export async function validData(data, notNullFields = []) {
  if (!data || Object.keys(data).length === 0) {
    return false;
  }
  for (const field of notNullFields) {
    if (
      data[field] === null ||
      data[field] === undefined ||
      data[field] === ""
    ) {
      return false; // Isso irá parar a execução da função validData imediatamente
    }
  }
  return true;
}

export const estadosOptions = [
  { value: "BA", label: "Bahia" },
  { value: "GO", label: "Goiás" },
  { value: "MA", label: "Maranhão" },
  { value: "MT", label: "Mato Grosso" },
  { value: "MS", label: "Mato Grosso do Sul" },
  { value: "MG", label: "Minas Gerais" },
  { value: "PR", label: "Paraná" },
  { value: "RJ", label: "Rio de Janeiro" },
  { value: "RS", label: "Rio Grande do Sul" },
  { value: "SC", label: "Santa Catarina" },
  { value: "SP", label: "São Paulo" },
  { value: "PG", label: "Porto de Paranaguá" },
  { value: "ST", label: "Porto de Santos" },
];

export const transformSectionComent = (section) => {
  switch (section) {
    case 1:
      return "Boi";
    case 3:
      return "Frango";
    case 4:
      return "Leite";
    case 5:
      return "Suínos";
    case 10:
      return "Trigo";
    case 11:
      return "Algodão";
    case 21:
      return "Soja";
    case 22:
      return "Milho";
    case 23:
      return "Café";
    case 24:
      return "Açucar";
    default:
      return "";
  }
};

export const transformLabelNumber = (label) => {
  switch (label.toLowerCase()) {
    case "soja":
      return 21;
    case "frango":
      return 3;
    case "boi":
      return 1;
    case "leite":
      return 4;
    case "suinos":
      return 5;
    case "trigo":
      return 10;
    case "algodao":
      return 11;
    case "cafe":
      return 23;
    case "acucar":
      return 24;
    case "milho":
      return 22;
    default:
      return 1;
  }
};

export const optionsTag = [
  { value: "agroacoes", label: "Agroações" },
  { value: "Bolsa", label: "Bolsa e opções" },
  { value: "TTEN3", label: "3TENTOS TTEN3" },
  { value: "GRAO3", label: "AGRIBRASIL GRAO3" },
  { value: "AGXY3", label: "AGROGALAXY AGXY3" },
  { value: "APER3", label: "ALPER S.A. APER3" },
  { value: "ABEV3", label: "AMBEV S.A ABEV3" },
  { value: "AMBP3", label: "AMBIPAR AMBP3" },
  { value: "ARZZ3", label: "AREZZO ARZZ3" },
  { value: "ARML3", label: "ARMAC LOCAÇÃO, LOGÍSTICA E SERVIÇOS S.A ARML3" },
  { value: "ASAI3", label: "ASSAÍ ASAI3" },
  { value: "BBDC3", label: "BANCO BRADESCO BBDC3" },
  { value: "BPAC3", label: "BANCO BTG PACTUAL SA BPAC3" },
  { value: "BBAS3", label: "BANCO DO BRASIL BBAS3" },
  { value: "SANB3", label: "BANCO SANTANDER SANB3" },
  { value: "BRSR3", label: "BANRISUL BRSR3" },
  { value: "BRSR6", label: "BANRISUL (PNB) BRSR6" },
  { value: "BBSE3", label: "BB SEGURIDADE BBSE3" },
  { value: "BSEV3", label: "BIOSEV BSEV3" },
  { value: "B3SA3", label: "BMF BOVESPA B3SA3" },
  { value: "SOJA3", label: "BOA SAFRA SOJA3" },
  { value: "BRFS3", label: "BRASIL FOODS BRFS3" },
  { value: "AGRO3", label: "BRASILAGRO AGRO3" },
  { value: "XBOV11", label: "CAIXA ETF IBOVESPA FUNDO DE INDICE XBOV11" },
  { value: "CAML3", label: "CAMIL ALIMENTOS S.A CAML3" },
  { value: "CRFB3", label: "CARREFOUR BR CRFB3" },
  { value: "CMIG4", label: "CEMIG CMIG4" },
  { value: "ELET3", label: "CENTRAIS ELETRICAS BRASILEIRAS SA ELET3" },
  {
    value: "ELET6",
    label: "CENTRAIS ELETRICAS BRASILEIRAS SA PREFERENCE SHARES SERIES ELET6",
  },
  { value: "CGAS3", label: "COMGAS CGAS3" },
  { value: "CSNA3", label: "COMPANHIA SIDERÚRGICA CSNA3" },
  { value: "CSAN3", label: "COSAN CSAN3" },
  { value: "RLOG3", label: "COSAN LOG RLOG3" },
  { value: "CMIN3", label: "CSN MINERAÇÃO SA CMIN3" },
  { value: "CTCA3", label: "CTC - CENTRO DE TECNOLOGIA CANAVIEIRA S.A. CTCA3" },
  {
    value: "DESK3",
    label: "DESKTOP - SIGMANET COMUNICAÇÃO MULTIMÍDIA S.A. DESK3",
  },
  { value: "DXCO3", label: "DEXCO DXCO3" },
  { value: "ENBR3", label: "EDP ENERGIA DO BRASIL S.A ENBR3" },
  { value: "EMBR3", label: "EMBRAER S.A EMBR3" },
  { value: "ENGI11", label: "ENERGISA ENGI11" },
  { value: "EGIE3", label: "ENGIE BRASIL EGIE3" },
  { value: "EQTL3", label: "EQUATORIAL ENERGIA EQTL3" },
  { value: "FHER3", label: "FERTILIZANTES HERINGER FHER3" },
  { value: "GGBR3", label: "GERDAU S.A. GGBR3" },
  { value: "GMAT3", label: "GRUPO MATEUS GMAT3" },
  { value: "HBSA3", label: "HIDROVIAS DO BRASIL S.A HBSA3" },
  { value: "RANI3", label: "IRANI CELULOSE RANI3" },
  { value: "IRBR3", label: "IRB BRASIL RE IRBR3" },
  { value: "ITUB3", label: "ITAÚ UNIBANCO ITUB3" },
  { value: "ITSA4", label: "ITAUSA INVESTIMENTOS ITAU SA ITSA4" },
  { value: "JALL3", label: "JALLES MACHADO S.A. JALL3" },
  { value: "JBSS3", label: "JBS FRIBOI JBSS3" },
  { value: "JSLG3", label: "JSL S.A JSLG3" },
  { value: "KEPL3", label: "KEPLER WEBER KEPL3" },
  { value: "KLBN11", label: "KLABIN SA KLBN11" },
  { value: "RENT3", label: "LOCALIZA RENT3" },
  { value: "MDIA3", label: "M.DIASBRANCO MDIA3" },
  { value: "MGLU3", label: "MAGAZINE LUIZA MGLU3" },
  { value: "POMO3", label: "MARCOPOLO S.A. POMO3" },
  { value: "MRFG3", label: "MARFRIG MRFG3" },
  { value: "MELI34", label: "MERCADO LIVRE MELI34" },
  { value: "BEEF3", label: "MINERVA BEEF3" },
  { value: "MNPR3", label: "MINUPAR MNPR3" },
  { value: "NEOE3", label: "NEOENERGIA NEOE3" },
  { value: "OPCT3", label: "OCEANPACT OPCT3" },
  { value: "MEGA3", label: "OMEGA ENERGIA S.A MEGA3" },
  { value: "OFSA3", label: "OUROFINO OFSA3" },
  { value: "PCAR3", label: "PÃO ACUCAR - GPA PCAR3" },
  { value: "MILK11", label: "PARMALAT MILK11" },
  { value: "RECV3", label: "PETRO RECONCAVO RECV3" },
  { value: "PETR3", label: "PETROLEO BRASILEIRO S.A. PETROBRAS PETR3" },
  { value: "PSSA3", label: "PORTO SEGURO SEGUROS PSSA3" },
  { value: "RAIZ4", label: "RAÍZEN RAIZ4" },
  { value: "RAPT3", label: "RANDON S.A. RAPT3" },
  { value: "RCSL3", label: "RECRUSUL S.A RCSL3" },
  { value: "RNAR3", label: "RENAR RNAR3" },
  { value: "ROMI3", label: "ROMI S.A. ROMI3" },
  { value: "RAIL3", label: "RUMO S.A RAIL3" },
  { value: "STBP3", label: "SANTOS BRASIL STBP3" },
  { value: "SMTO3", label: "SÃO MARTINHO SMTO3" },
  { value: "RDSA34", label: "SHELL RDSA34" },
  { value: "SLCE3", label: "SLC AGRÍCOLA S.A. SLCE3" },
  { value: "SUZB3", label: "SUZANO S.A SUZB3" },
  { value: "TAEE11", label: "TAESA TAEE11" },
  { value: "TASA4", label: "TAURUS ARMAS TASA4" },
  { value: "TERI3", label: "TEREOS TERI3" },
  { value: "TESA3", label: "TERRA SANTA TESA3" },
  { value: "RZTR11", label: "TERRAX RZTR11" },
  { value: "TOTS3", label: "TOTVS TOTS3" },
  { value: "LAND3", label: "TS AGRO LAND3" },
  { value: "TUPY3", label: "TUPY TUPY3" },
  { value: "UGPA3", label: "ULTRAPAR UGPA3" },
  { value: "LCAM3", label: "UNIDAS LCAM3" },
  { value: "USIM5", label: "USIMINAS USIM5" },
  { value: "VAGR3", label: "V-AGRO VAGR3" },
  { value: "VALE3", label: "VALE S.A. VALE3" },
  { value: "VAMO3", label: "VAMOS VAMO3" },
  { value: "VBBR3", label: "VIBRA ENERGIA VBBR3" },
  { value: "VIGR3", label: "VIGOR VIGR3" },
  { value: "VITT3", label: "VITTIA FERTILIZANTES E BIOLÓGICOS SA. VITT3" },
  { value: "WEGE3", label: "WEG S.A WEGE3" },
  { value: "ZAMP3", label: "ZAMP S.A. ZAMP3" },
];

export const optionsSection = [
  { value: false, label: "Todos" },
  { value: 1, label: "Boi" },
  { value: 3, label: "Frango" },
  { value: 4, label: "Leite" },
  { value: 5, label: "Suinos" },
  { value: 10, label: "Trigo" },
  { value: 11, label: "Algodao" },
  { value: 21, label: "Soja" },
  { value: 22, label: "Milho" },
  { value: 23, label: "Cafe" },
  { value: 24, label: "Cana-de-acucar" },
];

// Função para padronizar o Insert de dados
export const saveData = async (content) => {
  Object.keys(content).forEach((el) => {
    if (typeof content[el] === "string") {
      content[el] = content[el].replace(/,/g, ".");
    }
  });
  const newData = { newData: content };
  try {
    const response = await request(`/${content.path}/${content.insert}`, {
      method: "POST",
      body: JSON.stringify(newData),
    });

    console.log("Server response:", response);
    message.success("Operação realizada com sucesso.");
  } catch (error) {
    console.error("Error during fetch:", error);
    message.error("Operação falhou, consulte um administrador.");
  }
};

/**
 * Atualiza dados no banco de dados recebendo uma entidade.
 *
 * @param {Array} content - Dados do registro a ser atualizado (adicionais: path, endpoint).
 * @returns {Promise<object>} Retorna um objeto representando a resposta da atualização.
 *
 */
export const updateData = async (content) => {
  Object.keys(content).forEach((el) => {
    if (typeof content[el] === "string") {
      content[el] = content[el].replace(/,/g, ".");
    }
  });

  const newData = { newData: content };

  try {
    const response = await request(`/${content.path}/${content.endpoint}`, {
      method: "PUT",
      body: JSON.stringify(newData),
    });

    console.log("Server response:", response);
    message.success("Operação realizada com sucesso.");
  } catch (error) {
    console.error("Error during fetch:", error);
    message.error("Operação falhou, consulte um administrador.");
  }
};

// Função para gerar uma chave aleatória
export function uniKey(length = 10) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
