import React, { useMemo, useEffect, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Button, Divider, Typography } from "antd";
import { request } from "../../functions/request";
import Aguarde from "../../components/aguarde/aguarde";
import { AiOutlineSearch, AiFillEdit, AiFillDelete } from "react-icons/ai";
import ModalUser from "../../components/Modal/ModalUser";
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { Content } from "antd/es/layout/layout";
import { FaPlus } from "react-icons/fa";

const UserPage = () => {
  const [previewContent, setPreviewContent] = useState("");
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [haveResult, setHaveResult] = useState(false);
  const [editing, setEditing] = useState(true);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const currentPathWithQuery = "/user/getUsers";
        const response = await request(currentPathWithQuery, {
          method: "GET",
        });
        const data = response;
        const aux = [];

        data.map((el, index) => {
          let tipoUser = "";
          switch (el.usr_tipo) {
            case 7:
              tipoUser = "Investidor";
              break;
            case 0:
              tipoUser = "Usuário";
              break;
            case 4:
              tipoUser = "Administrador";
              break;
            case 5:
              tipoUser = "Vendedor";
              break;
            case 3:
              tipoUser = "Jornalista";
              break;
            default:
              tipoUser = "Usuário";
              break;
          }
          aux.push({
            usr_nome: el.usr_nome,
            usr_tipo: tipoUser,
            usr_correcao: el.usr_correcao ? 1 : 0,
            usr_password: el.usr_password,
            usr_login: el.usr_login,
          });
        });
        setTableData(aux);
        setHaveResult(true);
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    };
    fetchTableData();
  }, []);

  const confirmDelet = (id) => {
    confirmAlert({
      title: "Você tem certeza que deseja excluir esse usuário?",
      message: "Essa ação será irreversível.",
      buttons: [
        {
          label: "Não",
          className: "button-delete",
          onClick: () => {},
        },
        {
          label: "Sim",
          className: "button-save",
          onClick: () => deleteData(id),
        },
      ],
    });
  };
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "usr_nome",
        header: "Nome",
      },
      {
        accessorKey: "usr_login",
        header: "Login",
      },
      {
        accessorKey: "usr_tipo",
        header: "Tipo de Usuário",
        filterVariant: "multi-select",
      },
    ],
    []
  );

  const saveData = async () => {
    if (editing) {
      try {
        const currentPathWithQuery =
          `/user/updateUser?usr_login=` + previewContent.usr_login;
        let tipoUser = previewContent.usr_tipo;
        if (typeof previewContent.usr_tipo !== "number") {
          switch (previewContent.usr_tipo) {
            case "Investidor":
              tipoUser = 7;
              break;
            case "Usuário":
              tipoUser = 0;
              break;
            case "Administrador":
              tipoUser = 4;
              break;
            case "Vendedor":
              tipoUser = 5;
              break;
            case "Jornalista":
              tipoUser = 3;
              break;
            default:
              tipoUser = 0;
              break;
          }
        }
        const response = await request(currentPathWithQuery, {
          method: "PUT",
          body: JSON.stringify({ ...previewContent, usr_tipo: tipoUser }),
        }).then((response) => {
          setIsPreviewOpen(false);
          toast.success("Usuário atualizado com sucesso!");
          // setTimeout(() => {
          //     window.location.reload();
          // }, 1000);
        });
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    } else {
      try {
        const currentPathWithQuery = `/user/insertUser`;
        const response = await request(currentPathWithQuery, {
          method: "POST",
          body: JSON.stringify(previewContent),
        }).then((response) => {
          setIsPreviewOpen(false);
          toast.success("Usuário inserido com sucesso!");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        });
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    }
  };

  const deleteData = async (id) => {
    try {
      const currentPathWithQuery = `/user/deleteUser/` + id.usr_login;
      const response = await request(currentPathWithQuery, {
        method: "DELETE",
      }).then((response) => {
        setIsPreviewOpen(false);
        console.log(response);
        toast.success("Usuário deletado com sucesso!");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      });
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
  };
  const sendElement = (el, row) => {
    setEditing(true);
    setIsPreviewOpen(true);
    setPreviewContent(row.original);
  };

  const closeModal = () => {
    setIsPreviewOpen(false);
  };

  return (
    <Content>
      <ModalUser
        editing={editing}
        isPreviewOpen={isPreviewOpen}
        previewContent={previewContent}
        setPreviewContent={setPreviewContent}
        onRequestClose={closeModal}
        saveData={saveData}
        deleteData={deleteData}
      />
      {haveResult ? (
        tableData.length > 0 ? (
          <MaterialReactTable
            columns={columns}
            data={tableData}
            defaultColumn={{
              minSize: 5, //allow columns to get smaller than default
              maxSize: 160,
              size: 100,
            }}
            enableColumnFilterModes
            enableColumnSorting
            enableRowActions
            enableRowVirtualization
            enableFacetedValues
            columnFilterModeOptions={null}
            initialState={{
              showColumnFilters: true,
              pagination: { pageIndex: 0, pageSize: 100 },
            }}
            localization={MRT_Localization_PT_BR}
            positionActionsColumn="last"
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "", // Altere o texto do cabeçalho, se necessário
                size: 100, // Faça a coluna de ações um pouco mais larga
              },
            }}
            renderRowActions={({ row }) => (
              <div
                style={{ display: "flex", gap: "5px", justifyContent: "end" }}
              >
                <Button
                  onClick={(el) => sendElement(el.value, row)}
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <AiFillEdit />
                  Editar
                </Button>
                <Button
                  danger
                  onClick={(el) => {
                    confirmDelet(row.original);
                  }}
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <AiFillDelete />
                  Excluir
                </Button>
              </div>
            )}
            renderTopToolbarCustomActions={() => (
              <Content
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                  padding: 10,
                }}
              >
                <Typography.Title level={4} style={{ margin: 0 }}>
                  Usuários
                </Typography.Title>
                <Divider type="vertical" />
                <Button
                  type="primary"
                  icon={<FaPlus />}
                  size="large"
                  onClick={() => {
                    setEditing(false);
                    setPreviewContent({});
                    setIsPreviewOpen(true);
                  }}
                >
                  Adicionar Usuário
                </Button>
              </Content>
            )}
            muiTableHeadCellFilterTextFieldProps={{
              placeholder: "Filtrar",
            }}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "80%",
            }}
          >
            <h2>Ops! Não encontramos resultados para a busca.</h2>
            <AiOutlineSearch size={48} color="#888" />
            <p>Tente pesquisar novamente com outros termos ou filtros.</p>
          </div>
        )
      ) : (
        <Aguarde />
      )}
    </Content>
  );
};

export default UserPage;
