import { Modal } from "antd";
import React from "react";

export default function CustomModal({
  title,
  subtitle,
  open,
  onCancel,
  onOk,
  okText = "Confirmar",
  cancelText = "Cancelar",
  ...props
}) {
  return (
    <Modal
      title={title}
      open={open}
      onCancel={onCancel}
      cancelText={cancelText}
      onOk={onOk}
      okText={okText}
      {...props}
    >
      {subtitle}
    </Modal>
  );
}
