import { FileSearchOutlined } from "@ant-design/icons";
import { transformLabelNumber } from "../functions/functionsUtility";
import { PiGrainsLight } from "react-icons/pi";
import { LuMilk } from "react-icons/lu";
import { FaCottonBureau, FaPhotoVideo } from "react-icons/fa";
import { GiBull, GiCorn, GiWheat, GiChicken } from "react-icons/gi";
import {
  MdOutlineChangeCircle,
  MdAttachMoney,
  MdPermMedia,
} from "react-icons/md";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { FaChartColumn, FaMoneyBillTrendUp, FaOilWell } from "react-icons/fa6";
import {
  AiFillVideoCamera,
  AiOutlineTable,
  AiOutlinePicture,
} from "react-icons/ai";
import { BsNewspaper, BsPiggyBank, BsGraphUpArrow } from "react-icons/bs";
import { CiWheat } from "react-icons/ci";
import { IoIosCafe } from "react-icons/io";

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem("Análises", "comentario", <FileSearchOutlined />, [
    getItem("Consultar Análises", "comentario"),
    getItem("Inserir Nova Análise", "comentario/insertComentario"),
    getItem(
      "Interbois",
      "/comentario/getcomentariobysection?section=" +
        transformLabelNumber("boi"),
      <GiBull />
    ),
    getItem("Intergrãos", "Intergrãos", <PiGrainsLight />, [
      getItem(
        "Soja",
        "/comentario/getcomentariobysection?section=" +
          transformLabelNumber("Soja")
      ),
      getItem(
        "Milho",
        "/comentario/getcomentariobysection?section=" +
          transformLabelNumber("Milho")
      ),
      getItem(
        "Café",
        "/comentario/getcomentariobysection?section=" +
          transformLabelNumber("Cafe")
      ),
      getItem(
        "Açúcar",
        "/comentario/getcomentariobysection?section=" +
          transformLabelNumber("Acucar")
      ),
      getItem(
        "Trigo",
        "/comentario/getcomentariobysection?section=" +
          transformLabelNumber("Trigo")
      ),
      getItem(
        "Algodão",
        "/comentario/getcomentariobysection?section=" +
          transformLabelNumber("Algodao")
      ),
    ]),
    getItem(
      "Interfrango",
      "/comentario/getcomentariobysection?section=" +
        transformLabelNumber("frango"),
      <GiChicken />
    ),
    getItem(
      "Intersuíno",
      "/comentario/getcomentariobysection?section=" +
        transformLabelNumber("suinos"),
      <BsPiggyBank />
    ),
    getItem(
      "Interleite",
      "/comentario/getcomentariobysection?section=" +
        transformLabelNumber("leite"),
      <LuMilk />
    ),
  ]),
  getItem("Notícias RTE", "Notícias RTE", <BsNewspaper />, [
    getItem("Consultar Notícias", "noticia/consultar_noticias"),
    getItem("Inserir Nova Notícia", "/noticia/inserir_noticias"),
  ]),
  getItem("Fechamento de Bolsas", "bolsa", <BsGraphUpArrow />, [
    getItem("Interbois", "Bolsa Interbois", <GiBull />, [
      getItem("Boi CME", "/interbois/boi_cme"),
      getItem("Mercado Futuro", "/interbois/boi_futuro"),
    ]),
    getItem("Intergrãos", "Bolsa Intergrãos", <PiGrainsLight />, [
      getItem("Café", "Bolsa Cafe", <IoIosCafe />, [
        getItem("Café - BM&F", "/intergraos/cafe_bmf"),
        getItem("Café - Nova York", "/intergraos/cafe_ny"),
        getItem("Café - Londres", "/intergraos/cafe_londres"),
      ]),
      getItem("Milho", "Bolsa Milho", <GiCorn />, [
        getItem("Milho - BMF", "/intergraos/milho_bmf"),
        getItem("Milho CCM - BMF", "/intergraos/milho_bmfccm"),
        getItem("Milho - Mercado Futuro", "/intergraos/milho_futuro"),
      ]),
      getItem("Soja", "Bolsa Soja", <CiWheat />, [
        getItem("Soja - Mercado Futuro", "/intergraos/soja_futuro"),
        getItem("Soja - Óleo", "/intergraos/soja_oleo"),
        getItem("Soja - Farelo", "/intergraos/soja_farelo"),
        getItem("Soja - BMF", "/intergraos/soja_bmf"),
        getItem("Soja - América do Sul", "/intergraos/soja_cbot"),
      ]),
      getItem("Trigo, Açucar e Gerais", "Bolsa Gerais", <GiWheat />, [
        getItem("Trigo - CME", "/intergraos/trigo_cme"),
        getItem("Açúcar - Nova Iorque", "/intergraos/acucar_ny"),
        getItem("Açúcar - Londres", "/intergraos/acucar_lond"),
        getItem("Açúcar - BM&F", "/intergraos/acucar_bmf"),
        getItem("Alcool Anidro - BM&F", "/intergraos/alcool_bmf"),
      ]),
    ]),
    getItem("Petróleo", "/petroleo/leite_cotacao", <FaOilWell />, [
      getItem("ICE Futures", "/petroleo/petroleo_ice"),
      getItem("NYMEX", "/petroleo/petroleo_ny"),
      getItem("WTI", "/petroleo/petroleo_wti"),
    ]),
  ]),
  getItem("Cotações", "cotacoes", <MdAttachMoney />, [
    getItem("Interbois", "Cotações Interbois", <GiBull />, [
      getItem("Cotações Top", "/interbois/cotacoestop"),
      getItem("Mercado Atacadista", "/interbois/boi_atacado"),
      getItem("Boi Liners", "/interbois/boi_liners"),
      getItem("Boi Paraguai", "/interbois/boi_paraguai"),
      getItem("Cotações por Estado", "/interbois/boi_est"),
      getItem("Carne Industrializada", "/interbois/boi_carne_ind"),
      getItem("Carne In Natura", "/interbois/boi_carne_natura"),
      getItem("Cortes Trad. e Esp.", "/interbois/boi_corte_trad_esp"),
      getItem("Cortes", "/interbois/boi_cortes"),
      getItem("Couro", "/interbois/boi_couro"),
      getItem("Diversos", "/interbois/boi_diversos"),
      getItem("Miúdos Congelados", "/interbois/boi_miudos"),
      getItem("Charque", "/interbois/boi_charque"),
    ]),
    getItem("Intergrãos", "Cotações Intergrãos", <PiGrainsLight />, [
      getItem("Café - Bica Corrida", "/intergraos/cafe_bica", <IoIosCafe />),
      getItem(
        "Milho - Cotação - BM&F",
        "/intergraos/milho_cotacao",
        <GiCorn />
      ),
      getItem(
        "Soja - Cotação por Estado",
        "/intergraos/soja_cotacao",
        <CiWheat />
      ),
      getItem("Trigo - Agranel", "/intergraos/trigo_agranel", <GiWheat />),
      getItem(
        "Algodão - Cotação",
        "/intergraos/algodao_cotacao",
        <FaCottonBureau />
      ),
    ]),
    getItem("Interfrango", "Cotações Interfrango", <GiChicken />, [
      getItem("Cotações Top", "/interfrangos/fratop"),
      getItem("Cortes", "/interfrangos/cortes"),
    ]),
    getItem("Intersuíno", "Cotações Intersuíno", <BsPiggyBank />, [
      getItem("Cotações Top", "/intersuinos/cottop"),
      getItem("Cortes", "/intersuinos/cortes"),
      getItem("Cortes Especial", "/intersuinos/cortes_esp"),
    ]),
    getItem("Interleite", "/interleite/leite_cotacao", <LuMilk />),

    getItem("Dólar", "Dolar", <RiMoneyDollarCircleLine />, [
      getItem("Dólar", "/ferramentas/cotacoes_dolar"),
      getItem("Dólar Ptax", "/ferramentas/cotacoes_dolarptax"),
    ]),
    getItem(
      "Índices Econômicos",
      "/ferramentas/indices",
      <FaMoneyBillTrendUp />
    ),
  ]),

  getItem("Tabelas", "Tabelas", <AiOutlineTable />, [
    getItem("Interbois", "Tabelas Interbois", <GiBull />, [
      getItem("Tabela Diária", "/interbois/tab_coment_diario"),
      getItem("Tabela Mensal", "/interbois/tab_coment_mensal"),
      getItem("Tabela Clima", "/interbois/tab_coment_clima"),
    ]),
    getItem("Intergrãos", "Tabelas Intergrãos", <PiGrainsLight />, [
      getItem("Soja", "/intergraos/tab_soja_oferta"),
      getItem("Milho", "/intergraos/tab_milho_oferta"),
      getItem("Café", "/intergraos/tab_cafe_oferta"),
      getItem("Açúcar", "/intergraos/tab_cana_oferta"),
      getItem("Trigo", "/intergraos/tab_trigo_oferta"),
      getItem("Algodão", "/intergraos/tab_algodao_oferta"),
      getItem("Tabela de Clima", "/intergraos/tab_sec_clima"),
      getItem("Tabela de Dólar", "/intergraos/tab_sec_dolar"),
    ]),
    getItem("Interfrango", "/interfrangos/tab_frango", <GiChicken />),
    getItem("Intersuíno", "/intersuinos/tab_suino", <BsPiggyBank />),
    getItem("Interleite", "/interleite/tab_leite", <LuMilk />),
  ]),

  getItem("Multimídia", "Multimidia", <MdPermMedia />, [
    getItem("Imagens", "ferramentas/imagens", <AiOutlinePicture />),
    getItem("Series", "series", <AiFillVideoCamera />),
    getItem("Vídeos", "videos", <FaPhotoVideo />),
  ]),
  getItem("Agroações", "agroacoes", <FaChartColumn />),
  getItem("Correções", "correcoes", <MdOutlineChangeCircle />),
];

export default items;
