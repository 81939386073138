import { Button, Drawer, Form, Input, Popconfirm, Select, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { FaSave, FaTrash } from "react-icons/fa";
import { request } from "../../../functions/request";
import dayjs from "dayjs";

export default function DrawerAgroacoesCotacao({
  open,
  onClose,
  data,
  agroacoesNome,
  setDrawer,
}) {
  console.log(data);
  const [form] = useForm();
  const [formData, setFormData] = useState({});

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      id: data?.id,
      acoes_id: data?.acoes_id,
      acoes_data: data?.acoes_data ?? dayjs().format("DD/MM/YYYY"),
      acoes_abertura: data?.acoes_abertura,
      acoes_min: data?.acoes_min,
      acoes_max: data?.acoes_max,
      acoes_med: data?.acoes_med,
      acoes_valor: data?.acoes_valor,
      acoes_porcentagem: data?.acoes_porcentagem,
      acoes_compra: data?.acoes_compra,
      acoes_venda: data?.acoes_venda,
      acoes_negocios: data?.acoes_negocios,
      acoes_qtd_titulos: data?.acoes_qtd_titulos,
    });
    setFormData(data);
  }, [data, open]);

  async function handleInsert(values) {
    try {
      request("/cotacoes/insertCotacaoAcao", {
        method: "POST",
        body: JSON.stringify(values),
      })
        .then((res) => {
          console.log(res);
        })
        .finally(() => {
          onClose && onClose();
          setDrawer &&
            setDrawer((prevState) => ({
              ...prevState,
              shouldRefreshToggle: !prevState.shouldRefreshToggle,
            }));
          message.success("Ação realizada com sucesso.");
        })
        .catch((err) => {
          console.log(err);
          message.error("Ação falhou, consulte um administrador.");
        });
    } catch (error) {
      console.error("Error during fetch:", error);
      message.error("Ação falhou, consulte um administrador.");
    }
  }

  async function handleUpdate(values) {
    try {
      request(`/cotacoes/updateCotacaoAcao/${values.id}`, {
        method: "POST",
        body: JSON.stringify(values),
      })
        .then((res) => {
          console.log(res);
        })
        .finally(() => {
          onClose && onClose();
          setDrawer &&
            setDrawer((prevState) => ({
              ...prevState,
              shouldRefreshToggle: !prevState.shouldRefreshToggle,
            }));
          message.success("Ação realizada com sucesso.");
        })
        .catch((err) => {
          console.log(err);
          message.error("Ação falhou, consulte um administrador.");
        });
    } catch (error) {
      console.error("Error during fetch:", error);
      message.error("Ação falhou, consulte um administrador.");
    }
  }

  async function handleDelete() {
    try {
      request(`/cotacoes/deleteCotacaoAcao/${form.getFieldValue("id")}`, {
        method: "DELETE",
      })
        .then((res) => {
          console.log(res);
        })
        .finally(() => {
          onClose && onClose();
          setDrawer &&
            setDrawer((prevState) => ({
              ...prevState,
              shouldRefreshToggle: !prevState.shouldRefreshToggle,
            }));
          message.success("Ação excluída com sucesso.");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error("Error during fetch:", error);
      message.error("Ação falhou, consulte um administrador.");
    }
  }

  return (
    <Drawer
      title={formData?.id ? "Editar Agroação" : "Adicionar Nova Agroação"}
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      {/* bug da lib, deve re-renderizar Form para atualizar os valores */}
      {open && (
        <Form
          form={form}
          variant="filled"
          layout="vertical"
          onFinish={(values) => {
            if (formData?.id) {
              handleUpdate(values);
            } else {
              handleInsert(values);
            }
          }}
        >
          {formData?.id && (
            <Form.Item label="ID" name={"id"}>
              <Input disabled />
            </Form.Item>
          )}
          <Form.Item
            label="ID Ação"
            name={"acoes_id"}
            rules={[
              {
                required: true,
                message: "Preencha o campo ID Ação!",
              },
            ]}
          >
            <Select
              showSearch
              allowClear
              optionFilterProp="children"
              placeholder="Selecione uma Agroação"
              filterOption={(input, option) =>
                ((option?.label).toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              options={agroacoesNome}
            />
          </Form.Item>
          <Form.Item
            label="Data"
            name={"acoes_data"}
            rules={[
              {
                required: true,
                message: "Selecione uma Data!",
              },
            ]}
          >
            <Input disabled={formData?.id ? false : true} />
          </Form.Item>
          <Form.Item label="Abertura" name={"acoes_abertura"}>
            <Input />
          </Form.Item>
          <Form.Item label="Mínima" name={"acoes_min"}>
            <Input />
          </Form.Item>
          <Form.Item label="Máxima" name={"acoes_max"}>
            <Input />
          </Form.Item>
          <Form.Item label="Média" name={"acoes_med"}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Valor"
            name={"acoes_valor"}
            rules={[
              {
                required: true,
                message: "Preencha o campo Valor!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Porcentagem" name={"acoes_porcentagem"}>
            <Input />
          </Form.Item>
          <Form.Item label="Compra" name={"acoes_compra"}>
            <Input />
          </Form.Item>
          <Form.Item label="Venda" name={"acoes_venda"}>
            <Input />
          </Form.Item>
          <Form.Item label="Negócios" name={"acoes_negocios"}>
            <Input />
          </Form.Item>
          <Form.Item label="Qtd. Títulos" name={"acoes_qtd_titulos"}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button
              block
              icon={<FaSave />}
              type="primary"
              size="large"
              htmlType="submit"
              style={{ marginTop: 10 }}
            >
              {formData?.id ? "Salvar" : "Inserir"}
            </Button>
          </Form.Item>
          {formData?.id && (
            <Popconfirm
              title="Deseja mesmo excluir esta Ação?"
              description="Essa ação será irreversível."
              onConfirm={handleDelete}
              okText="Confirmar"
              cancelText="Cancelar"
              placement="left"
            >
              <Button
                block
                icon={<FaTrash />}
                danger
                type="primary"
                size="large"
                htmlType="button"
                style={{ marginTop: 10 }}
              >
                Excluir
              </Button>
            </Popconfirm>
          )}
        </Form>
      )}
    </Drawer>
  );
}
