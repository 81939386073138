import React, { useState } from "react";
import { request } from "../../../functions/request";
import { Button, Checkbox, Input, Select } from "antd";
import {
  formatDateHourToBR,
  updateData,
} from "../../../functions/functionsUtility";
import { DescriptionsLayout } from "../../../layout";
import { FaSave } from "react-icons/fa";

const cafeOptions = [
  { value: "caf_minimo", label: "Mínima" },
  { value: "caf_maximo", label: "Máxima" },
];

const cafeOptionsCheck = [
  { value: "caf_icms", label: "ICMS" },
  { value: "caf_funrural", label: "FUNRURAL" },
];

const categorias = [
  //   { value: "0", label: "Cerrado" },
  { value: "1", label: "Sul de Minas" },
  { value: "2", label: "Mogiana" },
  { value: "3", label: "Con 7-8" },
  { value: "4", label: "Riado" },
  { value: "5", label: "Bebida Rio" },
  { value: "6", label: "Pilha Batida Dura" },
  { value: "7", label: "Pilha Batida Riadas" },
  //   { value: "8", label: "600/700 Def" },
];

export default function IntergraosCafeBica() {
  const [cotacoesData, setCotacoesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const items = [
    {
      label: "Categoria",
      children: (
        <Select
          style={{ width: "100%", marginTop: "10px" }}
          onChange={(value) => {
            setCotacoesData([]);
            setCotacoesData({ ...cotacoesData, caf_categoria: value });
            getData(value);
          }}
          placeholder="Selecione a categoria"
        >
          {categorias.map((el, index) => {
            return (
              <Select.Option key={index} value={el.value}>
                {el.label}
              </Select.Option>
            );
          })}
        </Select>
      ),
      span: 3,
    },
    {
      label: "Última Alteração",
      children: formatDateHourToBR(cotacoesData.caf_data),
      span: 3,
    },
  ];

  const aux = Object.entries(cotacoesData).map(([key, value], index) => {
    if (
      key !== "caf_data" &&
      key !== "caf_categoria" &&
      key !== "endpoint" &&
      key !== "path" &&
      key !== "caf_icms" &&
      key !== "caf_funrural"
    ) {
      const cotLabel = cafeOptions.find((label) => label.value === key);
      return {
        label: cotLabel ? cotLabel.label : key,
        children: (
          <Input
            value={value}
            disabled={isLoading}
            onChange={(event) => {
              const inputValue = event.target.value;
              const formattedValue = inputValue.trim();
              const newData = {
                ...cotacoesData,
                [key]: formattedValue,
              };
              setCotacoesData(newData);
            }}
          />
        ),
        span: 2,
      };
    }
  });

  const auxCheck = Object.entries(cotacoesData).map(([key, value], index) => {
    if (
      key !== "caf_data" &&
      key !== "caf_categoria" &&
      key !== "endpoint" &&
      key !== "path" &&
      key !== "caf_minimo" &&
      key !== "caf_maximo"
    ) {
      const cotLabel = cafeOptionsCheck.find((label) => label.value === key);
      return {
        label: cotLabel ? cotLabel.label : key,
        children: (
          <Checkbox
            checked={value}
            onChange={(event) => {
              const inputValue = event.target.checked;
              const formattedValue = inputValue;
              const newData = {
                ...cotacoesData,
                [key]: formattedValue,
              };
              setCotacoesData(newData);
            }}
          />
        ),
        span: 2,
      };
    }
  });

  async function getData(value) {
    setIsLoading(true);
    const currentPathWithQuery = `/cafe_bica/getLastCafBicaByCategoria/?caf_categoria=${value}`;
    const response = await request(currentPathWithQuery, {
      method: "GET",
    });
    const data = { ...response, endpoint: "updateCafBica", path: "cafe_bica" };
    Object.keys(data).forEach((el, index) => {
      if (typeof data[el] === "number" && el !== "caf_categoria") {
        data[el] = data[el].toFixed(2);
        data[el] = data[el].replace(".", ",");
      }
    });
    setCotacoesData(data);
    setIsLoading(false);
    console.log(data);
  }

  console.log(cotacoesData);

  return (
    <>
      <DescriptionsLayout
        title={"Intergrãos - Café - Físico - Bica - Alterando Mercado Futuro"}
        isLoading={isLoading}
        items={[...items, ...aux.filter(Boolean), ...auxCheck.filter(Boolean)]}
        extra={
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            icon={<FaSave />}
            disabled={isLoading}
            onClick={() => {
              console.log(cotacoesData);
              updateData(cotacoesData);
            }}
          >
            Atualizar
          </Button>
        }
      />
    </>
  );
}
