import React, { useState, useEffect } from "react";
import { request } from "../../functions/request";
import { updateData, validData } from "../../functions/functionsUtility";
import { Button, Descriptions, Input } from "antd";
import { formatDateToBR } from "../../functions/functionsUtility";
import { toast } from "react-toastify";
import { DescriptionsLayout } from "../../layout";
import { FaSave } from "react-icons/fa";
import { indicesFields } from "../../constants/constants";

export default function IndicesEconomicos() {
  const [cotacoesData, setCotacoesData] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    async function getData() {
      const response = await request(`/indices/getIndice`, {
        method: "GET",
      }).catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
      const data = { ...response };
      Object.keys(data).forEach((el, index) => {
        if (el === "ind_trdata" || el === "ind_selicdata") {
          data[el] = formatDateToBR(data[el]);
        }

        if (typeof data[el] === "number") {
          data[el] = data[el].toString().replace(".", ",");
        }
      });
      setCotacoesData(data);
      setIsLoading(false);
    }
    getData();
  }, []);

  return (
    <>
      <DescriptionsLayout
        title={"Índices Econômicos"}
        isLoading={isLoading}
        extra={
          <Button
            type="primary"
            htmlType="submit"
            icon={<FaSave />}
            disabled={isLoading}
            onClick={async () => {
              const result = await validData(cotacoesData, [
                "ind_cheqespec",
                "ind_emprpessoal",
              ]);
              if (!result) {
                toast.error(
                  "Preencha todos os campos obrigatórios! Campos: Empréstimo Pessoal e Cheque Especial"
                );
                return false;
              }
              updateData({
                ...cotacoesData,
                path: "indices",
                endpoint: "updateIndices",
              });
            }}
          >
            Atualizar
          </Button>
        }
      >
        {indicesFields.map((corte) => (
          <Descriptions.Item
            span={corte?.span ? corte.span : 3}
            label={corte.label}
            children={
              <Input
                type="text"
                value={cotacoesData[corte.value]}
                onChange={(event) => {
                  const newData = {
                    ...cotacoesData,
                    [corte.value]: event.target.value,
                  };
                  setCotacoesData(newData);
                }}
              />
            }
          />
        ))}
      </DescriptionsLayout>
    </>
  );
}
