import React, { useState } from "react";
import { Switch, Input, Drawer, Form, Button, Image } from "antd";
import TextArea from "antd/es/input/TextArea";
import { requestWithImage } from "../../../functions/request";
import { uniKey, validData } from "../../../functions/functionsUtility";
import { toast } from "react-toastify";
import { Content } from "antd/es/layout/layout";

function ModalCapitulo(props) {
  const [capaImage, setCapaImage] = useState(null);
  const saveData = async () => {
    const formData = new FormData();
    let dataAux = {
      cap_ativo: 0,
      cap_aberto: 0,
    };
    const canSave = await validData(props.contentModal, [
      "cap_nome",
      "cap_ordem",
      "cap_video",
      "cap_ativo",
    ]);
    if (!canSave) {
      toast.error("Preencha todos os campos obrigatórios! Campo: Nome, Vídeo");
      return;
    }
    for (const key in props?.contentModal) {
      if (
        props?.contentModal[key] !== null &&
        props?.contentModal[key] !== undefined
      ) {
        dataAux[key] = props?.contentModal[key];
      }
    }
    dataAux.index = uniKey();
    const newData = JSON.stringify(dataAux);
    formData.append("newData", newData);
    if (capaImage !== null) {
      formData.append("cap_capa", capaImage);
    }
    const response = await requestWithImage("/capitulos/insertCapitulo", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        toast.success("Série Salva com Sucesso!");
        props.onRequestClose();
        props.setContentModal({});
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erro ao Salvar Série!");
      });
  };

  console.log(props);
  return (
    <>
      <Drawer
        title={
          props?.contentModal?.cap_id
            ? "Editar Capítulo"
            : "Adicionar Novo Capítulo"
        }
        open={props.isAddOpen}
        onClose={props.onRequestClose}
        extra={
          <Button
            type="primary"
            onClick={(el) => {
              !props?.contentModal?.cap_id
                ? saveData()
                : props.changeCapitulo(props.contentModal, capaImage);
            }}
          >
            Salvar
          </Button>
        }
      >
        <Form layout="vertical">
          <Form.Item label={"Título"}>
            <Input
              value={props.contentModal.cap_nome}
              onChange={(e) =>
                props.setContentModal({
                  ...props.contentModal,
                  cap_nome: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label={"Descrição"}>
            <TextArea
              value={props.contentModal.cap_desc}
              onChange={(e) =>
                props.setContentModal({
                  ...props.contentModal,
                  cap_desc: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label={"Capa"}>
            <Content
              style={{
                display: "flex",
                gap: 5,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {props?.contentModal?.cap_capa && (
                <Image
                  src={`https://ruralbusiness.com.br/series/series/${props?.contentModal?.cap_capa}`}
                  style={{ aspectRatio: "3 / 2", borderRadius: 8 }}
                  width={"80%"}
                />
              )}
              <Input
                onChange={(e) => setCapaImage(e.target.files[0])}
                type="file"
                accept="image/*"
              />
            </Content>
          </Form.Item>
          <Form.Item label={"Link"}>
            <Input
              value={props.contentModal.cap_video}
              onChange={(e) =>
                props.setContentModal({
                  ...props.contentModal,
                  cap_video: e.target.value,
                })
              }
              type="text"
            />
          </Form.Item>
          <Form.Item label={"Ativo"}>
            <Switch
              onChange={(e) =>
                props.setContentModal({ ...props.contentModal, cap_ativo: e })
              }
              checked={props.contentModal.cap_ativo}
            />
          </Form.Item>
          <Form.Item label={"Aberto"}>
            <Switch
              onChange={(e) =>
                props.setContentModal({
                  ...props.contentModal,
                  cap_aberto: e,
                })
              }
              checked={props.contentModal.cap_aberto}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default ModalCapitulo;
