import React, { useState } from "react";
import { request } from "../../../functions/request";
import { Button, Checkbox, Descriptions, Form, Input, Select } from "antd";
import {
  updateData,
  formatDateHourToBR,
} from "../../../functions/functionsUtility";
import { FaSave } from "react-icons/fa";

const trigoOptions = [{ value: "cot_valor", label: "Valor", span: 3 }];

const estadosOptions = [
  { value: "RS", label: "Rio Grande do Sul" },
  { value: "PR", label: "Paraná" },
];

export default function IntergraosTrigoAgranel() {
  const [cotacoesData, setCotacoesData] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  async function getData(value) {
    setIsLoading(true);
    const response = await request(
      `/trigo_agranel/getLastTrigoAgranelByUf?uf=${value}`,
      {
        method: "GET",
      }
    );
    const data = {
      ...response,
      endpoint: "updateTrigoAgranel",
      path: "trigo_agranel",
    };
    Object.keys(data).forEach((el, index) => {
      if (typeof data[el] === "number" && el !== "cot_id") {
        data[el] = data[el].toFixed(2);
        data[el] = data[el].replace(".", ",");
      }
    });
    setCotacoesData(data);
    setIsLoading(false);
  }

  return (
    <Form>
      <Descriptions
        title={"Intergrãos - Trigo - Agranel"}
        bordered
        extra={
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            icon={<FaSave />}
            disabled={isLoading}
            onClick={() => {
              console.log(cotacoesData);
              updateData(cotacoesData);
            }}
          >
            Atualizar
          </Button>
        }
        style={{ padding: 10 }}
      >
        <Descriptions.Item
          label={"Estado"}
          span={3}
          children={
            <Select
              style={{ width: "100%", marginTop: "10px" }}
              onChange={(value) => {
                setCotacoesData({ ...cotacoesData, cot_uf: value });
                getData(value);
              }}
              placeholder="Selecione o estado"
            >
              {estadosOptions.map((el, index) => {
                return (
                  <Select.Option key={index} value={el.value}>
                    {el.label}
                  </Select.Option>
                );
              })}
            </Select>
          }
        />
        <Descriptions.Item
          label={"Última Alteração"}
          span={3}
          children={formatDateHourToBR(cotacoesData?.cot_data)}
        />
        {!isLoading &&
          trigoOptions.map((element) => (
            <Descriptions.Item
              label={element.label}
              span={element.span}
              children={
                element?.type === "checkbox" ? (
                  <Checkbox
                    checked={cotacoesData[element.value]}
                    onChange={(event) => {
                      const inputValue = event.target.checked;
                      const formattedValue = inputValue;
                      const newData = {
                        ...cotacoesData,
                        [element.value]: formattedValue,
                      };
                      setCotacoesData(newData);
                    }}
                  />
                ) : (
                  <Input
                    datatype=""
                    value={cotacoesData[element.value]}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      const formattedValue = inputValue.trim();
                      const newData = {
                        ...cotacoesData,
                        [element.value]: formattedValue,
                      };
                      setCotacoesData(newData);
                    }}
                  />
                )
              }
            />
          ))}
      </Descriptions>
    </Form>
  );
}
