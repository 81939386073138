import dayjs from "dayjs";

export function getTimeFromDate(timeString) {
  const hour = dayjs(timeString, { format: "HH:mm:ss" }).format("HH:mm:ss");
  return hour;
}

export function dateMask(value) {
  // Remove any non-numeric characters
  value = value.replace(/\D/g, "");

  // Apply mask as user types
  let maskedValue = "";
  if (value.length <= 2) {
    maskedValue = value.replace(/^(\d{2})/, "$1/");
  } else if (value.length <= 4) {
    maskedValue = value.replace(/^(\d{2})(\d{0,2})/, "$1/$2");
  } else if (value.length <= 8) {
    maskedValue = value.replace(/^(\d{2})(\d{2})(\d{0,4})/, "$1/$2/$3");
  } else {
    maskedValue = value
      .slice(0, 8)
      .replace(/^(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
  }

  return maskedValue;
}
