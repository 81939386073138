import { Descriptions, Form, Skeleton } from "antd";
import React from "react";

export default function DescriptionsLayout({
  title,
  items,
  extra,
  isLoading,
  children,
}) {
  return (
    <Form>
      <Descriptions
        title={title}
        items={items}
        bordered
        size="default"
        style={{ padding: 10 }}
        extra={extra}
      >
        {isLoading ? (
          <Descriptions.Item
            label={"Carregando..."}
            span={3}
            children={
              <Skeleton.Input active={isLoading} size={"large"} block />
            }
          />
        ) : (
          children
        )}
      </Descriptions>
    </Form>
  );
}
