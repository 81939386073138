import React, { useState, useEffect } from "react";
import { getDataWithEndpoint } from "../../../functions/request";
import {
  formatDateHourToBR,
  updateData,
} from "../../../functions/functionsUtility";
import { DescriptionMinMax } from "../../../layout";

const cortesType = [
  { label: "Boi", value: "cot_boi" },
  { label: "Vaca", value: "cot_vaca" },
  { label: "Garrote", value: "cot_garrote" },
];

export default function InterboisParaguai() {
  const [cotacoesData, setCotacoesData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getDataWithEndpoint("boi_paraguai", "getBoiParaguai")
      .then((data) => {
        setCotacoesData(data);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setHasError(err?.message ?? "Ocorreu um erro.");
        console.log(err);
      });
  }, []);

  return (
    <>
      <DescriptionMinMax
        title={"Interbois - Boi Paraguai (VALOR EM REAIS)"}
        onClickButton={async () => {
          updateData({
            ...cotacoesData,
            endpoint: "updateBoiParaguai",
            path: "boi_paraguai",
          });
        }}
        buttonText={"Atualizar"}
        data={cortesType}
        date={formatDateHourToBR(cotacoesData.cot_data)}
        state={cotacoesData}
        setState={setCotacoesData}
        isLoading={isLoading}
        hasError={hasError}
        hasValue={false}
      />
    </>
  );
}
