import { Button, DatePicker, Select, message } from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useEffect, useMemo, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { request } from "../../functions/request";
import { Consult } from "../../components/Consult";
import locale from "antd/es/date-picker/locale/pt_BR";
import { rangePresets } from "../../constants/constants";
import dayjs from "dayjs";
import DrawerAgroacoesCotacao from "../../components/ui/Drawer/DrawerAgroacoesCotacao";
import DrawerAgroacoesNome from "../../components/ui/Drawer/DrawerAgroacoesNome";
import { MdAdd } from "react-icons/md";
const { RangePicker } = DatePicker;

export default function Agroacoes() {
  const [formData, setFormData] = useState();
  const [agroacoesNome, setAgroacoesNome] = useState([]);
  const [agroacoesCotacoes, setAgroacoesCotacoes] = useState([]);
  const [isLoading, setIsLoading] = useState({
    agroacoesNome: true,
    agroacoesCotacao: false,
  });
  const [isDrawerOpen, setDrawerOpen] = useState({
    quotation: false,
    name: false,
    shouldRefreshToggle: false,
  });
  const [hasError, setHasError] = useState(false);
  const [quotationDrawerContent, setQuotationDrawerContent] = useState({});
  const [nameDrawerContent, setNameDrawerContent] = useState({});

  useEffect(() => {
    setIsLoading((prevState) => ({
      ...prevState,
      agroacoesNome: true,
    }));
    request(`/cotacoes/getCotacoesNome`, {
      method: "GET",
    })
      .then((res) => {
        setAgroacoesNome(
          res[0].map((dt) => ({
            value: dt.acoes_id,
            label: `${dt.acoes_id} (${dt.acoes_nome})`,
          }))
        );
      })
      .finally(() => {
        setIsLoading((prevState) => ({
          ...prevState,
          agroacoesNome: false,
        }));
      })
      .catch((err) => {
        console.log(err);
        message.error("Um erro inesperado ocorreu. Consulte um administrador.");
      });
  }, [isDrawerOpen.shouldRefreshToggle]);

  useEffect(() => {
    if (formData) {
      fetchData(formData);
    }
  }, [isDrawerOpen.shouldRefreshToggle]);

  async function fetchData(values) {
    console.log(values);
    const body = {
      acoes_id: values.id,
      dataInicio: dayjs(values.date[0]).format("YYYY-MM-DD"),
      dataFim: dayjs(values.date[1]).format("YYYY-MM-DD"),
    };

    setIsLoading((prevState) => ({
      ...prevState,
      agroacoesCotacao: true,
    }));
    request(`/cotacoes/getCotacoesAcaoByNameAndData`, {
      method: "POST",
      body: JSON.stringify(body),
    })
      .then((res) => {
        console.log(res);
        const formattedData = res[0].map((res) => {
          res.acoes_data = dayjs(res?.acoes_data).format("DD/MM/YYYY");
          res.acoes_abertura = parseFloat(res?.acoes_abertura);
          res.acoes_min = parseFloat(res?.acoes_min);
          res.acoes_max = parseFloat(res?.acoes_max);
          res.acoes_med = parseFloat(res?.acoes_med);
          res.acoes_valor = parseFloat(res?.acoes_valor);
          res.acoes_porcentagem = parseFloat(res?.acoes_porcentagem);
          res.acoes_compra = parseFloat(res?.acoes_compra);

          // Check if parseFloat resulted in NaN, if so, return null
          res.acoes_abertura = isNaN(res.acoes_abertura)
            ? null
            : new Intl.NumberFormat("pt-BR").format(res.acoes_abertura);
          res.acoes_min = isNaN(res.acoes_min)
            ? null
            : new Intl.NumberFormat("pt-BR").format(res.acoes_min);
          res.acoes_max = isNaN(res.acoes_max)
            ? null
            : new Intl.NumberFormat("pt-BR").format(res.acoes_max);
          res.acoes_med = isNaN(res.acoes_med)
            ? null
            : new Intl.NumberFormat("pt-BR").format(res.acoes_med);
          res.acoes_valor = isNaN(res.acoes_valor)
            ? null
            : new Intl.NumberFormat("pt-BR").format(res.acoes_valor);
          res.acoes_porcentagem = isNaN(res.acoes_porcentagem)
            ? null
            : new Intl.NumberFormat("pt-BR").format(res.acoes_porcentagem);
          res.acoes_compra = isNaN(res.acoes_compra)
            ? null
            : new Intl.NumberFormat("pt-BR").format(res.acoes_compra);

          res.acoes_venda = isNaN(parseInt(res?.acoes_venda))
            ? null
            : parseInt(res?.acoes_venda);
          return res;
        });
        if (res[0].length <= 0) {
          setAgroacoesCotacoes([]);
          setHasError(true);
        } else {
          setAgroacoesCotacoes(formattedData);
          setHasError(false);
        }
      })
      .finally(() => {
        setIsLoading((prevState) => ({
          ...prevState,
          agroacoesCotacao: false,
        }));
      })
      .catch((err) => {
        console.log(err);
        setAgroacoesCotacoes([]);
        setHasError(true);
        setIsLoading((prevState) => ({
          ...prevState,
          agroacoesCotacao: false,
        }));
      });
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: "acoes_id",
        header: "Ação ID",
        size: 55,
      },
      {
        accessorKey: "acoes_data",
        header: "Data",
        size: 90,
      },
      {
        accessorKey: "acoes_abertura",
        header: "Abertura",
        size: 70,
      },
      {
        accessorKey: "acoes_min",
        header: "Mínimo",
        size: 70,
      },
      {
        accessorKey: "acoes_max",
        header: "Máximo",
        size: 70,
      },
      {
        accessorKey: "acoes_med",
        header: "Média",
        size: 70,
      },
      {
        accessorKey: "acoes_valor",
        header: "Valor",
        size: 70,
      },
      {
        accessorKey: "acoes_porcentagem",
        header: "Porcentagem",
        size: 70,
      },
      {
        accessorKey: "acoes_compra",
        header: "Compra",
        size: 70,
      },
      {
        accessorKey: "acoes_venda",
        header: "Venda",
        size: 70,
      },
      {
        accessorKey: "acoes_negocios",
        header: "Negócios",
        size: 70,
      },
      {
        accessorKey: "acoes_qtd_titulos",
        header: "QTD. Títulos",
        size: 70,
      },
    ],
    []
  );

  return (
    <>
      <DrawerAgroacoesCotacao
        data={quotationDrawerContent}
        open={isDrawerOpen.quotation}
        agroacoesNome={agroacoesNome}
        onClose={() =>
          setDrawerOpen((prevState) => ({ ...prevState, quotation: false }))
        }
        setDrawer={setDrawerOpen}
      />
      <DrawerAgroacoesNome
        data={nameDrawerContent}
        open={isDrawerOpen.name}
        onClose={() =>
          setDrawerOpen((prevState) => ({ ...prevState, name: false }))
        }
        setDrawer={setDrawerOpen}
      />
      <Consult.Root panelSizes={["25%", "auto"]}>
        <Consult.Form
          title={"Agroações"}
          onFinish={(values) => {
            setFormData(values);
            fetchData(values);
          }}
          variant="filled"
          initialValues={{
            id: "",
            date: "",
          }}
        >
          <Consult.Field label={"ID Ação"} name={"id"}>
            <Select
              showSearch
              allowClear
              loading={isLoading.agroacoesNome}
              optionFilterProp="children"
              placeholder="Selecione uma Agroação"
              filterOption={(input, option) =>
                ((option?.label).toLowerCase() ?? "").includes(
                  input.toLowerCase()
                )
              }
              options={agroacoesNome}
            />
          </Consult.Field>
          <Consult.Field
            label={"Data Inicial e Final"}
            name={"date"}
            hasFeedback
            rules={[
              { required: true, message: "Escolha uma data de início e fim" },
            ]}
          >
            <RangePicker
              style={{ width: "100%" }}
              presets={rangePresets}
              locale={locale}
            />
          </Consult.Field>
          <Consult.Field>
            <Consult.Button htmlType="submit" title="Buscar" />
          </Consult.Field>
          <Content
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              borderTop: "1px solid lightgray",
              paddingTop: 20,
            }}
          >
            <Button
              type="default"
              size="large"
              icon={<MdAdd />}
              onClick={() => {
                setQuotationDrawerContent({});
                setDrawerOpen((prevState) => ({
                  ...prevState,
                  quotation: true,
                }));
              }}
              block
            >
              Adicionar Cotação
            </Button>
            <Button
              type="dashed"
              size="large"
              icon={<MdAdd />}
              onClick={() => {
                setNameDrawerContent({});
                setDrawerOpen((prevState) => ({
                  ...prevState,
                  name: true,
                }));
              }}
              block
            >
              Adicionar Nome
            </Button>
          </Content>
        </Consult.Form>
        <Consult.Table
          data={agroacoesCotacoes}
          columns={columns}
          isLoading={isLoading.agroacoesCotacao}
          hasError={hasError}
          positionActionsColumn="first"
          initialState={{
            showColumnFilters: true,
            pagination: { pageIndex: 0, pageSize: 100 },
            density: "compact",
          }}
          muiTableBodyProps={{
            sx: (theme) => ({
              "& tr:nth-of-type(odd)": {
                backgroundColor: "#f4f4f4",
              },
            }),
          }}
          renderRowActions={({ row }) => (
            <Content
              style={{
                display: "flex",
                gap: "5px",
                margin: 5,
              }}
            >
              <Button
                type="primary"
                size="middle"
                onClick={() => {
                  setQuotationDrawerContent(row.original);
                  setDrawerOpen((prevState) => ({
                    ...prevState,
                    quotation: true,
                  }));
                }}
                icon={<AiFillEdit />}
              />
              <Button
                size="middle"
                onClick={() => {
                  setNameDrawerContent(row.original);
                  setDrawerOpen((prevState) => ({ ...prevState, name: true }));
                }}
              >
                Nome
              </Button>
            </Content>
          )}
        />
      </Consult.Root>
    </>
  );
}
