import React, { useMemo, useEffect, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Button, Divider, Typography, message } from "antd";
import { request } from "../../../functions/request";
import Aguarde from "../../../components/aguarde/aguarde";
import { AiOutlineSearch, AiFillEdit, AiFillDelete } from "react-icons/ai";
import ModalAddVideo from "../../../components/Modal/Video/ModalAddVideo";
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import { FaPlus } from "react-icons/fa";
import { formatDateHourToBR } from "../../../functions/functionsUtility";
import { Content } from "antd/es/layout/layout";
import { videosMercados } from "../../../constants/constants";
import { FileUpload } from "../../../components/FileUpload";
import { Modal } from "../../../components/ui";

const Videos = () => {
  const [addVideo, setAddVideo] = useState([]);
  const [isModalAddOpen, setIsModalAddOpen] = useState(false);
  const [modalDeleteData, setModalDeleteData] = useState();
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState({});

  const [haveResult, setHaveResult] = useState(false);
  const [toggleReload, setToggleReload] = useState(false);
  const [tableData, setTableData] = useState([]);

  const findNameByValue = (value) => {
    const mercado = videosMercados.find((item) => item.value === value);
    return mercado ? mercado.label : null;
  };

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const currentPathWithQuery = "/video_analise/getVideosAnalise";
        const response = await request(currentPathWithQuery, {
          method: "GET",
        });
        const aux = [];
        response.map((item) => {
          aux.push({
            ...item,
            vid_data: formatDateHourToBR(item.vid_data),
          });
        });
        setTableData(aux);
        setHaveResult(true);
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    };
    fetchTableData();
  }, [toggleReload]);

  const columns = useMemo(
    () => [
      {
        accessorKey: "vid_titulo",
        header: "Título",
      },
      {
        accessorKey: "vid_mercado",
        header: "Mercado",
        size: 50,
        Cell: ({ cell }) => findNameByValue(cell.getValue()),
      },
      {
        accessorKey: "vid_data",
        header: "Data",
      },
      {
        accessorKey: "vid_end",
        header: "Vídeo",
        size: 100,
        Cell: ({ cell, row }) =>
          cell.getValue() ? (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <iframe
                src={`https://player.vimeo.com/video/${cell.getValue()}?h=${
                  row.original.vid_hash
                }`}
                width="150"
                height="80"
                frameborder="0"
                allowfullscreen
              ></iframe>
            </div>
          ) : null,
      },
      {
        accessorKey: "vid_arquivo",
        header: "Vídeo Local",
        size: 100,
        Cell: ({ cell }) => (
          <Content
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "100%",
              textWrap: "wrap",
            }}
          >
            <FileUpload
              addVideo={addVideo}
              value={cell.row.original}
              setAddVideo={setAddVideo}
              setToggleReload={setToggleReload}
            />
          </Content>
        ),
      },
    ],
    []
  );

  console.log(editOpen);

  return (
    <>
      <Modal
        title={"Você tem certeza que deseja excluir esse Vídeo?"}
        subtitle={"Essa ação será irreversível!"}
        open={isModalDeleteOpen}
        onCancel={() => setIsModalDeleteOpen(false)}
        onOk={async () => {
          await request(
            "/video_analise/deleteVideoAnalise/" + modalDeleteData.vid_id,
            {
              method: "DELETE",
            }
          )
            .then((res) => {
              if (!modalDeleteData.vid_arquivo) {
                setToggleReload((prevState) => !prevState);
                setIsModalDeleteOpen(false);
              }
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
              message.error("Erro ao Excluir Análise!");
            });

          if (modalDeleteData.vid_arquivo) {
            await request(
              "/video_analise/deleteVideoFile/" + modalDeleteData.vid_arquivo,
              {
                method: "DELETE",
              }
            )
              .then((res) => {
                console.log(res);
                message.success("Arquivo Excluído com Sucesso!");
                setToggleReload((prevState) => !prevState);
                setIsModalDeleteOpen(false);
              })
              .catch((err) => {
                console.log(err);
                message.error("Erro ao Excluir Arquivo!");
              });
          }
        }}
      />
      <ModalAddVideo
        data={editOpen}
        open={isModalAddOpen}
        onFinish={() => setToggleReload((prevState) => !prevState)}
        onCancel={() => setIsModalAddOpen(false)}
      />
      {haveResult ? (
        tableData.length > 0 ? (
          <MaterialReactTable
            columns={columns}
            data={tableData}
            defaultColumn={{
              minSize: 5,
              maxSize: 160,
              size: 100,
            }}
            enableColumnFilterModes
            enableColumnSorting
            enableRowActions
            enableRowVirtualization
            enableFacetedValues
            columnFilterModeOptions={null}
            initialState={{
              showColumnFilters: true,
              pagination: { pageIndex: 0, pageSize: 10 },
            }}
            localization={MRT_Localization_PT_BR}
            positionActionsColumn="last"
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "", // Altere o texto do cabeçalho, se necessário
                size: 100, // Faça a coluna de ações um pouco mais larga
              },
            }}
            renderRowActions={({ row }) => (
              <Content style={{ display: "flex", gap: 10 }}>
                <Button
                  icon={<AiFillEdit />}
                  size="large"
                  onClick={(el) => {
                    setEditOpen(row.original);
                    setIsModalAddOpen(true);
                  }}
                >
                  Editar
                </Button>
                <Button
                  icon={<AiFillDelete />}
                  danger
                  size="large"
                  onClick={() => {
                    setIsModalDeleteOpen(true);
                    setModalDeleteData(row.original);
                  }}
                >
                  Excluir
                </Button>
              </Content>
            )}
            renderTopToolbarCustomActions={() => (
              <Content
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                  padding: 10,
                }}
              >
                <Typography.Title
                  level={4}
                  style={{
                    margin: 0,
                    display: "flex",
                    gap: 5,
                    alignItems: "center",
                  }}
                >
                  Vídeos
                </Typography.Title>
                <Divider type="vertical" />
                <Button
                  type="primary"
                  icon={<FaPlus />}
                  size="large"
                  onClick={(el) => {
                    setEditOpen({});
                    setIsModalAddOpen(true);
                  }}
                >
                  Adicionar Vídeo
                </Button>
              </Content>
            )}
            muiTableHeadCellFilterTextFieldProps={{
              placeholder: "Filtrar",
            }}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2>Ops! Não encontramos resultados para a busca.</h2>
            <AiOutlineSearch size={48} color="#888" />
            <p>Tente pesquisar novamente com outros termos ou filtros.</p>
          </div>
        )
      ) : (
        <Aguarde />
      )}
    </>
  );
};

export default Videos;
