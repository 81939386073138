import {
  Button,
  Descriptions,
  Form,
  Input,
  Result,
  Select,
  Skeleton,
  Spin,
} from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { FaSave } from "react-icons/fa";
import { monthsFields } from "../../constants/constants";

export default function DescriptionPeriodValue({
  title,
  date,
  items,
  extra,
  buttonText,
  onClickButton,
  datePickerValue,
  datePickerOnChange,
  inputValue,
  state,
  setState,
  stateKey,
  isLoading = true,
  hasValue = true,
  hasError = false,
  children,
}) {
  // os inputs devem ficar sem nada
  return (
    <Form>
      <Descriptions
        items={items}
        title={title}
        bordered
        size="default"
        style={{ padding: 10 }}
        extra={
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            icon={<FaSave />}
            disabled={isLoading || hasError}
            onClick={onClickButton}
          >
            {buttonText ? buttonText : inputValue ? "Atualizar" : "Inserir"}
          </Button>
        }
      >
        {hasError ? (
          <Descriptions.Item
            children={
              <Result
                status="500"
                title="500"
                subTitle={
                  hasError?.message ??
                  "Um erro inesperado ocorreu, tente novamente."
                }
              />
            }
          />
        ) : (
          <>
            <Descriptions.Item
              label={"Última Atualização"}
              span={3}
              children={date}
            />
            {datePickerOnChange && (
              <Descriptions.Item
                label={"Período"}
                span={3}
                children={
                  <Content style={{ display: "flex", gap: 5, width: "100%" }}>
                    <Select
                      placeholder={"Selecione o Mês"}
                      onChange={(value) => datePickerOnChange(value, "month")}
                      options={monthsFields}
                      loading={isLoading}
                      style={{ width: "100%", maxWidth: "200px" }}
                    />
                    <Input
                      placeholder="Ano"
                      value={datePickerValue}
                      disabled={isLoading}
                      type="number"
                      onChange={(e) =>
                        datePickerOnChange(e.target.value, "year")
                      }
                      style={{ width: "100%" }}
                    />
                  </Content>
                }
              />
            )}
            {hasValue && (
              <Descriptions.Item
                label={"Valor"}
                span={3}
                children={
                  <Content
                    style={{ display: "flex", alignItems: "center", gap: 10 }}
                  >
                    <Input
                      // className="input-number"
                      placeholder="00,00"
                      value={inputValue}
                      disabled={isLoading}
                      onChange={(event) => {
                        const inputValue = event.target.value;
                        const formattedValue = inputValue;
                        const newData = {
                          ...state,
                          [stateKey]: formattedValue,
                        };
                        setState(newData);
                      }}
                    />
                    {isLoading && <Spin size="small" />}
                  </Content>
                }
              />
            )}
            {isLoading && children ? (
              <Descriptions.Item
                label={"Carregando..."}
                span={3}
                children={
                  <Skeleton.Input active={isLoading} size={"large"} block />
                }
              />
            ) : (
              children
            )}
          </>
        )}
      </Descriptions>
    </Form>
  );
}
