import { ConsultButton } from "./ConsultButton";
import { ConsultField } from "./ConsultField";
import { ConsultForm } from "./ConsultForm";
import { ConsultModal } from "./ConsultModal";
import { ConsultRoot } from "./ConsultRoot";
import { ConsultTable } from "./ConsultTable";

export const Consult = {
  Root: ConsultRoot,
  Form: ConsultForm,
  Field: ConsultField,
  Button: ConsultButton,
  Table: ConsultTable,
  Modal: ConsultModal,
};
