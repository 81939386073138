import React, { useMemo, useState } from "react";
import { Consult } from "../../components/Consult";
import {
  analisesOpcoes,
  consultarOpcoes,
  rangePresets,
} from "../../constants/constants";
import dayjs from "dayjs";
import locale from "antd/es/date-picker/locale/pt_BR";
import { Button, DatePicker, Input, Radio, Select } from "antd";
import { request } from "../../functions/request";
import { Content } from "antd/es/layout/layout";
import { AiFillEdit, AiFillEye } from "react-icons/ai";
import { Link } from "react-router-dom";
const { RangePicker } = DatePicker;

export default function ConsultAnalise() {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [data, setData] = useState([]);
  const [modalContent, setModalContent] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const columns = useMemo(
    () => [
      {
        accessorKey: "com_numero",
        header: "ID",
        size: 40,
      },
      {
        accessorKey: "com_titulo",
        header: "Título",
        size: 100,
      },
      {
        accessorKey: "com_data",
        header: "Data",
        size: 30,
      },
      {
        accessorKey: "com_secao",
        header: "Seção",
        size: 60,
        filterVariant: "multi-select",
      },
    ],
    []
  );

  const transformSectionComent = (section) => {
    switch (section) {
      case 1:
        return "Boi";
      case 3:
        return "Frango";
      case 4:
        return "Leite";
      case 5:
        return "Suínos";
      case 10:
        return "Trigo";
      case 11:
        return "Algodão";
      case 21:
        return "Soja";
      case 22:
        return "Milho";
      case 23:
        return "Café";
      case 24:
        return "Açucar";
      default:
        return "";
    }
  };

  const makeQuery = (form) => {
    let queryParams = `data_start=${dayjs(form.data[0]).format(
      "YYYY-MM-DD"
    )}&data_final=${dayjs(form.data[1]).format("YYYY-MM-DD")}`;

    if (form?.palavra) {
      queryParams += `&req_type=${encodeURIComponent(
        form.opcao
      )}&input_query=${encodeURIComponent(form.palavra)}`;
    }
    if (form?.secao) {
      queryParams += `&com_secao=${encodeURIComponent(form.secao)}`;
    }

    return queryParams;
  };

  const fetchData = async (form) => {
    try {
      setHasError(false);
      setIsLoading(true);
      await request(`/comentario/getcomentarios?${makeQuery(form)}`, {
        method: "GET",
      })
        .then((response) => {
          const formattedData = response[0].map((res) => {
            res.com_data = dayjs(res?.com_data).format("DD/MM/YYYY HH:mm");
            res.com_secao = transformSectionComent(res?.com_secao);
            return res;
          });
          if (response[0].length <= 0) {
            setData([]);
            setHasError(true);
          } else {
            setData(formattedData);
          }
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setData([]);
          setIsLoading(false);
          setHasError(true);
        });
    } catch (error) {
      console.error("Erro na requisição:", error);
      setData([]);
      setIsLoading(false);
      setHasError(true);
    }
  };

  return (
    <Consult.Root>
      <Consult.Form
        title={"Consultar Análises"}
        onFinish={(values) => {
          fetchData(values);
        }}
        variant="filled"
        initialValues={{
          secao: false,
          opcao: "busca_completa",
        }}
      >
        <Consult.Field label={"Seção"} name={"secao"}>
          <Select
            showSearch
            style={{ width: "100%" }}
            optionFilterProp="children"
            placeholder="Selecione uma seção"
            defaultValue={{ value: false, label: "Todos" }}
            filterOption={(input, option) =>
              (option?.label ?? "").includes(input)
            }
            options={analisesOpcoes}
          />
        </Consult.Field>
        <Consult.Field
          label={"Data Inicial e Final"}
          name={"data"}
          hasFeedback
          rules={[
            { required: true, message: "Escolha uma data de início e fim" },
          ]}
        >
          <RangePicker
            style={{ width: "100%" }}
            presets={rangePresets}
            locale={locale}
          />
        </Consult.Field>
        <Consult.Field label={"Palavra-Chave"} name={"palavra"}>
          <Input placeholder="Ex.: BOI GORDO EUA | SOJA EXPORTAÇÃO | MILHO CHICAGO" />
        </Consult.Field>
        <Consult.Field name={"opcao"}>
          <Radio.Group defaultValue="busca_completa" buttonStyle="outline">
            {consultarOpcoes.map((option) => (
              <Radio.Button key={option.value} value={option.value}>
                {option.text}
              </Radio.Button>
            ))}
          </Radio.Group>
        </Consult.Field>
        <Consult.Field>
          <Consult.Button htmlType="submit" title="Buscar" />
        </Consult.Field>
      </Consult.Form>
      <Consult.Table
        data={data}
        columns={columns}
        isLoading={isLoading}
        hasError={hasError}
        initialState={{
          showColumnFilters: true,
          pagination: { pageIndex: 0, pageSize: 100 },
        }}
        renderRowActions={({ row }) => (
          <Content
            style={{
              display: "flex",
              gap: "5px",
              flexWrap: "wrap",
            }}
          >
            <Button
              onClick={() => {
                setModalContent(row.original);
                setIsModalOpen(true);
              }}
              icon={<AiFillEye />}
            >
              Visualizar
            </Button>
            <Link
              to={`/comentario/getcomentarios/${row.original.com_numero}`}
              target="_blank"
            >
              <Button icon={<AiFillEdit />}>Editar</Button>
            </Link>
          </Content>
        )}
      >
        <Consult.Modal
          title={modalContent?.com_titulo}
          date={modalContent?.com_data}
          text={modalContent?.com_texto}
          isOpen={isModalOpen}
          onRequestClose={() => setIsModalOpen(false)}
        />
      </Consult.Table>
    </Consult.Root>
  );
}
