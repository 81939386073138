import { Form } from "antd";
import React from "react";

export function ConsultField({ label, name, children, ...props }) {
  return (
    <Form.Item label={label} name={name} {...props}>
      {children}
    </Form.Item>
  );
}
