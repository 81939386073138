import { MaterialReactTable } from "material-react-table";
import React, { useMemo } from "react";
import { VscCircleLargeFilled } from "react-icons/vsc";
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import { Modal } from "antd";

const customStyles = {
  content: {
    width: "80%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function ModalSeriePreview(props) {
  const columns = useMemo(
    () => [
      {
        accessorKey: "cap_ordem", //access nested data with dot notation
        header: "Ordem",
        size: 50,
      },

      {
        accessorKey: "cap_nome", //access nested data with dot notation
        header: "Nome do Capitulo",
        size: 200,
      },
      {
        accessorKey: "cap_ativo", //normal accessorKey
        header: "Ativo",
        size: 100,
        Cell: ({ cell }) =>
          cell.getValue() === 1 ? (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <VscCircleLargeFilled
                color="info"
                fontSize="small"
                className="active-icon"
              />
              Ativo
            </div>
          ) : cell.getValue() === 0 ? (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <VscCircleLargeFilled
                color="info"
                fontSize="small"
                style={{ color: "var( --gray-color)" }}
              />
              Offline
            </div>
          ) : null,
      },
    ],
    []
  );

  return (
    <>
      <Modal
        title={props?.previewContent?.serie_nome}
        open={props.isPreviewOpen}
        onCancel={props.onRequestClose}
        width={"90vw"}
        centered
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        style={{ width: "100%" }}
        styles={{ body: { height: "80vh" } }}
      >
        <div style={{ height: "100%", overflowY: "auto" }}>
          <MaterialReactTable
            style={{ width: "100%" }}
            columns={columns}
            data={props?.previewContent?.data}
            enableColumnFilterModes
            enableColumnSorting
            localization={MRT_Localization_PT_BR}
            positionActionsColumn="last"
          />
        </div>
      </Modal>
    </>
  );
}

export default ModalSeriePreview;
