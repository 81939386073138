import React, { useMemo, useEffect, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Button, Divider, Typography } from "antd";
import { request, requestWithImage } from "../../../functions/request";
import Aguarde from "../../../components/aguarde/aguarde";
import { useNavigate } from "react-router-dom";
import { BiRightArrow } from "react-icons/bi";
import {
  AiOutlineSearch,
  AiFillEdit,
  AiFillEye,
  AiOutlinePause,
} from "react-icons/ai";
import ModalSeriePreview from "../../../components/Modal/Series/ModalSeriePreview";
import ModalAddSerie from "../../../components/Modal/Series/ModalAddSerie";
import { VscCircleLargeFilled } from "react-icons/vsc";
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import { confirmAlert } from "react-confirm-alert";
import { PiTelevisionFill } from "react-icons/pi";
import { toast } from "react-toastify";
import { uniKey, validData } from "../../../functions/functionsUtility";
import { Content } from "antd/es/layout/layout";
import { FaPlus } from "react-icons/fa";

const Series = () => {
  const navigate = useNavigate();
  //Modal Preview
  const [previewContent, setPreviewContent] = useState("");
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  //Modal Add
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [editOpen, setEditOpen] = useState({});

  const [haveResult, setHaveResult] = useState(false);
  const [tableData, setTableData] = useState([]);
  const confirmStop = (row) => {
    confirmAlert({
      title:
        row.original.serie_ativa === 1
          ? "Você tem certeza que deseja pausar essa série?"
          : "Você tem certeza que deseja ativar essa série?",
      message:
        row.original.serie_ativa === 1
          ? "Todos os seus capitulos sairão do ar e não poderão ser acessados pelos usuários."
          : "Todos os seus capitulos voltarão a ficar disponíveis para os usuários.",
      buttons: [
        {
          label: "Não",
          className: "button-delete",
          onClick: () => {},
        },
        {
          label: "Sim",
          className: "button-save",
          onClick: () => {
            const updatedRow = {
              ...row.original,
              serie_ativa: row.original.serie_ativa === 1 ? 0 : 1,
            };
            changeSerie(updatedRow);
          },
        },
      ],
    });
  };

  const changeSerie = async (row, serie_banner = null, serie_capa = null) => {
    const result = await validData(row, [
      "serie_nome",
      "serie_capitulos",
      "serie_ativa",
    ]);
    if (!result) {
      toast.error("Preencha todos os campos obrigatórios! Campo: Nome");
      return false;
    }

    try {
      const newData = JSON.stringify({ ...row, index: uniKey() });
      const formData = new FormData();
      formData.append("newData", newData);
      if (serie_banner !== null) {
        formData.append("serie_banner", serie_banner);
      }
      if (serie_capa !== null) {
        formData.append("serie_capa", serie_capa);
      }
      const response = await requestWithImage("/series/updateSeries", {
        method: "PUT",
        body: formData,
      })
        .then((res) => {
          toast.success("Série Salva com Sucesso!");
          setIsAddOpen(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Erro ao Salvar Série!");
        });
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
  };

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const currentPathWithQuery = "/series/getAllSeries";
        const response = await request(currentPathWithQuery, {
          method: "GET",
        });
        setTableData(response);
        setHaveResult(true);
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    };
    fetchTableData();
  }, []);
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "serie_id", //access nested data with dot notation
        header: "Id",
        size: 10,
      },

      {
        accessorKey: "serie_nome", //access nested data with dot notation
        header: "Nome da Série",
        size: 50,
      },
      {
        accessorKey: "serie_capitulos", //normal accessorKey
        header: "Quantidade de Capítulos",
        size: 50,
      },
      {
        accessorKey: "serie_ativa",
        header: "Serie Ativa",
        size: 50,
        Cell: ({ cell }) =>
          cell.getValue() === 1 ? (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <VscCircleLargeFilled
                color="info"
                fontSize="small"
                className="active-icon"
              />
              Ativo
            </div>
          ) : cell.getValue() === 0 ? (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <VscCircleLargeFilled
                color="info"
                fontSize="small"
                style={{ color: "var( --gray-color)" }}
              />
              Offline
            </div>
          ) : null,
      },
    ],
    []
  );
  const addElement = (el, row) => {};
  const editElement = (el, row) => {
    navigate(
      `/series/editar/${row.original.serie_id}` +
        `?nome=${encodeURIComponent(row.original.serie_nome)}`
    );
  };
  const openModal = async (content) => {
    try {
      const currentPathWithQuery = `/capitulos/getCapitulos?id=${encodeURIComponent(
        content.original.serie_id
      )}`;
      const response = await request(currentPathWithQuery, {
        method: "GET",
      });
      setIsPreviewOpen(true);
      setPreviewContent({
        data: response,
        serie_nome: content.original.serie_nome,
      });
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
  };
  const closeModal = () => {
    setIsPreviewOpen(false);
  };

  return (
    <>
      <ModalSeriePreview
        isPreviewOpen={isPreviewOpen}
        previewContent={previewContent}
        onRequestClose={closeModal}
      />
      <ModalAddSerie
        editOpen={editOpen}
        setEditOpen={setEditOpen}
        isAddOpen={isAddOpen}
        onRequestClose={() => setIsAddOpen(false)}
        changeSeries={changeSerie}
        index={tableData[tableData.length - 1]?.serie_id + 1}
      />
      {haveResult ? (
        tableData.length > 0 ? (
          <MaterialReactTable
            columns={columns}
            data={tableData}
            defaultColumn={{
              minSize: 5, //allow columns to get smaller than default
              maxSize: 100,
              size: 100,
            }}
            enableColumnFilterModes
            enableColumnSorting
            enableRowActions
            localization={MRT_Localization_PT_BR}
            positionActionsColumn="last"
            enableRowVirtualization
            enableFacetedValues
            columnFilterModeOptions={null}
            initialState={{
              showColumnFilters: true,
              pagination: { pageIndex: 0, pageSize: 20 },
            }}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "", // Altere o texto do cabeçalho, se necessário
                size: 100, // Faça a coluna de ações um pouco mais larga
              },
            }}
            renderRowActions={({ row }) => (
              <div
                style={{ display: "flex", gap: "5px", justifyContent: "end" }}
              >
                <Button
                  onClick={(el) => openModal(row)}
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <AiFillEye />
                  Visualizar
                </Button>
                <Button
                  onClick={(el) => editElement(el.value, row)}
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <PiTelevisionFill />
                  Capítulos
                </Button>
                <Button
                  onClick={(el) => {
                    setEditOpen(row.original);
                    setIsAddOpen(true);
                  }}
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <AiFillEdit />
                  Editar
                </Button>
                <Button
                  onClick={(el) => confirmStop(row)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {row.original.serie_ativa ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <AiOutlinePause /> Pausar
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <BiRightArrow /> Ativar
                    </div>
                  )}
                </Button>
              </div>
            )}
            renderTopToolbarCustomActions={() => (
              <Content
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 20,
                  padding: 10,
                }}
              >
                <Typography.Title
                  level={4}
                  style={{
                    margin: 0,
                    display: "flex",
                    gap: 5,
                    alignItems: "center",
                  }}
                >
                  Séries
                </Typography.Title>
                <Divider type="vertical" />
                <Button
                  type="primary"
                  icon={<FaPlus />}
                  size="large"
                  onClick={(el) => {
                    setEditOpen({});
                    setIsAddOpen(true);
                  }}
                >
                  Adicionar Série
                </Button>
              </Content>
            )}
            muiTableHeadCellFilterTextFieldProps={{
              placeholder: "Filtrar",
            }}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "80%",
            }}
          >
            <h2>Ops! Não encontramos resultados para a busca.</h2>
            <AiOutlineSearch size={48} color="#888" />
            <p>Tente pesquisar novamente com outros termos ou filtros.</p>
          </div>
        )
      ) : (
        <Aguarde />
      )}
    </>
  );
};

export default Series;
