import { Button, Drawer, Form, Input, Switch, Tag, message } from "antd";
import { useForm } from "antd/es/form/Form";
import React, { useEffect, useState } from "react";
import { BiInfoCircle } from "react-icons/bi";
import { FaSave } from "react-icons/fa";
import { request } from "../../../functions/request";

export default function DrawerAgroacoesNome({
  open,
  onClose,
  data,
  setDrawer,
}) {
  const [form] = useForm();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      acoes_id: data?.acoes_id,
      acoes_nome: data?.acoes_nome,
      acoes_ativas: 1,
    });
  }, [data, open]);

  async function handleInsert(values) {
    values.acoes_ativas === false
      ? (values.acoes_ativas = 0)
      : (values.acoes_ativas = 1);
    console.log(values);

    try {
      request("/cotacoes/insertCotacaoNome", {
        method: "POST",
        body: JSON.stringify(values),
      })
        .then((res) => {
          console.log(res);
        })
        .finally(() => {
          onClose && onClose();
          setDrawer &&
            setDrawer((prevState) => ({
              ...prevState,
              shouldRefreshToggle: !prevState.shouldRefreshToggle,
            }));
          message.success("Ação realizada com sucesso.");
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error("Error during fetch:", error);
      message.error("Upload falhou, consulte um administrador.");
    }
  }

  async function handleUpdate(values) {
    console.log(values);
    try {
      request(`/cotacoes/updateCotacaoNome/${values.acoes_id}`, {
        method: "POST",
        body: JSON.stringify(values),
      })
        .then((res) => {
          console.log(res);
        })
        .finally(() => {
          onClose && onClose();
          setDrawer &&
            setDrawer((prevState) => ({
              ...prevState,
              shouldRefreshToggle: !prevState.shouldRefreshToggle,
            }));
          message.success("Ação realizada com sucesso.");
        })
        .catch((err) => {
          console.log(err);
          message.error("Ação falhou, consulte um administrador.");
        });
    } catch (error) {
      console.error("Error during fetch:", error);
      message.error("Ação falhou, consulte um administrador.");
    }
  }

  return (
    <Drawer
      title={
        data?.acoes_id
          ? "Editar Nome de Agroação"
          : "Adicionar Nome de Agroação"
      }
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      {/* bug da lib, deve re-renderizar Form para atualizar os valores */}
      {open && (
        <Form
          form={form}
          variant="filled"
          layout="vertical"
          onFinish={(values) => {
            if (data?.acoes_id) {
              handleUpdate(values);
            } else {
              handleInsert(values);
            }
          }}
        >
          <Form.Item
            label="ID Ação"
            name={"acoes_id"}
            rules={[
              {
                required: true,
                message: "Preencha o campo ID!",
              },
            ]}
          >
            <Input disabled={data?.acoes_id ? true : false} />
          </Form.Item>
          <Form.Item
            label="Nome"
            name={"acoes_nome"}
            rules={[
              {
                required: true,
                message: "Preencha o campo Nome!",
              },
            ]}
            tooltip={
              data?.acoes_id
                ? "A mudança de nome vale para todas as ações desse ID."
                : false
            }
          >
            <Input />
          </Form.Item>
          <Form.Item label="Ativa" name={"acoes_ativas"}>
            <Switch />
          </Form.Item>
          <Form.Item>
            <Button
              icon={<FaSave />}
              type="primary"
              size="large"
              htmlType="submit"
              style={{ marginTop: 10 }}
            >
              {data?.acoes_id ? "Salvar" : "Inserir"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Drawer>
  );
}
