import React, { useState } from "react";
import { Switch, Input, Drawer, Form, Button, Image } from "antd";
import TextArea from "antd/es/input/TextArea";
import { requestWithImage } from "../../../functions/request";
import { toast } from "react-toastify";
import { uniKey, validData } from "../../../functions/functionsUtility";
import { Content } from "antd/es/layout/layout";

function ModalAddSerie(props) {
  const [bannerImage, setBannerImage] = useState(null);
  const [capaImage, setCapaImage] = useState(null);

  const saveData = async () => {
    const result = await validData(props.editOpen, [
      "serie_nome",
      "serie_capitulos",
      "serie_ativa",
    ]);
    if (!result) {
      toast.error("Preencha todos os campos obrigatórios! Campo: Nome");
      return false;
    }
    const formData = new FormData();
    let dataAux = {
      serie_ativa: 0,
    };
    for (const key in props?.editOpen) {
      if (props?.editOpen[key] !== null && props?.editOpen[key] !== undefined) {
        dataAux[key] = props?.editOpen[key];
      }
    }
    dataAux.index = uniKey();
    const newData = JSON.stringify(dataAux);
    formData.append("newData", newData);
    formData.append("serie_banner", bannerImage);
    formData.append("serie_capa", capaImage);
    const response = await requestWithImage("/series/insertSeries", {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        toast.success("Série Salva com Sucesso!");
        props?.onRequestClose();
        props?.setEditOpen({});
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Erro ao Salvar Série!");
      });
  };

  console.log(props);
  return (
    <>
      <Drawer
        title={
          props?.editOpen?.serie_id ? "Editar Serie" : "Adicionar Nova Serie"
        }
        open={props?.isAddOpen}
        onClose={props?.onRequestClose}
        extra={
          <Button
            type="primary"
            onClick={(el) => {
              if (props?.editOpen?.serie_id) {
                props.changeSeries(props.editOpen, bannerImage, capaImage);
              } else {
                saveData();
              }
            }}
          >
            Salvar
          </Button>
        }
      >
        <Form layout="vertical">
          <Form.Item label={"Título"}>
            <Input
              value={props?.editOpen?.serie_nome}
              onChange={(e) =>
                props?.setEditOpen({
                  ...props?.editOpen,
                  serie_nome: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label={"Descrição"}>
            <TextArea
              value={props?.editOpen?.serie_desc}
              onChange={(e) =>
                props?.setEditOpen({
                  ...props?.editOpen,
                  serie_desc: e.target.value,
                })
              }
            />
          </Form.Item>
          <Form.Item label={"Capa"}>
            <Content style={{ display: "flex", gap: 5 }}>
              {props?.editOpen?.serie_capa && (
                <Image
                  src={`https://ruralbusiness.com.br/series/series/${props?.editOpen?.serie_capa}`}
                  style={{ aspectRatio: "2 / 3", borderRadius: 8 }}
                  width={100}
                />
              )}
              <Input
                onChange={(e) => {
                  setCapaImage(e.target.files[0]);
                }}
                type="file"
                accept="image/*"
              />
            </Content>
          </Form.Item>
          <Form.Item label={"Banner"}>
            <Content style={{ display: "flex", gap: 5 }}>
              {props?.editOpen?.serie_banner && (
                <Image
                  src={`https://ruralbusiness.com.br/series/series/${props?.editOpen?.serie_banner}`}
                  style={{ aspectRatio: "2 / 3", borderRadius: 8 }}
                  width={100}
                />
              )}
              <Input
                onChange={(e) => setBannerImage(e.target.files[0])}
                type="file"
                accept="image/*"
              />
            </Content>
          </Form.Item>
          <Form.Item label={"Ativo"}>
            <Switch
              onChange={(e) =>
                props?.setEditOpen({ ...props?.editOpen, serie_ativa: e })
              }
              checked={props?.editOpen?.serie_ativa}
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
}

export default ModalAddSerie;
