import React, { useState, useEffect } from "react";
import { getDataWithEndpoint } from "../../../functions/request";
import {
  formatDateHourToBR,
  updateData,
} from "../../../functions/functionsUtility";
import { DescriptionMinMax } from "../../../layout";

const cortesType = [
  { label: "Injetado Pa", value: "injetado_diant" },
  { label: "Manteado PA", value: "injetado_pa" },
  { label: "Injetado Diant.", value: "manteado_diant" },
  { label: "Manteado Diant.", value: "manteado_pa" },
];

export default function InterboisCharque() {
  const [cotacoesData, setCotacoesData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getDataWithEndpoint("boi_charque", "getBoiCharque")
      .then((data) => {
        setCotacoesData(data);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setHasError(err?.message ?? "Ocorreu um erro.");
        console.log(err);
      });
  }, []);

  return (
    <>
      <DescriptionMinMax
        title={"Interbois - Charque"}
        onClickButton={async () => {
          updateData({
            ...cotacoesData,
            endpoint: "updateBoiCharque",
            path: "boi_charque",
          });
        }}
        buttonText={"Atualizar"}
        data={cortesType}
        defaultValueFromApi
        date={formatDateHourToBR(cotacoesData.boi_data)}
        state={cotacoesData}
        setState={setCotacoesData}
        isLoading={isLoading}
        hasError={hasError}
        hasValue={false}
      />
    </>
  );
}
