import { Button, Drawer, Form, Image, Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import {
  editoriaOpcoes,
  ordemOpcoes,
  paisOpcoes,
} from "../../../constants/constants";
import Cookies from "js-cookie";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";
import { FaSave } from "react-icons/fa";

export default function DrawerImages({ open, onClose, data, setDrawer }) {
  const [form] = useForm();
  const [formData, setFormData] = useState({});
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      editoria: data?.img_editoria,
      pais: data?.img_pais,
      nome: data?.img_nome,
      comentario: data?.img_comentario,
      ordem: data?.img_ordem,
    });
    setFormData(data);
    setFileList([]);
  }, [data, open]);

  async function handleUpload(values) {
    const fields = new FormData();
    const fileData = fileList[0];
    const data = {
      img_codigo: formData?.img_codigo,
      img_pais: values?.pais,
      img_ordem: values?.ordem,
      img_nome: values?.nome,
      img_comentario: values?.comentario,
      img_arquivo: formData?.img_arquivo,
    };

    fields.append("newData", JSON.stringify(data));
    fields.append("fileData", fileData);

    const token = Cookies.get("x-access-token");
    await fetch(process.env.REACT_APP_API_RB + "/imagem/updateImagem", {
      headers: {
        "x-access-token": token,
      },
      method: "PUT",
      body: fields,
    })
      .then((response) => {
        console.log("Server response:", response);
        message.success("Upload realizado com sucesso. Recarregue a página.");
      })
      .catch((error) => {
        console.error("Error during fetch:", error);
        message.error("Upload falhou, consulte um administrador.");
      });
  }

  async function handleInsert(values) {
    const fields = new FormData();
    const fileData = fileList[0];
    const data = {
      img_pais: values?.pais,
      img_ordem: values?.ordem,
      img_nome: values?.nome,
      img_comentario: values?.comentario,
      img_arquivo:
        values?.editoria +
        fileData?.uid +
        "." +
        fileData?.name?.toString().split(".")[1],
    };

    fields.append("newData", JSON.stringify(data));
    fields.append("fileData", fileData);
    console.log(JSON.stringify(data));

    const token = Cookies.get("x-access-token");

    try {
      const response = await fetch(
        process.env.REACT_APP_API_RB + "/imagem/insertImagem",
        {
          headers: {
            "x-access-token": token,
          },
          method: "POST",
          body: fields,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Server response:", responseData);
      onClose && onClose();
      setDrawer &&
        setDrawer((prevState) => ({
          ...prevState,
          shouldRefreshToggle: !prevState.shouldRefreshToggle,
        }));
      message.success("Upload realizado com sucesso.");
    } catch (error) {
      console.error("Error during fetch:", error);
      message.error("Upload falhou, consulte um administrador.");
    }
  }

  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  return (
    <Drawer
      title={formData?.img_arquivo ? "Editar Imagem" : "Adicionar Nova Imagem"}
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      {/* bug da lib, deve re-renderizar Form para atualizar os valores */}
      {open && (
        <Form
          form={form}
          variant="filled"
          layout="vertical"
          onFinish={(values) => {
            if (formData?.img_arquivo) {
              handleUpload(values);
            } else {
              handleInsert(values);
            }
          }}
        >
          {!formData?.img_arquivo && (
            <Form.Item
              label="Editoria"
              name="editoria"
              hasFeedback
              rules={[{ required: true, message: "Escolha uma Editoria" }]}
            >
              <Select
                allowClear
                placeholder="Selecione uma Editoria"
                options={editoriaOpcoes}
              />
            </Form.Item>
          )}
          <Form.Item label="País" name="pais">
            <Select
              allowClear
              optionFilterProp="children"
              placeholder="Selecione um País"
              options={paisOpcoes}
            />
          </Form.Item>
          <Form.Item
            label="Legenda"
            name={"nome"}
            rules={[
              {
                required: true,
                message: "Preencha o campo Legenda!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Comentário"
            name={"comentario"}
            rules={[
              { required: true, message: "Preencha o campo Comentário!" },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            label="Ordem"
            name={"ordem"}
            tooltip="Somente preencher para editoria: Clima"
          >
            <Select
              allowClear
              options={ordemOpcoes}
              placeholder="Selecione uma ordem"
            />
          </Form.Item>
          <Form.Item
            name={"imagem"}
            rules={[
              {
                required: formData?.img_arquivo ? false : true,
                message: "Faça upload de uma imagem!",
              },
            ]}
          >
            <Dragger
              height={!formData?.img_arquivo && "200px"}
              listType="picture"
              maxCount={1}
              {...uploadProps}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Arraste e Solte ou Selecione um Arquivo{" "}
                {formData?.img_arquivo
                  ? "para Substituir a Imagem Atual"
                  : "para a Área de Upload"}
              </p>
              <p className="ant-upload-hint">tamanho máximo da imagem de 5mb</p>
              {formData?.img_arquivo && (
                <Image
                  height={"50%"}
                  preview={false}
                  src={`${process.env.REACT_APP_BANCO_IMG_URL}${formData.img_arquivo}`}
                />
              )}
            </Dragger>
          </Form.Item>
          <Form.Item>
            <Button
              icon={<FaSave />}
              type="primary"
              htmlType="submit"
              style={{ marginTop: 10 }}
            >
              {formData ? "Salvar" : "Inserir"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </Drawer>
  );
}
