import React, { useState, useEffect } from "react";
import { getDataWithEndpoint } from "../../../functions/request";
import {
  formatDateHourToBR,
  updateData,
} from "../../../functions/functionsUtility";
import { DescriptionMinMax } from "../../../layout";

const cortesType = [
  { label: "Acém", value: "acem" },
  { label: "Beicinho", value: "beico" },
  { label: "Cupim", value: "cupim" },
  { label: "Fraldão", value: "fraldao" },
  { label: "Fraldinha", value: "fraldinha" },
  { label: "Lombinho", value: "lombinho" },
  { label: "Medula", value: "medula" },
  { label: "Nervo", value: "nervo" },
  { label: "Paleta c/ músculo", value: "paleta_cmusculo" },
  { label: "Paleta s/ músculo", value: "paleta_smusculo" },
  { label: "Peito", value: "peito" },
  { label: "Recorte", value: "recorte" },
  { label: "Tripa", value: "tripa" },
  { label: "Veia", value: "veia" },
  { label: "Vergalho", value: "vergalho" },
];

export default function InterboisCortes() {
  const [cotacoesData, setCotacoesData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getDataWithEndpoint("boi_cortes", "getBoiCortes")
      .then((data) => {
        setCotacoesData(data);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setHasError(err?.message ?? "Ocorreu um erro.");
        console.log(err);
      });
  }, []);

  return (
    <>
      <DescriptionMinMax
        title={"Interbois - Cortes"}
        onClickButton={async () => {
          updateData({
            ...cotacoesData,
            endpoint: "updateBoiCortes",
            path: "boi_cortes",
          });
        }}
        buttonText={"Atualizar"}
        data={cortesType}
        defaultValueFromApi
        date={formatDateHourToBR(cotacoesData.boi_data)}
        state={cotacoesData}
        setState={setCotacoesData}
        isLoading={isLoading}
        hasError={hasError}
        hasValue={false}
      />
    </>
  );
}
