import React, { useState, useEffect } from "react";
import { getDataWithEndpoint } from "../../../functions/request";
import { DescriptionMinMax } from "../../../layout";
import {
  formatDateHourToBR,
  updateData,
} from "../../../functions/functionsUtility";

const cortesType = [
  { label: "Frango Resfriado", value: "fran_resf" },
  { label: "Frango Frango Congelado", value: "fran_cong" },
  { label: "Carcaca Congelada", value: "carc_cong" },
  { label: "Asa", value: "asa" },
  { label: "Coração", value: "coracao" },
  { label: "Coxa", value: "coxa" },
  { label: "Coxa e Sobrecoxa", value: "coxa_sobcoxa" },
  { label: "Dorso", value: "dorso" },
  { label: "Figado", value: "figado" },
  { label: "File de Peito", value: "file_peito" },
  { label: "Moela", value: "moela" },
  { label: "Peito", value: "peito" },
  { label: "CMS - 25%", value: "cms" },
];

export default function InterfrangoCortes() {
  const [cotacoesData, setCotacoesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getDataWithEndpoint("ave_cortes", "getAveCortes")
      .then((data) => {
        setCotacoesData(data);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setHasError(err?.message ?? "Ocorreu um erro.");
        console.log(err);
      });
  }, []);

  return (
    <>
      <DescriptionMinMax
        title={"Interfrango - Cortes"}
        onClickButton={async () => {
          updateData({
            ...cotacoesData,
            endpoint: "updateAveCortes",
            path: "ave_cortes",
          });
        }}
        buttonText={"Atualizar"}
        data={cortesType}
        defaultValueFromApi
        date={formatDateHourToBR(cotacoesData.ave_data)}
        state={cotacoesData}
        setState={setCotacoesData}
        isLoading={isLoading}
        hasError={hasError}
        hasValue={false}
      />
    </>
  );
}
