import { Layout } from "antd";
import React, { useState } from "react";
import SplitPane from "split-pane-react";
import "split-pane-react/esm/themes/default.css";

export function ConsultRoot({ style, panelSizes = ["30%", "auto"], children }) {
  const [sizes, setSizes] = useState(panelSizes);
  return (
    <Layout
      style={{
        display: "flex",
        flexDirection: "row",
        zIndex: 10,
        backgroundColor: "white",
      }}
    >
      <SplitPane split="vertical" sizes={sizes} onChange={setSizes}>
        {children}
      </SplitPane>
    </Layout>
  );
}
