import React from "react";
import { doRequest, token } from "../../functions/request";
import { Tag, Typography, message } from "antd";
import { Content } from "antd/es/layout/layout";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export default function FileUpload(props) {
  const handlePondFile = async (error, file) => {
    const fields = new FormData();
    fields.append("newData", JSON.stringify({ vid_arquivo: file.filename }));

    doRequest("/video_analise/updateVideoAnalise/" + props.value.vid_id, {
      method: "PUT",
      body: fields,
    })
      .then((res) => {
        console.log(res);
        message.success("Video Salvo com Sucesso!");
        props.setToggleReload((prevState) => !prevState);
      })
      .catch((err) => {
        console.log(err);
        props.setToggleReload((prevState) => !prevState);
        message.error("Erro ao Salvar Video! Contate um Administrador");
      });
  };

  return (
    <Content style={{ display: "flex", flexDirection: "column", gap: 15 }}>
      {props.value.vid_arquivo && (
        <Content
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 5,
            textAlign: "center",
          }}
        >
          <Tag color="gold" style={{ textAlign: "center", width: "100%" }}>
            Contém Video
          </Tag>
          <Typography.Text>
            Colocar outro arquivo aqui, irá substituir o video atual.
          </Typography.Text>
        </Content>
      )}
      <FilePond
        style={{ height: "50% !important" }}
        onprocessfile={handlePondFile}
        onupdatefiles={props.setAddVideo}
        server={{
          url: process.env.REACT_APP_API_RB + "/video_analise",
          process: {
            url: "/insertVideoLocal",
            method: "POST",
            withCredentials: false,
            headers: {
              "x-access-token": token,
            },
          },
        }}
        allowMultiple={false}
        maxFiles={1}
        name="file"
        labelIdle='<span class="filepond--label-action">Arraste e Solte seu arquivo de Video ou Selecione</span>'
      />
    </Content>
  );
}
