import Cookies from "js-cookie";
export const token = Cookies.get("x-access-token"); // Altere para o nome do cookie que você definiu anteriormente.

export async function request(url, options) {
  try {
    return fetch(process.env.REACT_APP_API_RB + url, {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token,
      },
      ...options,
    })
      .then(checkStatus)
      .then(parseJSON)
      .catch((err) => {
        throw new Error(err);
      });
  } catch (err) {
    throw new Error(err);
  }
}

export async function doRequest(
  url,
  options,
  headers = {
    "x-access-token": token,
  }
) {
  try {
    return fetch(process.env.REACT_APP_API_RB + url, {
      headers: headers,
      ...options,
    })
      .then(checkStatus)
      .then(parseJSON)
      .catch((err) => {
        throw new Error(err);
      });
  } catch (err) {
    throw new Error(err);
  }
}

export async function requestWithImage(url, options) {
  const token = Cookies.get("x-access-token"); // Altere para o nome do cookie que você definiu anteriormente.

  if (options.body instanceof FormData) {
    console.log("FormData content:");
    for (let pair of options.body.entries()) {
      console.log(pair[0] + ": " + pair[1]);
    }
  } else {
    console.log("Non-FormData content:", options.body);
  }
  return fetch(process.env.REACT_APP_API_RB + url, {
    headers: {
      "x-access-token": token,
    },
    ...options,
  })
    .then(checkStatus)
    .then(parseJSON)
    .catch((err) => ({ err }));
}
// Funções auxiliares que você pode já ter definido (assumo que você tenha essas funções):
function checkStatus(response) {
  if (response.ok) {
    return response;
  } else {
    throw new Error(response.statusText);
  }
}

function parseJSON(response) {
  return response.json();
}

export async function getData(path, get, insert) {
  try {
    const currentPathWithQuery = `/${path}/${get}`;
    const response = await request(currentPathWithQuery, {
      method: "GET",
    });

    const data = { ...response, insert: insert, path: path };

    Object.keys(data).forEach((el, index) => {
      if (typeof data[el] === "number") {
        data[el] = data[el].toFixed(2);
        data[el] = data[el].replace(".", ",");
      }
    });

    console.log(data);
    return data;
  } catch (err) {
    throw new Error(err);
  }
}

export async function getDataWithEndpoint(path, get) {
  try {
    const currentPathWithQuery = `/${path}/${get}`;
    console.log(currentPathWithQuery);
    const response = await request(currentPathWithQuery, {
      method: "GET",
    });

    const data = { ...response };

    Object.keys(data).forEach((el, index) => {
      if (typeof data[el] === "number" && el !== "cot_id") {
        data[el] = data[el].toFixed(2);
        data[el] = data[el].replace(".", ",");
      } else if (typeof data[el] === "object") {
        // somente para bois por estado
        data[el][0].boi_valor = data[el][0].boi_valor.toFixed(2);
        data[el][0].boi_valor = data[el][0].boi_valor.replace(".", ",");
      }
    });

    console.log(data);
    return data;
  } catch (err) {
    throw new Error(err);
  }
}

export async function getDolarbyDate(date) {
  const arr = { data: { dataInicio: date } };

  const response = await request("/dolar/getDolarbyData", {
    method: "POST",
    body: JSON.stringify(arr),
  });
  return response[0].dol_valor;
}

export async function getCommodityByOrder(order) {
  const response = await request(`/correcoes/getCommodityByOrdem/${order}`, {
    method: "GET",
  }).catch((error) => {
    console.error("Erro na requisição:", error);
  });
  return response[0];
}
