import dayjs from "dayjs";

export const rangePresets = [
  {
    label: "Hoje",
    value: [dayjs().add(0, "d"), dayjs()],
  },
  {
    label: "Últimos 7 Dias",
    value: [dayjs().add(-7, "d"), dayjs()],
  },
  {
    label: "Últimos 14 Dias",
    value: [dayjs().add(-14, "d"), dayjs()],
  },
  {
    label: "Últimos 30 Dias",
    value: [dayjs().add(-30, "d"), dayjs()],
  },
  {
    label: "Últimos 90 Dias",
    value: [dayjs().add(-90, "d"), dayjs()],
  },
  {
    label: "Últimos 180 Dias",
    value: [dayjs().add(-180, "d"), dayjs()],
  },
  {
    label: "Últimos 365 Dias",
    value: [dayjs().add(-365, "d"), dayjs()],
  },
];

export const sojaStatesOptions = [
  { value: "BA", label: "Bahia" },
  { value: "GO", label: "Goiás" },
  { value: "MA", label: "Maranhão" },
  { value: "MT", label: "Mato Grosso" },
  { value: "MS", label: "Mato Grosso do Sul" },
  { value: "MG", label: "Minas Gerais" },
  { value: "PR", label: "Paraná" },
  { value: "RJ", label: "Rio de Janeiro" },
  { value: "RS", label: "Rio Grande do Sul" },
  { value: "SC", label: "Santa Catarina" },
  { value: "SP", label: "São Paulo" },
  { value: "RG", label: "Porto do Rio Grande" },
  { value: "PG", label: "Porto de Paranaguá" },
  { value: "ST", label: "Porto de Santos" },
];

export const videosMercados = [
  { label: "Agroações", value: 11 },
  { label: "Live Money", value: 12 },
  { label: "Boi", value: 1 },
  { label: "Soja", value: 2 },
  { label: "Milho", value: 3 },
  { label: "Café", value: 4 },
  { label: "Trigo", value: 5 },
  { label: "Algodão", value: 6 },
  { label: "Frango", value: 7 },
  { label: "Leite", value: 8 },
  { label: "Suínos", value: 9 },
  { label: "Outros", value: 10 },
];

export const noticiasOpcoes = [
  { value: false, label: "Todos" },
  { value: 1, label: "Boi" },
  { value: 2, label: "Frango" },
  { value: 3, label: "Grãos" },
  { value: 4, label: "Leite" },
  { value: 5, label: "Suinos" },
  { value: 8, label: "Agroações" },
  { value: 9, label: "Bolsas e Opções" },
];

export const analisesOpcoes = [
  { value: false, label: "Todos" },
  { value: 1, label: "Boi" },
  { value: 3, label: "Frango" },
  { value: 4, label: "Leite" },
  { value: 5, label: "Suinos" },
  { value: 10, label: "Trigo" },
  { value: 11, label: "Algodao" },
  { value: 21, label: "Soja" },
  { value: 22, label: "Milho" },
  { value: 23, label: "Cafe" },
  { value: 24, label: "Cana-de-acucar" },
];

export const consultarOpcoes = [
  { text: "Busca Completa", value: "busca_completa" },
  { text: "Por Título", value: "busca_titulo" },
  { text: "Por Texto", value: "busca_texto" },
];

export const editoriaOpcoes = [
  { value: "fr01", label: "Interfrango" },
  { value: "bo01", label: "Interbois" },
  { value: "gr01", label: "Intergrãos" },
  { value: "su01", label: "Intersuíno" },
  { value: "le01", label: "Interleite" },
  { value: "per", label: "Personalidades" },
  { value: "gf01", label: "Gráficos" },
  { value: "cl01", label: "Clima" },
  { value: "etc", label: "Outros" },
];

export const paisOpcoes = [
  { value: "AR", label: "Argentina" },
  { value: "BR", label: "Brasil" },
  { value: "EUA", label: "Estados Unidos" },
  { value: "AMS", label: "América do Sul" },
];

export const ordemOpcoes = [
  { value: "1", label: "1º" },
  { value: "2", label: "2º" },
  { value: "3", label: "3º" },
  { value: "4", label: "4º" },
];

//Meses
export const monthsFields = [
  { value: "01", label: "Janeiro" },
  { value: "02", label: "Fevereiro" },
  { value: "03", label: "Março" },
  { value: "04", label: "Abril" },
  { value: "05", label: "Maio" },
  { value: "06", label: "Junho" },
  { value: "07", label: "Julho" },
  { value: "08", label: "Agosto" },
  { value: "09", label: "Setembro" },
  { value: "10", label: "Outubro" },
  { value: "11", label: "Novembro" },
  { value: "12", label: "Dezembro" },
];

//cotações
export const indicesFields = [
  { label: "Dólar Comercial:", value: "ind_dolarcom" },
  { label: "Dólar Paralelo:", value: "ind_dolarpar" },
  { label: "Bovespa:", value: "ind_bovespa" },
  { label: "Ouro:", value: "ind_ouro" },
  { label: "Poupança:", value: "ind_poupanca" },
  { label: "Salário Mínimo:", value: "ind_salminimo" },
  { label: "UFIR:", value: "ind_ufirano" },
  { label: "TJLP:", value: "ind_tjlpano" },
  {
    label: "TR:",
    value: "ind_trdia",
    span: 2,
  },
  {
    label: "Data TR:",
    value: "ind_trdata",
    span: 1,
  },
  {
    label: "Selic:",
    value: "ind_selic",
    span: 2,
  },
  {
    label: "Data Selic:",
    value: "ind_selicdata",
    span: 1,
  },
  { label: "Empréstimo Pessoal:", value: "ind_emprpessoal" },
  { label: "Cheque Especial:", value: "ind_cheqespec" },
  { label: "NPR:", value: "ind_npr" },
  { label: "Dólar Ptax:", value: "ind_dolarptax" },
];

export const alcoolOptions = [
  { value: "alcool_fechamento", label: "Fechamento" },
  { value: "alcool_minimo", label: "Mínima" },
  { value: "alcool_maximo", label: "Máxima" },
  { value: "alcool_volume", label: "Volume" },
  { value: "alcool_aberto", label: "Posição em Aberto" },
];

export const acucarOptions = [
  { value: "acucar_fechamento", label: "Fechamento" },
  { value: "acucar_minimo", label: "Mínima" },
  { value: "acucar_maximo", label: "Máxima" },
  { value: "acucar_volume", label: "Volume" },
  { value: "acucar_aberto", label: "Posição em Aberto" },
];

export const cafeOptions = [
  { value: "caf_fechamento", label: "Fechamento" },
  { value: "caf_minimo", label: "Mínima" },
  { value: "caf_maximo", label: "Máxima" },
  { value: "caf_volume", label: "Volume" },
  { value: "caf_aberto", label: "Posição em Aberto" },
];

export const milhoFields = [
  { value: "mil_compr_mn", label: "Mínima:", cif: "min_cif" },
  { value: "mil_compr_mx", label: "Máxima:", cif: "max_cif" },
  { value: "mil_vend_mn", label: "Preço Mínimo ao Vend" },
  { value: "mil_vend_mx", label: "Preço Máximo aoVend" },
];

export const sojaFields = [
  { value: "soj_comp_min", label: "Preço Min. ao Compr:", cif: "min_cif" },
  { value: "soj_comp", label: "Preço Máx. ao Comp:", cif: "max_cif" },
  { value: "soj_vend_min", label: "Preço Min. ao Vend:" },
  { value: "soj_vend", label: "Preço Máx. ao Vend:" },
];

export const leiteCotacaoFields = [
  { label: "Mínimo", value: "cot_minimo" },
  { label: "Máximo", value: "cot_maximo" },
];

export const estados = [
  { value: "BA", label: "Bahia" },
  { value: "GO", label: "Goiás" },
  { value: "MT", label: "Mato Grosso" },
  { value: "MS", label: "Mato Grosso do Sul" },
  { value: "MG", label: "Minas Gerais" },
  { value: "PA", label: "Pará" },
  { value: "PR", label: "Paraná" },
  { value: "RJ", label: "Rio de Janeiro" },
  { value: "RS", label: "Rio Grande do Sul" },
  { value: "SC", label: "Santa Catarina" },
  { value: "SP", label: "São Paulo" },
  { value: "TO", label: "Tocantins" },
];

export const cortesType = [
  {
    name: "Barriga c/ Costela (cong.):",
    value: "bar_ccostela_cong",
    salg: "bar_ccostela_sal",
  },
  {
    name: "Barriga s/ Costela (cong.):",
    value: "bar_scostela_cong",
    salg: "bar_scostela_sal",
  },
  { name: "Copa (cong.):", value: "copa_cong", salg: "copa_sal" },
  { name: "Couro (cong.):", value: "couro_cong", salg: "couro_sal" },
  { name: "Costela (cong.):", value: "cost_cong", salg: "cost_sal" },
  { name: "Filé (cong.):", value: "filet_cong", salg: "filet_sal" },
  { name: "Língua (cong.):", value: "lingua_cong", salg: "lingua_sal" },
  { name: "Orelha (cong.):", value: "orelha_cong", salg: "orelha_sal" },
  { name: "Pé (cong.):", value: "pe_cong", salg: "pe_sal" },
  { name: "Rabo (cong.):", value: "rabo_cong", salg: "rabo_sal" },
  {
    name: "Retalho Gordo (cong.):",
    value: "ret_gordo_cong",
    salg: "ret_gordo_sal",
  },
  {
    name: "Retalho Magro (cong.):",
    value: "ret_magro_cong",
    salg: "ret_magro_sal",
  },
  {
    name: "Toucinho de Lombo (cong.):",
    value: "touc_lombo_cong",
    salg: "touc_lombo_sal",
  },
  {
    name: "Toucinho s/ Couro (cong.):",
    value: "touc_scouro_cong",
    salg: "touc_scouro_sal",
  },
];

export const cortesEspType = [
  { name: "Carc. Esp. Atac:", value: "carc_esp_ata" },
  { name: "Carc. Esp. Dist:", value: "carc_esp_dist" },
  { name: "Carret. Cong.:", value: "carret_cong" },
  { name: "Lombo Cong. Extr:", value: "lombo_cong" },
  { name: "Paleta s/ Osso:", value: "paleta_sosso" },
  { name: "Paleta s/ Couro:", value: "paleta_cosso" },
  { name: "Pernil Esp.:", value: "pernil_esp" },
  { name: "Pernil Ind. Cosso:", value: "pernil_ind_cosso" },
];

export const cotacoesSuinos = [
  { value: "sui_minimo", label: "Mínimo" },
  { value: "sui_maximo", label: "Máximo" },
];

export const BoiCarneIndOptions = [
  { label: "PA Boi c/ Osso Resfriado", value: "pa_boi_cosso" },
  { label: "PA Vaca c/ Osso Resfriado", value: "pa_vaca_cosso" },
  { label: "PA s/ Osso", value: "pa_sosso" },
  { label: "Dianteiro s/ Osso", value: "diant_sosso" },
  { label: "Diant. Boi c/ Osso Resfriado", value: "diant_boi_cosso" },
  { label: "Diant. Vaca c/ Osso Resfriado", value: "diant_vaca_cosso" },
  { label: "Carne Industrial", value: "carne_ind" },
  { label: "Carne Industrial Especial", value: "carne_ind_esp" },
  { label: "Carne Conserva", value: "carne_cons" },
  { label: "Carne Sangria", value: "carne_sang" },
  { label: "Músculo Dianteiro", value: "musc_diant" },
  { label: "Osso do Patinho", value: "osso_pat" },
];

export const BoiCarneNaturaOptions = [
  { value: "tras_boi", label: "Traseiro Boi" },
  { value: "diant_boi", label: "Dianteiro Boi" },
  { value: "tras_vaca", label: "Traseiro Vaca" },
  { value: "diant_vaca", label: "Dianteiro Vaca" },
  { value: "tras_boi_av", label: "Tras. Boi Avulso" },
  { value: "tras_vaca_av", label: "Tras. Vaca Avulso" },
  { value: "boi_casado", label: "Boi Casado" },
  { value: "vaca_casado", label: "Vaca Casado" },
  { value: "pt_agu_boi", label: "PA Boi" },
  { value: "pt_agu_vaca", label: "PA Vaca" },
  { value: "pt_agu_boi_esp", label: "PA Boi Especial" },
];

export const boiCharqueFields = [
  { name: "Injetado Pa", value: "injetado_diant" },
  { name: "Manteado PA", value: "injetado_pa" },
  { name: "Injetado Diant.", value: "manteado_diant" },
  { name: "Manteado Diant.", value: "manteado_pa" },
];

export const bioCortesFields = [
  { name: "Acém", value: "acem" },
  { name: "Beicinho", value: "beico" },
  { name: "Cupim", value: "cupim" },
  { name: "Fraldão", value: "fraldao" },
  { name: "Fraldinha", value: "fraldinha" },
  { name: "Lombinho", value: "lombinho" },
  { name: "Medula", value: "medula" },
  { name: "Nervo", value: "nervo" },
  { name: "Paleta c/ músculo", value: "paleta_cmusculo" },
  { name: "Paleta s/ músculo", value: "paleta_smusculo" },
  { name: "Peito", value: "peito" },
  { name: "Recorte", value: "recorte" },
  { name: "Tripa", value: "tripa" },
  { name: "Veia", value: "veia" },
  { name: "Vergalho", value: "vergalho" },
];

export const boiCortesEspFields = [
  { name: "Alcatra Completa", value: "alcatra_comp" },
  { name: "Alcatra c/Maminha", value: "alcatra_maminha" },
  { name: "Alcatra Miolo", value: "alcatra_miolo" },
  { name: "Capa de Filet", value: "capa_file" },
  { name: "Contra Filet", value: "contra_file" },
  { name: "Coxão Duro", value: "coxao_duro" },
  { name: "Coxão Mole", value: "coxao_mole" },
  { name: "Cupim A", value: "cupim_a" },
  { name: "Cupim B", value: "cupim_b" },
  { name: "Filet Mignon c/ Cordão", value: "file_ccordao" },
  { name: "Filet Mignon s/ Cordão", value: "file_scordao" },
  { name: "Lagarto", value: "lagarto" },
  { name: "Maminha", value: "maminha" },
  { name: "Músculo", value: "musculo" },
  { name: "Patinho", value: "patinho" },
  { name: "Picanha A", value: "picanha_a" },
  { name: "Picanha B", value: "picanha_b" },
];

export const boiCouroStates = [
  { value: "SP", label: "São Paulo" },
  { value: "GO", label: "Goiás" },
  { value: "MT", label: "Mato Grosso" },
  { value: "MS", label: "Mato Grosso do Sul" },
  { value: "MG", label: "Minas Gerais" },
  { value: "PR", label: "Paraná" },
  { value: "RS", label: "Rio Grande do Sul" },
];

export const boiCouroFields = [
  { value: "verde_av", label: "Verde (A/V)" },
  { value: "verde_30", label: "Verde (30d)" },
  { value: "salg", label: "Salgado" },
  { value: "salg_prazo", label: "Prazo Salgado" },
];

export const boiDiversosFields = [
  { name: "Bilis Conc-Kg", value: "bilis_conc", prazo: "p_bilis_conc" },
  { name: "Bilis Líq-Litro", value: "bilis_liq", prazo: "p_bilis_liq" },
  { name: "Calc Biliar FOB", value: "calc_biliar", prazo: "p_calc_biliar" },
  { name: "Casco/Chifre-Kg", value: "casco_chifre", prazo: "p_casco_chifre" },
  { name: "Crina-Kg", value: "crina", prazo: "p_crina" },
  { name: "Pelo/Orelha-Kg", value: "pelo_orelha", prazo: "p_pelo_orelha" },
];

export const boiLinersFields = [
  { name: "Novilhos Pesados", value: "boi_nov_pes" },
  { name: "Novillitos 300/350 kg", value: "boi_novi_350" },
  { name: "Novillitos 391/430 kg", value: "boi_novi_430" },
  { name: "Vaquillonas 300/340 kg", value: "boi_vaq_340" },
  { name: "Vaquillonas 391/430 kg", value: "boi_vaq_430" },
  { name: "Terneiros:", value: "boi_tern" },
];

export const boiMiudosStates = [{ value: "SP", label: "São Paulo" }];

export const boiMiudosFields = [
  { value: "baco", label: "Baço" },
  { value: "bucho", label: "Bucho" },
  { value: "coracao", label: "Coração" },
  { value: "figado", label: "Fígado" },
  { value: "lingua", label: "Língua s/ Garganta" },
  { value: "miolo", label: "Miolo" },
  { value: "mocoto", label: "Mocotó" },
  { value: "pulmao", label: "Pulmão" },
  { value: "rabo", label: "Rabo" },
  { value: "rins", label: "Rins" },
  { value: "testiculos", label: "Testículos" },
];

export const boiParaguaiFields = [
  { name: "Boi", value: "cot_boi" },
  { name: "Vaca", value: "cot_vaca" },
  { name: "Garrote", value: "cot_garrote" },
];

export const boiCotacoesTopStates = [
  { value: "BA", label: "Bahia" },
  { value: "GO", label: "Goiás" },
  { value: "MA", label: "Maranhão" },
  { value: "MT", label: "Mato Grosso(Sul)" },
  { value: "MN", label: "Mato Grosso(Norte)" },
  { value: "MS", label: "Mato Grosso do Sul" },
  { value: "MG", label: "Minas Gerais" },
  { value: "TR", label: "Minas Gerais(Triangulo)" },
  { value: "PA", label: "Pará" },
  { value: "PR", label: "Paraná" },
  { value: "RJ", label: "Rio de Janeiro" },
  { value: "RO", label: "Rondônia" },
  { value: "RS", label: "Rio Grande do Sul (P. Alegre)" },
  { value: "RF", label: "Rio Grande do Sul (Fronteira)" },
  { value: "SC", label: "Santa Catarina" },
  { value: "SP", label: "São Paulo" },
  { value: "TO", label: "Tocantins" },
];

export const boiCotacoesTopAPrazoFields = [
  { value: "cot_boi", label: "Boi Gordo" },
  { value: "cot_vaca", label: "Vaca" },
  { value: "cot_bezerro", label: "Bezerro" },
  { value: "cot_magro", label: "Boi Magro" },
];

export const boiCotacoesTopAVistaFields = [
  { value: "cot_boi_av", label: "Boi Gordo" },
  { value: "cot_vaca_av", label: "Vaca" },
];

export const mercadoAtacadistaFields = [
  { label: "Dianteiro/Boi", value: "ata_diaboi" },
  { label: "Dianteiro/Vaca", value: "ata_diavac" },
  { label: "Dianteiro s/ Osso - Boi", value: "ata_diaossoboi" },
  { label: "Dianteiro s/ Osso - Vaca", value: "ata_diaossovac" },
  { label: "Traseiro de Boi", value: "ata_traboi" },
  { label: "Traseiro de Vaca", value: "ata_travac" },
  { label: "Traseiro s/ Osso - Boi", value: "ata_traossoboi" },
  { label: "Traseiro s/ Osso - Vaca", value: "ata_traossovac" },
  { label: "Ponta de Agulha - Boi", value: "ata_agulha" },
  { label: "Ponta de Agulha - Vaca", value: "ata_casado" },
  { label: "Equivalente Físico", value: "ata_equifis" },
];
