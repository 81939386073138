import React, { useState, useEffect } from "react";
import { getDataWithEndpoint } from "../../../functions/request";
import {
  formatDateHourToBR,
  updateData,
} from "../../../functions/functionsUtility";
import { DescriptionMinMax } from "../../../layout";

const cortesType = [
  { label: "Carc. Esp. Atac:", value: "carc_esp_ata" },
  { label: "Carc. Esp. Dist:", value: "carc_esp_dist" },
  { label: "Carret. Cong.:", value: "carret_cong" },
  { label: "Lombo Cong. Extr:", value: "lombo_cong" },
  { label: "Paleta s/ Osso:", value: "paleta_sosso" },
  { label: "Paleta s/ Couro:", value: "paleta_cosso" },
  { label: "Pernil Esp.:", value: "pernil_esp" },
  { label: "Pernil Ind. Cosso:", value: "pernil_ind_cosso" },
];

export default function IntersuinosCortesEspeciais() {
  const [cotacoesData, setCotacoesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getDataWithEndpoint("suino_corte_esp", "getSuinoCorteEsp")
      .then((data) => {
        setCotacoesData(data);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setHasError(err?.message ?? "Ocorreu um erro.");
        console.log(err);
      });
  }, []);

  return (
    <>
      <DescriptionMinMax
        title={"Intersuinos - Cortes Especiais"}
        onClickButton={async () => {
          updateData({
            ...cotacoesData,
            endpoint: "updateSuinoCorteEsp",
            path: "suino_corte_esp",
          });
        }}
        buttonText={"Atualizar"}
        data={cortesType}
        defaultValueFromApi
        date={formatDateHourToBR(cotacoesData.sui_data)}
        state={cotacoesData}
        setState={setCotacoesData}
        isLoading={isLoading}
        hasError={hasError}
        hasValue={false}
      />
    </>
  );
}
