import React, { useContext, useEffect, useState } from "react";
import { Button, Image, Layout, Menu } from "antd";
import { useNavigate, Link, useLocation } from "react-router-dom";
import logoImage from "../assets/logorb.png";
import Logo from "../assets/logorbwhite.png";
import { FiLogOut } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import { AuthContext } from "../context/AuthContext";
import Items from "../pages/allpages";
// itens menu
import { FileSearchOutlined } from "@ant-design/icons";
import { transformLabelNumber } from "../functions/functionsUtility";
import { PiGrainsLight } from "react-icons/pi";
import { LuMilk } from "react-icons/lu";
import { FaCottonBureau, FaPhotoVideo } from "react-icons/fa";
import { GiBull, GiCorn, GiWheat, GiChicken } from "react-icons/gi";
import {
  MdOutlineChangeCircle,
  MdAttachMoney,
  MdPermMedia,
} from "react-icons/md";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { FaChartColumn, FaMoneyBillTrendUp, FaOilWell } from "react-icons/fa6";
import {
  AiFillVideoCamera,
  AiOutlineTable,
  AiOutlinePicture,
} from "react-icons/ai";
import { BsNewspaper, BsPiggyBank, BsGraphUpArrow } from "react-icons/bs";
import { CiWheat } from "react-icons/ci";
import { IoIosCafe } from "react-icons/io";
/// itens menu

const { Header, Content, Sider } = Layout;

function getItem(visible, label, key, icon, children) {
  let response = '';
  if (visible) {
    response = {
      key,
      icon,
      children,
      label,
    };
  }
  return response;
}

export default function AppLayout({ children }) {
  const { isLogged, useHaveAccess, logout, accessLevel } = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(false);
  const [itemsTable, setItemsTable] = useState();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  console.log(pathname);
  const changeRoute = (data) => {
    navigate(data.key);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  async function getMenu() {
    const items = [
      getItem(accessLevel.usr_analise, "Análises", "comentario", <FileSearchOutlined />, [
        getItem(true,"Consultar Análises", "comentario"),
        getItem(accessLevel.usr_analise,"Inserir Nova Análise", "comentario/insertComentario"),
        getItem(accessLevel.usr_analise,
          "Interbois",
          "/comentario/getcomentariobysection?section=" +
            transformLabelNumber("boi"),
          <GiBull />
        ),
        getItem(accessLevel.usr_analise,"Intergrãos", "Intergrãos", <PiGrainsLight />, [
          getItem(accessLevel.usr_analise,
            "Soja",
            "/comentario/getcomentariobysection?section=" +
              transformLabelNumber("Soja")
          ),
          getItem(accessLevel.usr_analise,
            "Milho",
            "/comentario/getcomentariobysection?section=" +
              transformLabelNumber("Milho")
          ),
          getItem(accessLevel.usr_analise,
            "Café",
            "/comentario/getcomentariobysection?section=" +
              transformLabelNumber("Cafe")
          ),
          getItem(accessLevel.usr_analise,
            "Açúcar",
            "/comentario/getcomentariobysection?section=" +
              transformLabelNumber("Acucar")
          ),
          getItem(accessLevel.usr_analise,
            "Trigo",
            "/comentario/getcomentariobysection?section=" +
              transformLabelNumber("Trigo")
          ),
          getItem(accessLevel.usr_analise,
            "Algodão",
            "/comentario/getcomentariobysection?section=" +
              transformLabelNumber("Algodao")
          ),
        ]),
        getItem(accessLevel.usr_analise,
          "Interfrango",
          "/comentario/getcomentariobysection?section=" +
            transformLabelNumber("frango"),
          <GiChicken />
        ),
        getItem(accessLevel.usr_analise,
          "Intersuíno",
          "/comentario/getcomentariobysection?section=" +
            transformLabelNumber("suinos"),
          <BsPiggyBank />
        ),
        getItem(accessLevel.usr_analise,
          "Interleite",
          "/comentario/getcomentariobysection?section=" +
            transformLabelNumber("leite"),
          <LuMilk />
        ),
      ]),
      getItem(accessLevel.usr_noticias,"Notícias RTE", "Notícias RTE", <BsNewspaper />, [
        getItem(accessLevel.usr_noticias,"Consultar Notícias", "noticia/consultar_noticias"),
        getItem(accessLevel.usr_noticias,"Inserir Nova Notícia", "/noticia/inserir_noticias"),
      ]),
      getItem(accessLevel.usr_correcao,"Fechamento de Bolsas", "bolsa", <BsGraphUpArrow />, [
        getItem(accessLevel.usr_correcao,"Interbois", "Bolsa Interbois", <GiBull />, [
          getItem(accessLevel.usr_correcao,"Boi CME", "/interbois/boi_cme"),
          getItem(accessLevel.usr_correcao,"Mercado Futuro", "/interbois/boi_futuro"),
        ]),
        getItem(accessLevel.usr_correcao,"Intergrãos", "Bolsa Intergrãos", <PiGrainsLight />, [
          getItem(accessLevel.usr_correcao,"Café", "Bolsa Cafe", <IoIosCafe />, [
            getItem(accessLevel.usr_correcao,"Café - BM&F", "/intergraos/cafe_bmf"),
            getItem(accessLevel.usr_correcao,"Café - Nova York", "/intergraos/cafe_ny"),
            getItem(accessLevel.usr_correcao,"Café - Londres", "/intergraos/cafe_londres"),
          ]),
          getItem(accessLevel.usr_correcao,"Milho", "Bolsa Milho", <GiCorn />, [
            getItem(accessLevel.usr_correcao,"Milho - BMF", "/intergraos/milho_bmf"),
            getItem(accessLevel.usr_correcao,"Milho CCM - BMF", "/intergraos/milho_bmfccm"),
            getItem(accessLevel.usr_correcao,"Milho - Mercado Futuro", "/intergraos/milho_futuro"),
          ]),
          getItem(accessLevel.usr_correcao,"Soja", "Bolsa Soja", <CiWheat />, [
            getItem(accessLevel.usr_correcao,"Soja - Mercado Futuro", "/intergraos/soja_futuro"),
            getItem(accessLevel.usr_correcao,"Soja - Óleo", "/intergraos/soja_oleo"),
            getItem(accessLevel.usr_correcao,"Soja - Farelo", "/intergraos/soja_farelo"),
            getItem(accessLevel.usr_correcao,"Soja - BMF", "/intergraos/soja_bmf"),
            getItem(accessLevel.usr_correcao,"Soja - América do Sul", "/intergraos/soja_cbot"),
          ]),
          getItem(accessLevel.usr_correcao,"Trigo, Açucar e Gerais", "Bolsa Gerais", <GiWheat />, [
            getItem(accessLevel.usr_correcao,"Trigo - CME", "/intergraos/trigo_cme"),
            getItem(accessLevel.usr_correcao,"Açúcar - Nova Iorque", "/intergraos/acucar_ny"),
            getItem(accessLevel.usr_correcao,"Açúcar - Londres", "/intergraos/acucar_lond"),
            getItem(accessLevel.usr_correcao,"Açúcar - BM&F", "/intergraos/acucar_bmf"),
            getItem(accessLevel.usr_correcao,"Alcool Anidro - BM&F", "/intergraos/alcool_bmf"),
          ]),
        ]),
        getItem(accessLevel.usr_correcao,"Petróleo", "/petroleo/leite_cotacao", <FaOilWell />, [
          getItem(accessLevel.usr_correcao,"ICE Futures", "/petroleo/petroleo_ice"),
          getItem(accessLevel.usr_correcao,"NYMEX", "/petroleo/petroleo_ny"),
          getItem(accessLevel.usr_correcao,"WTI", "/petroleo/petroleo_wti"),
        ]),
      ]),
      getItem(accessLevel.usr_correcao,"Cotações", "cotacoes", <MdAttachMoney />, [
        getItem(accessLevel.usr_correcao,"Interbois", "Cotações Interbois", <GiBull />, [
          getItem(accessLevel.usr_correcao,"Cotações Top", "/interbois/cotacoestop"),
          getItem(accessLevel.usr_correcao,"Mercado Atacadista", "/interbois/boi_atacado"),
          getItem(accessLevel.usr_correcao,"Boi Liners", "/interbois/boi_liners"),
          getItem(accessLevel.usr_correcao,"Boi Paraguai", "/interbois/boi_paraguai"),
          getItem(accessLevel.usr_correcao,"Cotações por Estado", "/interbois/boi_est"),
          getItem(accessLevel.usr_correcao,"Carne Industrializada", "/interbois/boi_carne_ind"),
          getItem(accessLevel.usr_correcao,"Carne In Natura", "/interbois/boi_carne_natura"),
          getItem(accessLevel.usr_correcao,"Cortes Trad. e Esp.", "/interbois/boi_corte_trad_esp"),
          getItem(accessLevel.usr_correcao,"Cortes", "/interbois/boi_cortes"),
          getItem(accessLevel.usr_correcao,"Couro", "/interbois/boi_couro"),
          getItem(accessLevel.usr_correcao,"Diversos", "/interbois/boi_diversos"),
          getItem(accessLevel.usr_correcao,"Miúdos Congelados", "/interbois/boi_miudos"),
          getItem(accessLevel.usr_correcao,"Charque", "/interbois/boi_charque"),
        ]),
        getItem(accessLevel.usr_correcao,"Intergrãos", "Cotações Intergrãos", <PiGrainsLight />, [
          getItem(accessLevel.usr_correcao,"Café - Bica Corrida", "/intergraos/cafe_bica", <IoIosCafe />),
          getItem(accessLevel.usr_correcao,
            "Milho - Cotação - BM&F",
            "/intergraos/milho_cotacao",
            <GiCorn />
          ),
          getItem(accessLevel.usr_correcao,
            "Soja - Cotação por Estado",
            "/intergraos/soja_cotacao",
            <CiWheat />
          ),
          getItem(accessLevel.usr_correcao,"Trigo - Agranel", "/intergraos/trigo_agranel", <GiWheat />),
          getItem(accessLevel.usr_correcao,
            "Algodão - Cotação",
            "/intergraos/algodao_cotacao",
            <FaCottonBureau />
          ),
        ]),
        getItem(accessLevel.usr_correcao,"Interfrango", "Cotações Interfrango", <GiChicken />, [
          getItem(accessLevel.usr_correcao,"Cotações Top", "/interfrangos/fratop"),
          getItem(accessLevel.usr_correcao,"Cortes", "/interfrangos/cortes"),
        ]),
        getItem(accessLevel.usr_correcao,"Intersuíno", "Cotações Intersuíno", <BsPiggyBank />, [
          getItem(accessLevel.usr_correcao,"Cotações Top", "/intersuinos/cottop"),
          getItem(accessLevel.usr_correcao,"Cortes", "/intersuinos/cortes"),
          getItem(accessLevel.usr_correcao,"Cortes Especial", "/intersuinos/cortes_esp"),
        ]),
        getItem(accessLevel.usr_correcao,"Interleite", "/interleite/leite_cotacao", <LuMilk />),
    
        getItem(accessLevel.usr_correcao,"Dólar", "Dolar", <RiMoneyDollarCircleLine />, [
          getItem(accessLevel.usr_correcao,"Dólar", "/ferramentas/cotacoes_dolar"),
          getItem(accessLevel.usr_correcao,"Dólar Ptax", "/ferramentas/cotacoes_dolarptax"),
        ]),
        getItem(accessLevel.usr_correcao,
          "Índices Econômicos",
          "/ferramentas/indices",
          <FaMoneyBillTrendUp />
        ),
      ]),
    
      getItem(accessLevel.usr_noticias,"Tabelas", "Tabelas", <AiOutlineTable />, [
        getItem(accessLevel.usr_noticias,"Interbois", "Tabelas Interbois", <GiBull />, [
          getItem(accessLevel.usr_noticias,"Tabela Diária", "/interbois/tab_coment_diario"),
          getItem(accessLevel.usr_noticias,"Tabela Mensal", "/interbois/tab_coment_mensal"),
          getItem(accessLevel.usr_noticias,"Tabela Clima", "/interbois/tab_coment_clima"),
        ]),
        getItem(accessLevel.usr_noticias,"Intergrãos", "Tabelas Intergrãos", <PiGrainsLight />, [
          getItem(accessLevel.usr_noticias,"Soja", "/intergraos/tab_soja_oferta"),
          getItem(accessLevel.usr_noticias,"Milho", "/intergraos/tab_milho_oferta"),
          getItem(accessLevel.usr_noticias,"Café", "/intergraos/tab_cafe_oferta"),
          getItem(accessLevel.usr_noticias,"Açúcar", "/intergraos/tab_cana_oferta"),
          getItem(accessLevel.usr_noticias,"Trigo", "/intergraos/tab_trigo_oferta"),
          getItem(accessLevel.usr_noticias,"Algodão", "/intergraos/tab_algodao_oferta"),
          getItem(accessLevel.usr_noticias,"Tabela de Clima", "/intergraos/tab_sec_clima"),
          getItem(accessLevel.usr_noticias,"Tabela de Dólar", "/intergraos/tab_sec_dolar"),
        ]),
        getItem(accessLevel.usr_noticias,"Interfrango", "/interfrangos/tab_frango", <GiChicken />),
        getItem(accessLevel.usr_noticias,"Intersuíno", "/intersuinos/tab_suino", <BsPiggyBank />),
        getItem(accessLevel.usr_noticias,"Interleite", "/interleite/tab_leite", <LuMilk />),
      ]),
    
      getItem(accessLevel.usr_video,"Multimídia", "Multimidia", <MdPermMedia />, [
        getItem(accessLevel.usr_video,"Imagens", "ferramentas/imagens", <AiOutlinePicture />),
        getItem(accessLevel.usr_video,"Series", "series", <AiFillVideoCamera />),
        getItem(accessLevel.usr_video,"Vídeos", "videos", <FaPhotoVideo />),
      ]),
      getItem(accessLevel.usr_correcao,"Agroações", "agroacoes", <FaChartColumn />),
      getItem(accessLevel.usr_correcao,"Correções", "correcoes", <MdOutlineChangeCircle />),
    ];
    const auxItems = [...items];
    console.log(items);
    console.log(Items);
    if (useHaveAccess?.haveAcess) {
      auxItems.push(getItem(accessLevel.usr_correcao,"Usuários", "/users", <AiOutlineUser />));
    }
    setItemsTable(auxItems);
  }
  useEffect(() => {
    getMenu();
  }, []);

  return (
    <Layout
      style={{
        height: "100vh",
      }}
    >
      {isLogged && pathname !== "/multimidia/banco_imagens_popup" && (
        <Menu
          theme="dark"
          style={{
            height: "100vh",
            display: "grid",
            gridTemplateRows: "0 min-content 1fr auto 0",
            overflowX: "hidden",
          }}
        >
          <Link
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "15px 0",
              flexGrow: 1,
            }}
            to="/"
          >
            <Image
              style={{
                width: "100%",
                maxWidth: collapsed ? "50px" : "130px",
                height: "100%",
              }}
              preview={false}
              src={Logo}
            />
          </Link>
          <Sider
            collapsible
            collapsed={collapsed}
            style={{
              overflowY: "auto",
              height: "100%",
            }}
            width={"230px"}
            trigger={null}
            theme="dark"
          >
            <Menu
              theme="dark"
              defaultSelectedKeys={["1"]}
              mode="inline"
              items={itemsTable}
              onClick={(value) => changeRoute(value)}
            />
          </Sider>
          <Menu
            style={{
              width: "100%",
              padding: collapsed ? 10 : 15,
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
              zIndex: 1,
              display: "flex",
              flexDirection: collapsed ? "column" : "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 10,
            }}
          >
            <Button
              title="Sair da Conta"
              size={collapsed ? "large" : "middle"}
              danger
              type="primary"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                height: "100%",
                width: "100%",
                fontWeight: "600",
              }}
              onClick={logout}
            >
              {!collapsed && "Sair"}
              <FiLogOut />
            </Button>
            <Button
              title="Colapsar Menu"
              size="large"
              icon={collapsed ? <RiMenuUnfoldLine /> : <RiMenuFoldLine />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                height: "100%",
                width: "100%",
                backgroundColor: "lightgray",
              }}
            />
          </Menu>
        </Menu>
      )}
      <Layout
        style={{
          overflowY: pathname !== "/multimidia/banco_imagens_popup" && "auto",
          height: "100vh",
        }}
      >
        {children}
      </Layout>
    </Layout>
  );
}
