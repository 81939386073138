import React, { useMemo, useEffect, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { Button, Typography } from "antd";
import { request, requestWithImage } from "../../../functions/request";
import { uniKey, validData } from "../../../functions/functionsUtility";
import Aguarde from "../../../components/aguarde/aguarde";
import { useNavigate } from "react-router-dom";
import { BiRightArrow, BiArrowBack } from "react-icons/bi";
import {
  AiOutlineSearch,
  AiFillEdit,
  AiFillEye,
  AiOutlinePlus,
  AiOutlinePause,
  AiFillVideoCamera,
} from "react-icons/ai";
import ModalCapitulo from "../../../components/Modal/Series/ModalCapitulo";
import { VscCircleLargeFilled } from "react-icons/vsc";
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { Content } from "antd/es/layout/layout";
import { IoAddCircle } from "react-icons/io5";

const SeriesCapitulos = () => {
  const navigate = useNavigate();
  //Modal Preview
  const [nameSerie, setNameSerie] = useState("");
  const [idSerie, setIdSerie] = useState("");
  //Modal Add
  const [isAddOpen, setIsAddOpen] = useState(false);
  const [contentModal, setContentModal] = useState({});

  const [haveResult, setHaveResult] = useState(false);
  const [tableData, setTableData] = useState([]);

  const confirmStop = (row) => {
    confirmAlert({
      title:
        row.original.serie_ativa === 1
          ? "Você tem certeza que deseja pausar essa série?"
          : "Você tem certeza que deseja ativar essa série?",
      message:
        row.original.serie_ativa === 1
          ? "Todos os seus capitulos sairão do ar e não poderão ser acessados pelos usuários."
          : "Todos os seus capitulos voltarão a ficar disponíveis para os usuários.",
      buttons: [
        {
          label: "Não",
          className: "button-delete",
          onClick: () => {},
        },
        {
          label: "Sim",
          className: "button-save",
          onClick: () => {
            const updatedRow = {
              cap_id: row.original.cap_id,
              cap_ativo: row.original.cap_ativo === 1 ? 0 : 1,
            };
            changeCapitulo(updatedRow);
          },
        },
      ],
    });
  };

  const changeCapitulo = async (row, capaImage = null) => {
    console.log(row);
    const canSave = await validData(row, [
      "cap_nome",
      "cap_ordem",
      "cap_video",
      "cap_ativo",
    ]);
    if (!canSave) {
      toast.error("Preencha todos os campos obrigatórios! Campo: Nome, Vídeo");
      return;
    }
    try {
      const newData = JSON.stringify({ ...row, index: uniKey() });
      const formData = new FormData();
      formData.append("newData", newData);
      if (capaImage !== null) {
        formData.append("cap_capa", capaImage);
      }

      const response = await requestWithImage(
        "/capitulos/updateCapitulo/" + row.cap_id,
        {
          method: "PUT",
          body: formData,
        }
      )
        .then((res) => {
          toast.success("Série Salva com Sucesso!");
          setIsAddOpen(false);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          toast.error("Erro ao Salvar Série!");
        });
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
  };

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        const id = window.location.pathname.split("/")[3];
        const urlParams = new URLSearchParams(window.location.search);
        const nomeParam = urlParams.get("nome");
        setNameSerie(nomeParam);
        const currentPathWithQuery = `/capitulos/getCapitulos?id=${id}`;
        const response = await request(currentPathWithQuery, {
          method: "GET",
        });
        setIdSerie(id);
        setTableData(response);
        setHaveResult(true);
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    };
    fetchTableData();
  }, []);
  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "cap_ordem", //access nested data with dot notation
        header: "Ordem",
        size: 10,
      },

      {
        accessorKey: "cap_nome", //access nested data with dot notation
        header: "Nome do Capitulo",
        size: 50,
      },
      {
        accessorKey: "cap_ativo", //normal accessorKey
        header: "Ativo",
        size: 50,
        Cell: ({ cell }) =>
          cell.getValue() === 1 ? (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <VscCircleLargeFilled
                color="info"
                fontSize="small"
                className="active-icon"
              />
              Ativo
            </div>
          ) : cell.getValue() === 0 ? (
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <VscCircleLargeFilled
                color="info"
                fontSize="small"
                style={{ color: "var( --gray-color)" }}
              />
              Offline
            </div>
          ) : null,
      },
    ],
    []
  );

  const openModal = async (content) => {
    try {
      setContentModal(content);
      setIsAddOpen(true);
    } catch (error) {
      console.error("Erro na requisição:", error);
    }
  };

  return (
    <>
      <ModalCapitulo
        isAddOpen={isAddOpen}
        contentModal={contentModal}
        setContentModal={setContentModal}
        changeCapitulo={changeCapitulo}
        onRequestClose={() => setIsAddOpen(false)}
      />
      {haveResult ? (
        tableData.length > 0 ? (
          <MaterialReactTable
            columns={columns}
            data={tableData}
            enableColumnFilterModes
            enableColumnSorting
            enableRowActions
            localization={MRT_Localization_PT_BR}
            positionActionsColumn="last"
            enableRowVirtualization
            enableFacetedValues
            columnFilterModeOptions={null}
            initialState={{
              showColumnFilters: true,
              pagination: { pageIndex: 0, pageSize: 20 },
            }}
            displayColumnDefOptions={{
              "mrt-row-actions": {
                header: "", // Altere o texto do cabeçalho, se necessário
                size: 100, // Faça a coluna de ações um pouco mais larga
              },
            }}
            renderRowActions={({ row }) => (
              <div
                style={{ display: "flex", gap: "5px", justifyContent: "end" }}
              >
                <Button
                  onClick={(el) => {
                    openModal(row.original);
                  }}
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <AiFillEdit />
                  Editar
                </Button>
                <Button
                  onClick={(el) => confirmStop(row)}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {row.original.cap_ativo ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <AiOutlinePause /> Pausar
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <BiRightArrow /> Ativar
                    </div>
                  )}
                </Button>
              </div>
            )}
            renderTopToolbarCustomActions={() => (
              <Content>
                <Button
                  onClick={(el) => navigate(-1)}
                  style={{ display: "flex", alignItems: "center", gap: "5px" }}
                >
                  <BiArrowBack /> Voltar
                </Button>
                <Content
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 20,
                    padding: 10,
                  }}
                >
                  <Typography.Title
                    level={4}
                    style={{
                      margin: 0,
                      display: "flex",
                      gap: 5,
                      alignItems: "center",
                    }}
                  >
                    <AiFillVideoCamera />
                    {nameSerie ? "Série: " + nameSerie : "Série"}
                  </Typography.Title>
                  <Button
                    type="primary"
                    icon={<IoAddCircle />}
                    onClick={(el) => {
                      setContentModal({ cap_serie_id: idSerie });
                      setIsAddOpen(true);
                    }}
                  >
                    Adicionar Capítulo
                  </Button>
                </Content>
              </Content>
            )}
            muiTableHeadCellFilterTextFieldProps={{
              placeholder: "Filtrar",
            }}
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "80%",
            }}
          >
            <h2>Ops! Não encontramos resultados para a busca.</h2>
            <AiOutlineSearch size={48} color="#888" />
            <p>Tente pesquisar novamente com outros termos ou filtros.</p>
          </div>
        )
      ) : (
        <Aguarde />
      )}
    </>
  );
};

export default SeriesCapitulos;
