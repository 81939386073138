import { Descriptions, Input, Spin } from "antd";
import React from "react";
import DescriptionPeriodValue from "./DescriptionPeriodValue";
import { Content } from "antd/es/layout/layout";

export default function DescriptionMinMax({
  title,
  date,
  items,
  buttonText,
  onClickButton,
  datePickerValue,
  datePickerOnChange,
  inputValue,
  state,
  setState,
  stateKey,
  isLoading = true,
  hasError = false,
  defaultValueFromApi = false,
  data,
}) {
  console.log(state);
  return (
    <DescriptionPeriodValue
      title={title}
      items={items}
      buttonText={buttonText}
      onClickButton={onClickButton}
      date={date}
      datePickerValue={datePickerValue}
      datePickerOnChange={datePickerOnChange}
      inputValue={inputValue}
      state={state}
      setState={setState}
      stateKey={stateKey}
      isLoading={isLoading}
      hasValue={false}
      hasError={hasError}
    >
      <>
        {data?.map((element) => {
          return (
            <Descriptions.Item
              label={element.label}
              span={3}
              children={
                <Content
                  style={{ display: "flex", alignItems: "center", gap: 10 }}
                >
                  <Input
                    placeholder="00,00"
                    value={
                      defaultValueFromApi ? state[element.value] : undefined
                    }
                    disabled={isLoading}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      const formattedValue = inputValue;
                      const newData = {
                        ...state,
                        [element.value]: formattedValue,
                      };
                      setState(newData);
                    }}
                  />
                  {isLoading && <Spin size="small" />}
                </Content>
              }
            />
          );
        })}
      </>
    </DescriptionPeriodValue>
  );
}
