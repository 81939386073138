import React, { useState, useEffect } from "react";
import "./correcao.css";
import { getDolarbyDate, request } from "../../functions/request";
import { Table } from "antd";
import { Descriptions } from "antd";
import { Content } from "antd/es/layout/layout";
import { toast } from "react-toastify";

function formatDate(dataArray) {
  let arrayData = [];
  let dataContent = Array.isArray(dataArray[0]) ? dataArray[0] : dataArray;
  if (
    typeof dataContent === "object" &&
    dataContent !== null &&
    !Array.isArray(dataContent)
  ) {
    const aux = [];
    for (const key in dataContent) {
      if (Array.isArray(dataContent[key]) && dataContent[key].length > 0) {
        dataContent[key][0].map((data) => {
          aux.push(data);
        });
      }
    }
    dataContent = aux;
  }
  if (!dataContent || dataContent === undefined) return dataContent;

  dataContent.map((data) => {
    arrayData.push({
      campodt: new Date(data.campodt).toLocaleDateString("pt-BR", {
        timeZone: "America/Sao_Paulo",
      }),
      campo: data.campo,
      campouf: data.campouf,
      campodolar: Number(data.campo / data.campodolar).toFixed(2),
    });
  });
  return arrayData;
}

export default function CorrecaoRelatorio({ query, allUfs }) {
  const params = new URLSearchParams(query);
  const [commodity, setCommodity] = useState([]);
  const [dadosTabela, setDadosTabela] = useState();
  const [ufCotacao, setUfCotacao] = useState();
  const [dataInicial, setDataInicial] = useState();
  const [dataFinal, setDataFinal] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const descriptions = [
    {
      key: "1",
      label: "Cotação",
      children: commodity.nome,
    },
    {
      key: "2",
      label: "UF",
      children: ufCotacao,
    },
    {
      key: "3",
      label: "Data inicial",
      children: new Date(dataInicial).toLocaleDateString("pt-BR", {
        timeZone: "America/Sao_Paulo",
      }),
    },
    {
      key: "4",
      label: "Data final",
      children: new Date(dataFinal).toLocaleDateString("pt-BR", {
        timeZone: "America/Sao_Paulo",
      }),
    },
  ];

  const columns = [
    {
      title: "UF",
      dataIndex: "campouf",
    },
    {
      title: "Data",
      dataIndex: "campodt",
    },
    {
      title: "Valor",
      dataIndex: "campo",
    },
    {
      title: "Valor em dólar",
      dataIndex: "campodolar",
    },
  ];

  useEffect(() => {
    const fetchTableData = async () => {
      setDadosTabela();
      setIsLoading(true);
      let path = `/correcoes/getcorrecoes`;
      const data_start = params.get("data_start");
      const data_final = params.get("data_final");
      const ordem = params.get("ordem");
      const uf = params.get("uf") ?? "";
      if (data_start && data_final) {
        path += `?data_start=${data_start}&data_final=${data_final}`;
      }
      if (ordem) {
        path += `&ordem=${ordem}`;
      }
      setDataInicial(data_start);
      setDataFinal(data_final);

      if (uf.toUpperCase() === "TODOS") {
        setUfCotacao(uf);
        try {
          const ufsPromises = allUfs.map(async (ufItem) => {
            const ufPath = `${path}&uf=${ufItem.uf}`;
            const response = await request(ufPath, {
              method: "GET",
            });
            const preData = await Promise.all(
              response[0].map(async (item) => ({
                ...item,
                campodolar: await getDolarbyDate(item.campodt),
              }))
            );
            return formatDate(preData);
          });

          const formattedData = await Promise.all(ufsPromises);
          const mergedData = formattedData.flat();

          setDadosTabela(mergedData);

          await getCommodity();
          setIsLoading(false);
        } catch (error) {
          console.error("Erro na requisição:", error);
          toast.error("Ocorreu um erro inesperado! Tente novamente.");
        }
      } else {
        try {
          if (uf) {
            path += `&uf=${uf}`;
            setUfCotacao(uf);
          }

          const response = await request(path, {
            method: "GET",
          });

          const preData = await Promise.all(
            response[0].map(async (item) => ({
              ...item,
              campodolar: await getDolarbyDate(item.campodt),
            }))
          );

          const formattedData = formatDate(preData);

          setDadosTabela(formattedData);

          await getCommodity();
          setIsLoading(false);
        } catch (error) {
          console.error("Erro na requisição:", error);
          toast.error("Ocorreu um erro inesperado! Tente novamente.");
        }
      }
    };
    fetchTableData();
  }, [query]);

  const getCommodity = async () => {
    const ordem = params.get("ordem");

    await request(`/correcoes/getCommodityByOrdem/${ordem}`, {
      method: "GET",
    })
      .then((response) => {
        setCommodity(response[0]);
      })
      .catch((error) => {
        console.error("Erro na requisição:", error);
      });
  };

  const onChange = (pagination, filters, sorter, extra) => {
    console.log("params", pagination, filters, sorter, extra);
  };

  return (
    <Content
      style={{
        padding: 10,
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        zIndex: 0,
      }}
      className="slide-right"
    >
      <Descriptions title={"Relatório"} column={2} items={descriptions} />
      <Table
        loading={isLoading}
        columns={columns}
        dataSource={dadosTabela}
        onChange={onChange}
        pagination={false}
      />
    </Content>
  );
}
