import React, { useContext, useEffect, useState } from "react";
import { Button, Image, Layout, Menu } from "antd";
import { useNavigate, Link, useLocation } from "react-router-dom";
import logoImage from "../assets/logorb.png";
import Logo from "../assets/logorbwhite.png";
import { FiLogOut } from "react-icons/fi";
import { AiOutlineUser } from "react-icons/ai";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import { AuthContext } from "../context/AuthContext";
import Items from "../pages/allpages";

const { Header, Content, Sider } = Layout;

function getItem(label, key, icon, items) {
  return {
    key,
    icon,
    items,
    label,
  };
}

export default function AppLayout({ children }) {
  const { isLogged, useHaveAccess, logout } = useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(false);
  const [itemsTable, setItemsTable] = useState(Items);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  console.log(pathname);
  const changeRoute = (data) => {
    navigate(data.key);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    if (useHaveAccess?.haveAcess) {
      const auxItems = [...Items];
      auxItems.push(getItem("Usuários", "/users", <AiOutlineUser />));
      setItemsTable(auxItems);
    }
  }, []);

  return (
    <Layout
      style={{
        height: "100vh",
      }}
    >
      {isLogged && pathname !== "/multimidia/banco_imagens_popup" && (
        <Menu
          theme="dark"
          style={{
            height: "100vh",
            display: "grid",
            gridTemplateRows: "0 min-content 1fr auto 0",
            overflowX: "hidden",
          }}
        >
          <Link
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "15px 0",
              flexGrow: 1,
            }}
            to="/"
          >
            <Image
              style={{
                width: "100%",
                maxWidth: collapsed ? "50px" : "130px",
                height: "100%",
              }}
              preview={false}
              src={Logo}
            />
          </Link>
          <Sider
            collapsible
            collapsed={collapsed}
            style={{
              overflowY: "auto",
              height: "100%",
            }}
            width={"230px"}
            trigger={null}
            theme="dark"
          >
            <Menu
              theme="dark"
              defaultSelectedKeys={["1"]}
              mode="inline"
              items={itemsTable}
              onClick={(value) => changeRoute(value)}
            />
          </Sider>
          <Menu
            style={{
              width: "100%",
              padding: collapsed ? 10 : 15,
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
              zIndex: 1,
              display: "flex",
              flexDirection: collapsed ? "column" : "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 10,
            }}
          >
            <Button
              title="Sair da Conta"
              size={collapsed ? "large" : "middle"}
              danger
              type="primary"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                height: "100%",
                width: "100%",
                fontWeight: "600",
              }}
              onClick={logout}
            >
              {!collapsed && "Sair"}
              <FiLogOut />
            </Button>
            <Button
              title="Colapsar Menu"
              size="large"
              icon={collapsed ? <RiMenuUnfoldLine /> : <RiMenuFoldLine />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                height: "100%",
                width: "100%",
                backgroundColor: "lightgray",
              }}
            />
          </Menu>
        </Menu>
      )}
      <Layout
        style={{
          overflowY: pathname !== "/multimidia/banco_imagens_popup" && "auto",
          height: "100vh",
        }}
      >
        {children}
      </Layout>
    </Layout>
  );
}
