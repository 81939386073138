import React, { useState, useEffect } from "react";
import { getData } from "../../../../functions/request";
import {
  formatDateHourToBR,
  saveData,
} from "../../../../functions/functionsUtility";
import DescriptionPeriodValue from "../../../../layout/descriptions/DescriptionPeriodValue";
import { dateMask } from "../../../../functions/utils";
const dayjs = require("dayjs");

export default function IntergraosSojaBmf() {
  const [cotacoesData, setCotacoesData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getData("soja_bmf", "getLastSojaBmf", "insertSojaBmf")
      .then((data) => {
        setCotacoesData(data);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setHasError(err?.message ?? "Ocorreu um erro.");
        console.log(err);
      });
  }, []);

  const [date, setDate] = useState({
    month: "",
    year: dayjs().year().toString(),
  });
  const onChange = (value, type) => {
    if (type === "month") {
      setDate((prevState) => ({ ...prevState, month: value }));
    } else if (type === "year") {
      setDate((prevState) => ({ ...prevState, year: value }));
    }
  };

  useEffect(() => {
    setCotacoesData((prevState) => ({
      ...prevState,
      soj_periodo: dayjs(`01/${date.month}/${date.year}`, "DD/MM/YYYY").format(
        "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
      ),
    }));
  }, [date]);

  return (
    <>
      <DescriptionPeriodValue
        title={"Intergrãos - Soja com LIQUIDAÇÃO FINANCEIRA - Alterando BMF"}
        onClickButton={() => saveData(cotacoesData)}
        date={formatDateHourToBR(cotacoesData.soj_data)}
        datePickerValue={date.year}
        datePickerOnChange={onChange}
        // inputValue={cotacoesData["soj_valor"]}
        state={cotacoesData}
        setState={setCotacoesData}
        stateKey={"soj_valor"}
        isLoading={isLoading}
        hasError={hasError}
      />
    </>
  );
}
