import React, { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./pages/Login/Login";
import AnaliseItem from "./pages/Analise/AnaliseItem";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { AuthContext } from "./context/AuthContext";
import Admin_Initial from "./pages/pageInitial/Admin_Initial";
import Correcao from "./pages/Correcao/Correcao";
import UserPage from "./pages/Usuarios/UserPage";
import CorrecaoRelatorio from "./pages/Correcao/CorrecaoRelatorio";
import AppLayout from "./layout/AppLayout";
import { ConsultNoticias, InserirNoticias } from "./pages/Noticias";
import { ConsultAnalise } from "./pages/Analise";
import {
  InterboisCme,
  InterboisMercadoFuturo,
  IntergraosAcucarBmf,
  IntergraosAcucarLondres,
  IntergraosAcucarNy,
  IntergraosAlcoolBmf,
  IntergraosCafeBmf,
  IntergraosCafeLondres,
  IntergraosCafeNy,
  IntergraosMilhoBmf,
  IntergraosMilhoCcm,
  IntergraosMilhoMercadoFuturo,
  IntergraosSojaBmf,
  IntergraosSojaCbot,
  IntergraosSojaFarelo,
  IntergraosSojaMercadoFuturo,
  IntergraosSojaOleo,
  IntergraosTrigoCme,
  PetroleoIce,
  PetroleoNy,
  PetroleoWti,
} from "./pages/Bolsas";
import {
  DolarCotacao,
  DolarPtaxCotacao,
  IndicesEconomicos,
  InterboisCarneInNatura,
  InterboisCarneIndustrializada,
  InterboisCharque,
  InterboisCortes,
  InterboisCortesEspeciais,
  InterboisCouro,
  InterboisDiversos,
  InterboisLiners,
  InterboisMercadoAtacadista,
  InterboisMiudos,
  InterboisParaguai,
  InterboisPorEstado,
  InterboisTop,
  InterfrangosCorte,
  InterfrangosTop,
  IntergraosAlgodaoCotacao,
  IntergraosCafeBica,
  IntergraosMilhoCotacao,
  IntergraosSojaPorEstado,
  IntergraosTrigoAgranel,
  InterleiteCotacao,
  IntersuinosCortes,
  IntersuinosCortesEspeciais,
  IntersuinosTop,
} from "./pages/Cotacoes";
import Tabela from "./pages/Tabelas/Tabela";
import {
  BancoImagensPopUp,
  Imagens,
  Series,
  SeriesCapitulos,
  Videos,
} from "./pages/Multimidia";
import Agroacoes from "./pages/Agroacoes/Agroacoes";

const App = () => {
  const { isLogged, useHaveAccess } = useContext(AuthContext);

  const tabelaRoutes = [
    { path: "/interbois/tab_coment_diario", title: "Tabela Diária" },
    { path: "/interbois/tab_coment_mensal", title: "Tabela Mensal" },
    { path: "/interbois/tab_coment_clima", title: "Tabela de Clima" },
    { path: "/intergraos/tab_soja_oferta", title: "Tabela de Soja Oferta" },
    { path: "/intergraos/tab_milho_oferta", title: "Tabela de Milho Oferta" },
    { path: "/intergraos/tab_cafe_oferta", title: "Tabela de Café Oferta" },
    { path: "/intergraos/tab_cana_oferta", title: "Tabela de Açucar Oferta" },
    { path: "/intergraos/tab_trigo_oferta", title: "Tabela de Trigo Oferta" },
    {
      path: "/intergraos/tab_algodao_oferta",
      title: "Tabela de Algodão Oferta",
    },
    { path: "/intergraos/tab_sec_clima", title: "Tabela de Clima Intergrãos" },
    { path: "/intergraos/tab_sec_dolar", title: "Tabela de Dolar Intergrãos" },
    { path: "/intersuinos/tab_suino", title: "Tabela de Suinos" },
    { path: "/interfrangos/tab_frango", title: "Tabela de Frango" },
    { path: "/interleite/tab_leite", title: "Tabela de Leite" },
  ];

  return (
    <AppLayout>
      <Routes>
        {isLogged ? (
          <>
            <Route index path="/" element={<Admin_Initial />} />
            <Route path="/agroacoes" element={<Agroacoes />} />
            {/* analises rotas */}
            <Route path="/comentario" element={<ConsultAnalise />} />
            <Route
              path="/comentario/getcomentariobysection"
              element={<AnaliseItem />}
            />
            <Route
              path="/comentario/getcomentarios/:id"
              element={<AnaliseItem />}
            />
            <Route
              path="/comentario/insertComentario"
              element={<AnaliseItem />}
            />
            {/* noticias RTE rotas */}
            <Route
              path="/noticia/inserir_noticias"
              element={<InserirNoticias />}
            />
            <Route
              path="/noticia/consultar_noticias"
              element={<ConsultNoticias />}
            />
            <Route
              path="/noticia/getnoticia/:id"
              element={<InserirNoticias />}
            />

            {/* petroleo rotas */}
            <Route path="/petroleo/petroleo_ice" element={<PetroleoIce />} />
            <Route path="/petroleo/petroleo_wti" element={<PetroleoWti />} />
            <Route path="/petroleo/petroleo_ny" element={<PetroleoNy />} />

            {tabelaRoutes.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={<Tabela route={route} />}
              />
            ))}
            <Route path="/intersuinos/cottop" element={<IntersuinosTop />} />
            <Route path="/intersuinos/cortes" element={<IntersuinosCortes />} />
            <Route
              path="/intersuinos/cortes_esp"
              element={<IntersuinosCortesEspeciais />}
            />
            <Route path="/interfrangos/fratop" element={<InterfrangosTop />} />
            <Route
              path="/interfrangos/cortes"
              element={<InterfrangosCorte />}
            />
            <Route
              path="/interleite/leite_cotacao"
              element={<InterleiteCotacao />}
            />
            <Route path="/interbois/cotacoestop" element={<InterboisTop />} />
            <Route
              path="/interbois/boi_atacado"
              element={<InterboisMercadoAtacadista />}
            />
            <Route
              path="/interbois/boi_futuro"
              element={<InterboisMercadoFuturo />}
            />
            <Route
              path="/interbois/boi_paraguai"
              element={<InterboisParaguai />}
            />
            <Route path="/interbois/boi_est" element={<InterboisPorEstado />} />
            <Route path="/interbois/boi_miudos" element={<InterboisMiudos />} />
            <Route path="/interbois/boi_liners" element={<InterboisLiners />} />
            <Route
              path="/interbois/boi_carne_natura"
              element={<InterboisCarneInNatura />}
            />
            <Route path="/interbois/boi_cortes" element={<InterboisCortes />} />
            <Route
              path="/interbois/boi_carne_ind"
              element={<InterboisCarneIndustrializada />}
            />
            <Route path="/interbois/boi_cme" element={<InterboisCme />} />
            <Route
              path="/interbois/boi_corte_trad_esp"
              element={<InterboisCortesEspeciais />}
            />
            <Route
              path="/interbois/boi_diversos"
              element={<InterboisDiversos />}
            />
            <Route path="/interbois/boi_couro" element={<InterboisCouro />} />
            <Route
              path="/interbois/boi_charque"
              element={<InterboisCharque />}
            />
            <Route
              path="/intergraos/cafe_bmf"
              element={<IntergraosCafeBmf />}
            />
            <Route path="/intergraos/cafe_ny" element={<IntergraosCafeNy />} />
            <Route
              path="/intergraos/cafe_londres"
              element={<IntergraosCafeLondres />}
            />
            <Route
              path="/intergraos/cafe_bica"
              element={<IntergraosCafeBica />}
            />
            <Route
              path="/intergraos/milho_bmf"
              element={<IntergraosMilhoBmf />}
            />
            <Route
              path="/intergraos/milho_bmfccm"
              element={<IntergraosMilhoCcm />}
            />
            <Route
              path="/intergraos/milho_cotacao"
              element={<IntergraosMilhoCotacao />}
            />
            <Route
              path="/intergraos/soja_cotacao"
              element={<IntergraosSojaPorEstado />}
            />
            <Route
              path="/intergraos/soja_futuro"
              element={<IntergraosSojaMercadoFuturo />}
            />
            <Route
              path="/intergraos/soja_oleo"
              element={<IntergraosSojaOleo />}
            />
            <Route
              path="/intergraos/soja_farelo"
              element={<IntergraosSojaFarelo />}
            />
            <Route
              path="/intergraos/soja_cbot"
              element={<IntergraosSojaCbot />}
            />
            <Route
              path="/intergraos/soja_bmf"
              element={<IntergraosSojaBmf />}
            />
            <Route
              path="/intergraos/trigo_cme"
              element={<IntergraosTrigoCme />}
            />
            <Route
              path="/intergraos/trigo_agranel"
              element={<IntergraosTrigoAgranel />}
            />
            <Route
              path="/intergraos/algodao_cotacao"
              element={<IntergraosAlgodaoCotacao />}
            />
            <Route
              path="/intergraos/milho_futuro"
              element={<IntergraosMilhoMercadoFuturo />}
            />
            <Route
              path="/intergraos/acucar_bmf"
              element={<IntergraosAcucarBmf />}
            />
            <Route
              path="/intergraos/acucar_lond"
              element={<IntergraosAcucarLondres />}
            />
            <Route
              path="/intergraos/acucar_ny"
              element={<IntergraosAcucarNy />}
            />
            <Route
              path="/intergraos/alcool_bmf"
              element={<IntergraosAlcoolBmf />}
            />
            <Route path="/series" element={<Series />} />
            <Route path="/series/editar/:id" element={<SeriesCapitulos />} />
            <Route path="/videos" element={<Videos />} />
            <Route
              path="/ferramentas/indices"
              element={<IndicesEconomicos />}
            />
            <Route
              path="/ferramentas/cotacoes_dolar"
              element={<DolarCotacao />}
            />
            <Route
              path="/ferramentas/cotacoes_dolarptax"
              element={<DolarPtaxCotacao />}
            />
            <Route path="/ferramentas/imagens" element={<Imagens />} />
            <Route
              path="/multimidia/banco_imagens_popup"
              element={<BancoImagensPopUp />}
            />
            <Route path="/correcoes" element={<Correcao />} />
            <Route path="/correcoes/tabela" element={<CorrecaoRelatorio />} />
            {useHaveAccess?.haveAcess ? (
              <Route path="/users" element={<UserPage />} />
            ) : (
              <Route path="/users" element={<Admin_Initial />} />
            )}
          </>
        ) : (
          <>
            <Route Component={Login} path="/login" />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
    </AppLayout>
  );
};

export default App;
