import React, { useState, useEffect } from "react";
import { DatePicker, Select, Button, Layout, Descriptions } from "antd";
import locale from "antd/es/date-picker/locale/pt_BR";
import "./correcao.css";
import { AiOutlineSearch } from "react-icons/ai";
import { toast } from "react-toastify";
import { request } from "../../functions/request";
import { Content } from "antd/es/layout/layout";
import { rangePresets } from "../../constants/constants";
import { BiTable } from "react-icons/bi";
import CorrecaoRelatorio from "./CorrecaoRelatorio";
import CorrecaoSearch from "./CorrecaoSearch";
import { Consult } from "../../components/Consult";
import { useForm } from "antd/es/form/Form";

const { RangePicker } = DatePicker;

const mapLabelToName = (label) => {
  switch (label) {
    case 1:
      return "Agroações";
    case 2:
      return "Gado PY/AR";
    case 3:
      return "Gado Magro";
    case 4:
      return "Gado Cortes";
    case 5:
      return "Gado Atacado";
    case 6:
      return "Boi Gordo";
    case 7:
      return "Agricultura";
    case 8:
      return "Leite/Suino/Frango";
    case 9:
      return "Dolár/Petróleo";
    default:
      return "Unknown Label";
  }
};

const Correcao = () => {
  const [ufList, setUfList] = useState();
  const [optUfList, setOptUfList] = useState([]);
  const [cotacao, setCotacao] = useState();
  const [estado, setEstado] = useState();
  const [dataRange, setDataRange] = useState([]);
  const [optCommo, setOptCommo] = useState();
  const [commList, setCommList] = useState([]);
  const [query, setQuery] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [toggleTableView, setToggleTableView] = useState({
    search: false,
    table: false,
  });
  const [form] = useForm();

  const handleElementos = (values) => {
    const commodity = commList.find((item) => item.id === values.quotation);
    const { ordem } = commodity;

    let queryParams = `?data_start=${encodeURIComponent(
      values.dates[0].$d
    )}&data_final=${encodeURIComponent(values.dates[1].$d)}`;
    queryParams += `&ordem=${encodeURIComponent(ordem)}`;
    if (estado) {
      queryParams += `&uf=${encodeURIComponent(values.state)}`;
    }

    setQuery(`${queryParams}`);
  };

  // req de todas as cotações
  useEffect(() => {
    request("/correcoes/getAllCommodityGraf/", {
      method: "GET",
    })
      .then((response) => {
        // console.log(response);
        const optionsCorrecoes = response.reduce((acc, item) => {
          const mappedLabel = mapLabelToName(item.tipo);

          const existingLabel = acc.find(
            (group) => group.label === mappedLabel
          );

          if (existingLabel) {
            existingLabel.options.push({
              value: item.id,
              label: item.nome,
            });
          } else {
            acc.push({
              label: mappedLabel,
              options: [{ value: item.id, label: item.nome }],
            });
          }

          return acc;
        }, []);
        setCommList(response);
        setOptCommo(optionsCorrecoes);
      })
      .catch((error) => {
        console.error("Erro na requisição:", error);
      });
  }, []);

  //atualizar tabela de estados ao selecionar uma cotação
  useEffect(() => {
    const commodity = commList.find((item) => item.id === cotacao);
    if (commodity) {
      const { ordem } = commodity;
      setIsLoading(true);
      request(`/correcoes/getUfByOrdem/${ordem}`, {
        method: "GET",
      })
        .then((response) => {
          setUfList(response);
          const ufOptions = response.map((item) => ({
            value: item.uf,
            label: item.uf,
          }));

          if (ufOptions.length > 0) {
            setOptUfList(() => [
              { value: "TODOS", label: "Todos" },
              ...ufOptions,
            ]);
          } else {
            setOptUfList([]);
            setEstado();
            form.setFieldValue("state", "");
          }
        })
        .finally(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Erro na requisição:", error);
          toast.error("Ocorreu um erro inesperado! Tente novamente.");
        });
    }
  }, [cotacao]);

  return (
    <Consult.Root panelSizes={["30%", "auto"]}>
      <Consult.Form
        form={form}
        title={"Correções"}
        variant="filled"
        bordered
        onFinish={(values) => {
          handleElementos(values);
        }}
        extra={
          <Content style={{ display: "flex", gap: 5, justifyContent: "end" }}>
            <Button
              icon={<BiTable />}
              htmlType="submit"
              onClick={() => {
                setToggleTableView((prevState) => ({
                  search: false,
                  table: true,
                }));
                console.log(form.getFieldsValue());
              }}
            >
              Relatório
            </Button>
            <Button
              type="primary"
              icon={<AiOutlineSearch />}
              htmlType="submit"
              onClick={() => {
                setToggleTableView((prevState) => ({
                  search: true,
                  table: false,
                }));
              }}
            >
              Buscar
            </Button>
          </Content>
        }
        style={{ width: "100%" }}
      >
        <Consult.Field
          label={"Cotação"}
          name={"quotation"}
          rules={[{ required: true, message: "Escolha uma Cotação!" }]}
          children={
            <Select
              showSearch
              style={{ width: "100%" }}
              optionFilterProp="children"
              placeholder="Selecione uma Cotação"
              filterOption={(input, option) =>
                (option?.label.toLocaleLowerCase() ?? "").includes(
                  input.toLocaleLowerCase()
                )
              }
              options={optCommo}
              value={cotacao}
              onChange={setCotacao}
            />
          }
        />
        <Consult.Field
          label={"Data Inicial e Final"}
          name={"dates"}
          rules={[
            { required: true, message: "Selecione uma data inicial e final!" },
          ]}
          hasFeedback
          children={
            <RangePicker
              style={{ width: "100%" }}
              presets={rangePresets}
              onChange={(dates, dateStrings) => setDataRange(dates)}
              locale={locale}
              value={dataRange}
            />
          }
        />
        <Consult.Field
          label={"Estado"}
          name={"state"}
          span={4}
          children={
            <Select
              showSearch
              style={{ width: "100%" }}
              optionFilterProp="children"
              placeholder="Selecione um estado"
              filterOption={(input, option) =>
                (option?.label.toLocaleLowerCase() ?? "").includes(
                  input.toLocaleLowerCase()
                )
              }
              disabled={optUfList <= 0}
              loading={isLoading}
              options={optUfList}
              value={estado}
              onChange={setEstado}
              allowClear={true}
            />
          }
        />
      </Consult.Form>
      <Content style={{ width: "100%", height: "100%", overflowY: "scroll" }}>
        {toggleTableView.search && query ? (
          <CorrecaoSearch query={query} allUfs={ufList} />
        ) : toggleTableView.table && query ? (
          <CorrecaoRelatorio query={query} allUfs={ufList} />
        ) : null}
      </Content>
    </Consult.Root>
  );
};

export default Correcao;
