import React, { useState, useEffect } from "react";
import { getDataWithEndpoint } from "../../../functions/request";
import {
  formatDateHourToBR,
  updateData,
} from "../../../functions/functionsUtility";
import { DescriptionMinMax } from "../../../layout";

const cortesType = [
  {
    label: "Barriga c/ Costela (cong.)",
    value: "bar_ccostela_cong",
  },
  {
    label: "Salg",
    value: "bar_ccostela_sal",
  },
  {
    label: "Barriga s/ Costela (cong.)",
    value: "bar_scostela_cong",
  },
  {
    label: "Salg",
    value: "bar_scostela_sal",
  },
  { label: "Copa (cong.)", value: "copa_cong" },
  {
    label: "Salg",
    value: "copa_sal",
  },
  { label: "Couro (cong.)", value: "couro_cong" },
  {
    label: "Salg",
    value: "couro_sal",
  },
  { label: "Costela (cong.)", value: "cost_cong" },
  {
    label: "Salg",
    value: "cost_sal",
  },
  { label: "Filé (cong.)", value: "filet_cong" },
  {
    label: "Salg",
    value: "filet_sal",
  },
  { label: "Língua (cong.)", value: "lingua_cong" },
  {
    label: "Salg",
    value: "lingua_sal",
  },
  { label: "Orelha (cong.)", value: "orelha_cong" },
  {
    label: "Salg",
    value: "orelha_sal",
  },
  { label: "Pé (cong.)", value: "pe_cong" },
  {
    label: "Salg",
    value: "pe_sal",
  },
  { label: "Rabo (cong.)", value: "rabo_cong" },
  {
    label: "Salg",
    value: "rabo_sal",
  },
  {
    label: "Retalho Gordo (cong.)",
    value: "ret_gordo_cong",
  },
  {
    label: "Salg",
    value: "ret_gordo_sal",
  },
  {
    label: "Retalho Magro (cong.)",
    value: "ret_magro_cong",
  },
  {
    label: "Salg",
    value: "ret_magro_sal",
  },
  {
    label: "Toucinho de Lombo (cong.)",
    value: "touc_lombo_cong",
  },
  {
    label: "Salg",
    value: "touc_lombo_sal",
  },
  {
    label: "Toucinho s/ Couro (cong.)",
    value: "touc_scouro_cong",
  },
  {
    label: "Salg",
    value: "touc_scouro_sal",
  },
];

export default function IntersuinosCortes() {
  const [cotacoesData, setCotacoesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getDataWithEndpoint("suino_cortes", "getSuinoCortes")
      .then((data) => {
        setCotacoesData(data);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setHasError(err?.message ?? "Ocorreu um erro.");
        console.log(err);
      });
  }, []);

  return (
    <>
      <DescriptionMinMax
        title={"Intersuinos - Cortes"}
        onClickButton={async () => {
          updateData({
            ...cotacoesData,
            endpoint: "updateSuinoCortes",
            path: "suino_cortes",
          });
        }}
        buttonText={"Atualizar"}
        data={cortesType}
        defaultValueFromApi
        date={formatDateHourToBR(cotacoesData.sui_data)}
        state={cotacoesData}
        setState={setCotacoesData}
        isLoading={isLoading}
        hasError={hasError}
        hasValue={false}
      />
    </>
  );
}
