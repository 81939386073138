import React, { useState, useEffect } from "react";
import { getDataWithEndpoint } from "../../../functions/request";
import {
  formatDateHourToBR,
  updateData,
} from "../../../functions/functionsUtility";
import { DescriptionMinMax } from "../../../layout";

const cortesType = [
  { label: "Alcatra Completa", value: "alcatra_comp" },
  { label: "Alcatra c/Maminha", value: "alcatra_maminha" },
  { label: "Alcatra Miolo", value: "alcatra_miolo" },
  { label: "Capa de Filet", value: "capa_file" },
  { label: "Contra Filet", value: "contra_file" },
  { label: "Coxão Duro", value: "coxao_duro" },
  { label: "Coxão Mole", value: "coxao_mole" },
  { label: "Cupim A", value: "cupim_a" },
  { label: "Cupim B", value: "cupim_b" },
  { label: "Filet Mignon c/ Cordão", value: "file_ccordao" },
  { label: "Filet Mignon s/ Cordão", value: "file_scordao" },
  { label: "Lagarto", value: "lagarto" },
  { label: "Maminha", value: "maminha" },
  { label: "Músculo", value: "musculo" },
  { label: "Patinho", value: "patinho" },
  { label: "Picanha A", value: "picanha_a" },
  { label: "Picanha B", value: "picanha_b" },
];

export default function InterboisCortesEspeciais() {
  const [cotacoesData, setCotacoesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getDataWithEndpoint("boi_corte_trad_esp", "getBoiCorteTradEspc")
      .then((data) => {
        setCotacoesData(data);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setHasError(err?.message ?? "Ocorreu um erro.");
        console.log(err);
      });
  }, []);

  return (
    <>
      <DescriptionMinMax
        title={"Interbois - Cortes Especiais e Tradicionais"}
        onClickButton={async () => {
          updateData({
            ...cotacoesData,
            endpoint: "updateBoiCorteTradEspc",
            path: "boi_corte_trad_esp",
          });
        }}
        buttonText={"Atualizar"}
        data={cortesType}
        defaultValueFromApi
        date={formatDateHourToBR(cotacoesData.boi_data)}
        state={cotacoesData}
        setState={setCotacoesData}
        isLoading={isLoading}
        hasError={hasError}
        hasValue={false}
      />
    </>
  );
}
