import React, { useEffect, useState } from "react";
import JoditEditor, { Jodit } from "jodit-react";
import { request } from "../../functions/request";
import { formatDateHourToBR } from "../../functions/functionsUtility";
import { FaSave } from "react-icons/fa";
import { toast } from "react-toastify";
import Aguarde from "../../components/aguarde/aguarde";
import { Content } from "antd/es/layout/layout";
import { Button, FloatButton, Typography } from "antd";
const { Title, Paragraph, Text, Link } = Typography;

function Tabela({ route }) {
  const [content, setContent] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const getSuffix = () => {
    return (
      route.path.split("/")[2].charAt(0).toUpperCase() +
      route.path
        .split("/")[2]
        .slice(1)
        .replace(/\//g, "")
        .replace(/_(\w)/g, (match, p1) => p1.toUpperCase())
    );
  };

  useEffect(() => {
    async function getData() {
      try {
        setIsLoading(true);
        const reqPath = `/${route.path.split("/")[2]}/get${getSuffix()}`;
        const response = await request(reqPath, {
          method: "GET",
        });

        const data = { ...response, title: route.title, path: route.path };
        setContent(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Erro ao obter dados:", error);
        // Tratar o erro, se necessário.
      }
    }

    getData();
  }, [route]);

  const saveData = async () => {
    try {
      // Construindo o sufixo para operação POST com base no route.path
      const reqPath = `/${route.path.split("/")[2]}/insert${getSuffix()}`;

      const newData = { newData: { texto: content.texto } };

      const response = await request(reqPath, {
        method: "POST",
        body: JSON.stringify(newData),
      });

      if (response) {
        toast.success("Dados salvos com sucesso!");
      }
    } catch (error) {
      console.error("Erro ao salvar dados:", error);
      // Tratar o erro, se necessário.
    }
  };

  function preparePaste(jodit) {
    jodit.e.on(
      "paste",
      (e) => {
        jodit.e.stopPropagation("paste");

        // Get the pasted HTML data
        const pastedHtml = Jodit.modules.Helpers.getDataTransfer(e).getData(
          Jodit.constants.TEXT_HTML
        );

        // Filter out unwanted characters like &nbsp;
        let filteredHtml = pastedHtml.replace(/&nbsp;/g, " ");
        filteredHtml = filteredHtml.replace(/=""/g, " ");

        // Insert the filtered HTML
        try {
          jodit.s.insertHTML(filteredHtml);
        } catch (error) {
          console.log(error);
        }

        return false;
      },
      { top: true }
    );
  }

  useEffect(() => {
    Jodit.plugins.add("preparePaste", preparePaste);
  }, []);

  return (
    <Content
      style={{
        display: "flex",
        flexDirection: "column",
        maxHeight: "100vh",
        height: "100%",
      }}
    >
      <div
        style={{
          padding: 20,
          display: "flex",
          gap: 20,
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Title level={3} style={{ margin: 0 }}>
            {route?.title}
          </Title>
          <Text type="secondary">
            Última atualização: {formatDateHourToBR(content?.data)}
          </Text>
        </Typography>
        <Button
          type="primary"
          size="large"
          icon={<FaSave />}
          disabled={isLoading}
          onClick={saveData}
        >
          Inserir
        </Button>
      </div>
      {isLoading ? (
        <Aguarde />
      ) : (
        <Content
          style={{
            maxWidth: "1000px",
            width: "100%",
            marginInline: "auto",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <JoditEditor
            config={{ height: "auto" }}
            value={content?.texto}
            onBlur={(newContent) => {
              const newData = { ...content, texto: newContent };
              setContent(newData);
            }}
            tabIndex={1}
          />
        </Content>
      )}
      <FloatButton.BackTop style={{ zIndex: 100 }} />
    </Content>
  );
}

export default Tabela;
