import { Button } from "antd";
import React from "react";
import { AiOutlineSearch } from "react-icons/ai";

export function ConsultButton({
  title = "",
  icon = <AiOutlineSearch />,
  type = "primary",
  htmlType = "",
  size = "large",
  onClick = () => {},
}) {
  return (
    <Button
      htmlType={htmlType}
      icon={icon}
      type={type}
      onClick={onClick}
      size={size}
    >
      {title}
    </Button>
  );
}
