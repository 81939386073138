import React, { useState, useEffect } from "react";
import { getData } from "../../../../functions/request";
import {
  formatDateHourToBR,
  saveData,
} from "../../../../functions/functionsUtility";
import { DescriptionMinMax } from "../../../../layout";
import { dateMask } from "../../../../functions/utils";
import { acucarOptions } from "../../../../constants/constants";
const dayjs = require("dayjs");

export default function IntergraosAcucarBmf() {
  const [cotacoesData, setCotacoesData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getData("acucar_bmf", "getLastAcucarBmf", "insertAcucarBmf")
      .then((data) => {
        setCotacoesData(data);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setHasError(err?.message ?? "Ocorreu um erro.");
        console.log(err);
      });
  }, []);

  const [date, setDate] = useState({
    month: "",
    year: dayjs().year().toString(),
  });
  const onChange = (value, type) => {
    if (type === "month") {
      setDate((prevState) => ({ ...prevState, month: value }));
    } else if (type === "year") {
      setDate((prevState) => ({ ...prevState, year: value }));
    }
  };

  useEffect(() => {
    setCotacoesData((prevState) => ({
      ...prevState,
      acucar_periodo: dayjs(
        `01/${date.month}/${date.year}`,
        "DD/MM/YYYY"
      ).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
    }));
  }, [date]);

  return (
    <>
      <DescriptionMinMax
        title={"Intergrãos - Açúcar - BM&F - Alterando Mercado Futuro"}
        data={acucarOptions}
        date={formatDateHourToBR(cotacoesData.acucar_data)}
        datePickerOnChange={onChange}
        datePickerValue={date.year}
        setState={setCotacoesData}
        state={cotacoesData}
        stateKey={"acucar_valor"}
        onClickButton={() => saveData(cotacoesData)}
        isLoading={isLoading}
        hasError={hasError}
      />
    </>
  );
}
