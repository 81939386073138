import React, { useState } from "react";
import { getDataWithEndpoint } from "../../../functions/request";
import {
  formatDateHourToBR,
  updateData,
} from "../../../functions/functionsUtility";
import { Button, Input, Select } from "antd";
import { FaSave } from "react-icons/fa";
import { DescriptionsLayout } from "../../../layout";

const estados = [
  //   { value: "BA", label: "Bahia" },
  { value: "CE", label: "Ceará" },
  { value: "GO", label: "Goiás" },
  { value: "MT", label: "Mato Grosso" },
  { value: "MS", label: "Mato Grosso do Sul" },
  { value: "MG", label: "Minas Gerais" },
  //   { value: "PA", label: "Pará" },
  { value: "PR", label: "Paraná" },
  { value: "RJ", label: "Rio de Janeiro" },
  { value: "RS", label: "Rio Grande do Sul" },
  { value: "SC", label: "Santa Catarina" },
  { value: "SP", label: "São Paulo" },
  { value: "TO", label: "Tocantins" },
];

const cotacoesLabel = [
  { label: "Kg vivo:", value: "cot_kg" },
  { label: "Custo Prod.:", value: "cot_custo" },
];

export default function Fratop() {
  const [cotacoesData, setCotacoesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const aux = Object.entries(cotacoesData).map(([key, value], index) => {
    if (key !== "cot_data" && key !== "cot_uf") {
      const cotLabel = cotacoesLabel.find((label) => label.value === key);
      return {
        label: cotLabel ? cotLabel.label : key,
        children: (
          <Input
            className="input-number"
            value={value}
            disabled={isLoading}
            onChange={(event) => {
              const inputValue = event.target.value;
              const formattedValue = inputValue;
              const newData = {
                ...cotacoesData,
                [key]: formattedValue,
              };
              setCotacoesData(newData);
            }}
          />
        ),
        span: 2,
      };
    }
  });

  const items = [
    {
      label: "Estado",
      children: (
        <Select
          showSearch
          style={{ width: "100%" }}
          optionFilterProp="children"
          placeholder="Selecione um estado"
          filterOption={(input, option) =>
            (option?.label ?? "").includes(input)
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
          options={estados}
          loading={isLoading}
          onChange={(value) => {
            setIsLoading(true);
            setCotacoesData([]);
            getDataWithEndpoint(
              "fratop",
              `getFratopByUf?uf=${encodeURIComponent(value)}`
            )
              .then((res) => {
                setCotacoesData(res);
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
          allowClear={true}
        />
      ),
      span: 3,
    },
    {
      label: "Última Alteração",
      children: formatDateHourToBR(cotacoesData.cot_data),
      span: 3,
    },
  ];

  return (
    <>
      <DescriptionsLayout
        title={"Interfrango - Cotações Top"}
        items={[...items, ...aux.filter(Boolean)]}
        extra={
          <div style={{ padding: 10, display: "flex", gap: 10 }}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              icon={<FaSave />}
              disabled={isLoading}
              onClick={async () => {
                updateData({
                  ...cotacoesData,
                  endpoint: "updateFratop",
                  path: "fratop",
                });
              }}
            >
              Atualizar
            </Button>
          </div>
        }
      />
    </>
  );
}
