import React, { useEffect, useState } from "react";
import { Input, Select, Form, Button, Modal, message, Image } from "antd";
import TextArea from "antd/es/input/TextArea";
import { doRequest } from "../../../functions/request";
import { useForm } from "antd/es/form/Form";
import { videosMercados } from "../../../constants/constants";
import { FaSave } from "react-icons/fa";
import Dragger from "antd/es/upload/Dragger";
import { InboxOutlined } from "@ant-design/icons";
import { Content } from "antd/es/layout/layout";

function ModalAddVideo({ open, onCancel, data, onFinish }) {
  const [form] = useForm();
  const [formData, setFormData] = useState({});
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      vid_mercado: data?.vid_mercado,
      vid_titulo: data?.vid_titulo,
      vid_end: data?.vid_end,
      vid_hash: data?.vid_hash,
      vid_cdn: data?.vid_cdn,
      vid_thumb: data?.vid_thumb,
      vid_desc: data?.vid_desc,
    });
    setFormData(data);
    setFileList([]);
  }, [data, open]);

  const handleInsert = async (values) => {
    const fields = new FormData();
    const fileData = fileList[0];
    const data = {
      vid_mercado: values?.vid_mercado,
      vid_titulo: values?.vid_titulo,
      vid_desc: values?.vid_desc,
      vid_end: values?.vid_end,
      vid_hash: values?.vid_hash,
      vid_cdn: values?.vid_cdn,
      vid_thumb: fileData?.uid + "." + fileData?.name?.toString().split(".")[1],
    };

    fields.append("newData", JSON.stringify(data));
    fields.append("fileData", fileData);

    await doRequest("/video_analise/insertVideoAnalise", {
      method: "POST",
      body: fields,
    })
      .then((res) => {
        console.log(res);
        message.success("Video Inserido com Sucesso!");
        onCancel();
        onFinish();
      })
      .catch((err) => {
        console.log(err);
        message.error("Erro ao Inserir Video! Contate um Administrador.");
      });
  };

  const handleUpdate = async (values) => {
    const fields = new FormData();
    const fileData = fileList[0];
    const data = {
      vid_mercado: values?.vid_mercado,
      vid_titulo: values?.vid_titulo,
      vid_desc: values?.vid_desc,
      vid_end: values?.vid_end,
      vid_hash: values?.vid_hash,
      vid_cdn: values?.vid_cdn,
    };

    fields.append("newData", JSON.stringify(data));
    fields.append("fileData", fileData);

    await doRequest(`/video_analise/updateVideoAnalise/${formData.vid_id}`, {
      method: "PUT",
      body: fields,
    })
      .then((res) => {
        console.log(res);
        message.success("Vídeo Salvo com Sucesso!");
        onCancel();
        onFinish();
      })
      .catch((err) => {
        console.log(err);
        message.error("Erro ao Atualizar Video! Contate um Administrador.");
      });
  };

  const uploadProps = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
  };

  return (
    <>
      <Modal
        title={formData?.vid_id ? "Editar Video" : "Adicionar Novo Video"}
        open={open}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={onCancel}
        width={"80%"}
      >
        {open && (
          <Form
            form={form}
            layout="vertical"
            variant="filled"
            onFinish={(values) => {
              console.log(values);
              if (formData?.vid_id) {
                handleUpdate(values);
              } else {
                handleInsert(values);
              }
            }}
          >
            <Content style={{ display: "flex", gap: 10 }}>
              <Content
                style={{
                  width: "min-content",
                  backgroundColor: "#fafafa",
                  borderRadius: 8,
                  flexShrink: 0,
                  maxWidth: "500px",
                }}
              >
                <Form.Item
                  name={"vid_thumb"}
                  rules={[
                    {
                      required: formData?.vid_thumb ? false : true,
                      message: "Faça Upload de uma Capa!",
                    },
                  ]}
                  style={{ paddingInline: 10, paddingTop: 10 }}
                >
                  <Dragger
                    height={!formData?.vid_thumb && "200px"}
                    listType="picture"
                    maxCount={1}
                    {...uploadProps}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Arraste e Solte ou Selecione uma Capa{" "}
                      {formData?.vid_thumb
                        ? "para Substituir a Imagem Atual"
                        : "para a Área de Upload"}
                    </p>
                    <p className="ant-upload-hint">
                      tamanho máximo da imagem de 5mb
                    </p>
                    {formData?.vid_thumb && (
                      <Image
                        height={"50%"}
                        preview={false}
                        src={`${process.env.REACT_APP_THUMBNAILS_URL}${formData.vid_thumb}`}
                      />
                    )}
                  </Dragger>
                </Form.Item>
                <Form.Item
                  label={"Título"}
                  name={"vid_titulo"}
                  rules={[
                    { required: true, message: "Preencha o Campo Título!" },
                  ]}
                  style={{ paddingInline: 10 }}
                >
                  <Input value={data?.vid_titulo} />
                </Form.Item>
                <Form.Item
                  label={"Descrição do Video"}
                  name={"vid_desc"}
                  style={{ paddingInline: 10 }}
                >
                  <TextArea value={data?.vid_desc} />
                </Form.Item>
              </Content>
              <Content
                style={{
                  flexGrow: 1,
                  flexShrink: 0,
                }}
              >
                <Form.Item
                  label={"Mercado"}
                  name={"vid_mercado"}
                  rules={[{ required: true, message: "Escolha um Mercado!" }]}
                >
                  <Select value={data?.vid_mercado} options={videosMercados} />
                </Form.Item>
                <Form.Item label={"Endereço Vimeo"} name={"vid_end"}>
                  <Input value={data?.vid_end} />
                </Form.Item>
                <Form.Item label={"Endereço HASH"} name={"vid_hash"}>
                  <Input value={data?.vid_hash} />
                </Form.Item>
                <Form.Item label={"Endereço CDN"} name={"vid_cdn"}>
                  <Input value={data?.vid_cdn} />
                </Form.Item>
              </Content>
            </Content>
            <Form.Item style={{ marginTop: 10 }}>
              <Button icon={<FaSave />} type="primary" htmlType="submit">
                {formData?.vid_id ? "Salvar" : "Inserir"}
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
}

export default ModalAddVideo;
