import React, { useState, useEffect } from "react";
import { getDataWithEndpoint } from "../../../functions/request";
import {
  formatDateHourToBR,
  updateData,
} from "../../../functions/functionsUtility";
import { DescriptionMinMax } from "../../../layout";

const cortesType = [
  { label: "Bilis Conc-Kg", value: "bilis_conc" },
  { label: "Prazo", value: "p_bilis_conc" },
  { label: "Bilis Líq-Litro", value: "bilis_liq" },
  { label: "Prazo", value: "p_bilis_liq" },
  { label: "Calc Biliar FOB", value: "calc_biliar" },
  { label: "Prazo", value: "p_calc_biliar" },
  { label: "Casco/Chifre-Kg", value: "casco_chifre" },
  { label: "Prazo", value: "p_casco_chifre" },
  { label: "Crina-Kg", value: "crina" },
  { label: "Prazo", value: "p_crina" },
  { label: "Pelo/Orelha-Kg", value: "pelo_orelha" },
  { label: "Prazo", value: "p_pelo_orelha" },
];

export default function InterboisDiversos() {
  const [cotacoesData, setCotacoesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getDataWithEndpoint("boi_diversos", "getBoiDiversos")
      .then((data) => {
        setCotacoesData(data);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setHasError(err?.message ?? "Ocorreu um erro.");
        console.log(err);
      });
  }, []);

  return (
    <>
      <DescriptionMinMax
        title={"Interbois - Diversos"}
        onClickButton={async () => {
          updateData({
            ...cotacoesData,
            endpoint: "updateBoiDiversos",
            path: "boi_diversos",
          });
        }}
        buttonText={"Atualizar"}
        data={cortesType}
        defaultValueFromApi
        date={formatDateHourToBR(cotacoesData.boi_data)}
        state={cotacoesData}
        setState={setCotacoesData}
        isLoading={isLoading}
        hasError={hasError}
        hasValue={false}
      />
    </>
  );
}
