import React, { useState, useRef, useEffect } from "react";
import JoditEditor, { Jodit } from "jodit-react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { Content } from "antd/es/layout/layout";

const Editor = (props, { style }) => {
  function preparePaste(jodit) {
    jodit.e.on(
      "paste",
      (e) => {
        jodit.e.stopPropagation("paste");

        // Get the pasted HTML data
        const pastedHtml = Jodit.modules.Helpers.getDataTransfer(e).getData(
          Jodit.constants.TEXT_HTML
        );

        // Filter out unwanted characters like &nbsp;
        let filteredHtml = pastedHtml.replace(/&nbsp;/g, " ");
        filteredHtml = filteredHtml.replace(/=""/g, " ");

        // Insert the filtered HTML
        try {
          jodit.s.insertHTML(filteredHtml);
        } catch (error) {
          console.log(error);
        }

        return false;
      },
      { top: true }
    );
  }

  const editor = useRef(null);
  // const [content, setContent] = useState(props.content); // Defina o estado com o valor de props.content

  const config = {
    readonly: false,
  };

  useEffect(() => {
    Jodit.plugins.add("preparePaste", preparePaste);
  }, []);

  return (
    <Content
      style={{
        maxWidth: "1000px",
        width: "100%",
        marginInline: "auto",
        ...style,
      }}
    >
      <JoditEditor
        ref={editor}
        value={props.content}
        // Use props.content em vez de content
        config={config}
        tabIndex={1}
        onBlur={(newContent) => props.setContent(newContent)}
        // Use props.setContent para atualizar o estado no pai
      />
    </Content>
  );
};

export default Editor;
