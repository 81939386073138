import React, { useState } from "react";
import { request } from "../../../functions/request";
import { Button, Checkbox, Descriptions, Form, Input, Select } from "antd";
import {
  updateData,
  formatDateHourToBR,
} from "../../../functions/functionsUtility";
import { FaSave } from "react-icons/fa";
import { sojaStatesOptions } from "../../../constants/constants";

const sojaOptions = [
  { value: "soj_comp_min", label: "Preço Min. ao Compr:", span: 2 },
  { value: "min_cif", label: "CIF:", span: 1, type: "checkbox" },
  { value: "soj_comp", label: "Preço Máx. ao Comp:", cif: "max_cif", span: 2 },
  { value: "max_cif", label: "CIF:", span: 1, type: "checkbox" },
  { value: "soj_vend_min", label: "Preço Min. ao Vend:", span: 3 },
  { value: "soj_vend", label: "Preço Máx. ao Vend:", span: 3 },
];

export default function IntergraosSojaPorEstado() {
  const [cotacoesData, setCotacoesData] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  async function getData(value) {
    setIsLoading(true);
    const currentPathWithQuery = `/soja_cotacao/getLastSojaCotacaoByUf?soj_uf=${value}`;
    const response = await request(currentPathWithQuery, {
      method: "GET",
    });
    const data = {
      ...response,
      endpoint: "updateSojaCotacao",
      path: "soja_cotacao",
    };
    Object.keys(data).forEach((el, index) => {
      if (typeof data[el] === "number") {
        data[el] = data[el].toFixed(2);
        data[el] = data[el].replace(".", ",");
      }
    });
    setCotacoesData(data);
    setIsLoading(false);
  }

  return (
    <Form>
      <Descriptions
        title={"Intergrãos - Soja - Alterando Cotação Por Estado"}
        bordered
        extra={
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            icon={<FaSave />}
            disabled={isLoading}
            onClick={() => {
              console.log(cotacoesData);
              updateData(cotacoesData);
            }}
          >
            Atualizar
          </Button>
        }
        style={{ padding: 10 }}
      >
        <Descriptions.Item
          label={"Estado"}
          span={3}
          children={
            <Select
              style={{ width: "100%", marginTop: "10px" }}
              onChange={(value) => {
                setCotacoesData({ ...cotacoesData, soj_uf: value });
                getData(value);
              }}
              placeholder="Selecione o estado"
            >
              {sojaStatesOptions.map((el, index) => {
                return (
                  <Select.Option key={index} value={el.value}>
                    {el.label}
                  </Select.Option>
                );
              })}
            </Select>
          }
        />
        <Descriptions.Item
          label={"Última Alteração"}
          span={3}
          children={formatDateHourToBR(cotacoesData?.soj_data)}
        />
        {!isLoading &&
          sojaOptions.map((element) => (
            <Descriptions.Item
              label={element.label}
              span={element.span}
              children={
                element?.type === "checkbox" ? (
                  <Checkbox
                    checked={cotacoesData[element.value]}
                    onChange={(event) => {
                      const inputValue = event.target.checked;
                      const formattedValue = inputValue;
                      const newData = {
                        ...cotacoesData,
                        [element.value]: formattedValue,
                      };
                      setCotacoesData(newData);
                    }}
                  />
                ) : (
                  <Input
                    datatype=""
                    value={cotacoesData[element.value]}
                    onChange={(event) => {
                      const inputValue = event.target.value;
                      const formattedValue = inputValue.trim();
                      const newData = {
                        ...cotacoesData,
                        [element.value]: formattedValue,
                      };
                      setCotacoesData(newData);
                    }}
                  />
                )
              }
            />
          ))}
      </Descriptions>
    </Form>
  );
}
