import React from "react";
import { Modal } from "antd";
import JoditEditor from "jodit-react";

export function ConsultModal({ isOpen, onRequestClose, title, date, text }) {
  return (
    <Modal
      title={title}
      open={isOpen}
      onCancel={onRequestClose}
      width={"90vw"}
      centered
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      style={{ width: "100%" }}
      styles={{ body: { height: "80vh" } }}
    >
      <p>{date}</p>

      <JoditEditor
        value={text}
        config={{ readonly: true, height: 500 }}
        tabIndex={1}
      />
    </Modal>
  );
}
