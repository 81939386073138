// reducers/analiseReducer.js
const initialState = {
    analises: [],
  };
  
  const analisesReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_ANALISES':
        return {
          ...state,
          analises: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default analisesReducer;
  