import React from 'react';
import { Typography, Divider, Space } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const { Title, Paragraph } = Typography;

function Admin_Initial() {

  function toPascalCase(str) {
    console.log(str)
    console.log(user)
    if(str === undefined || str === null || str === '') return str;

    return str
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join('');
  }

  
  const user = useSelector(state => state.user.user);

  return (
    <div style={{ padding: '20px' }}>
      <Title level={3} style={{ marginBottom: '20px' }}>
      {user ? (
        <p>Olá, {toPascalCase(user?.username)}!</p>
      ) : (
        <p>Please log in to see your profile.</p>
      )}
      </Title>
      <Paragraph>
        Bem-vindo à administração do site! Utilize o menu lateral para acessar as diferentes opções disponíveis.
      </Paragraph>
      <Divider />
      <Space direction="vertical" size="middle">
        <div>
          <SmileOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
          <span>Fique à vontade para explorar as funcionalidades e gerenciar o conteúdo do site.</span>
        </div>
        <div>
          <SmileOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
          <span>
            Lembre-se de que todas as alterações realizadas aqui podem afetar diretamente o conteúdo e a experiência do
            usuário no site.
          </span>
        </div>
        <div>
          <SmileOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
          <span>
            Caso tenha alguma dúvida ou precise de suporte, não hesite em entrar em contato com a equipe de desenvolvimento.
          </span>
        </div>
      </Space>
    </div>
  );
}

export default Admin_Initial;
