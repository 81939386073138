import React, { useEffect, useState } from "react";
import { Button, Descriptions, Typography, message } from "antd";
import { request } from "../../functions/request";
import Aguarde from "../../components/aguarde/aguarde";
import { AiOutlineSearch } from "react-icons/ai";
import "./correcao.css";
import { Input } from "antd";
import { useNavigate } from "react-router-dom";
import { Content } from "antd/es/layout/layout";
import { FaSave } from "react-icons/fa";

function formatDate(dataArray) {
  let arrayData = [];
  let dataContent = Array.isArray(dataArray[0]) ? dataArray[0] : dataArray;
  if (
    typeof dataContent === "object" &&
    dataContent !== null &&
    !Array.isArray(dataContent)
  ) {
    const aux = [];
    for (const key in dataContent) {
      if (Array.isArray(dataContent[key]) && dataContent[key].length > 0) {
        dataContent[key][0].map((data) => {
          aux.push(data);
        });
      }
    }
    dataContent = aux;
  }
  if (!dataContent || dataContent === undefined) return dataContent;

  dataContent.map((data) => {
    arrayData.push({
      campodt: data.campodt,
      campo: data.campo,
      campouf: data.campouf,
    });
  });
  console.log(dataContent);
  return dataContent;
}

export default function CorrecaoSearch({ query, allUfs }) {
  const navigate = useNavigate();
  const [haveResult, setHaveResult] = useState(false);
  const [alteredTableData, setAlteredTableData] = useState([]);
  const [commodity, setCommodity] = useState([]);

  const [ordemQuery, setOrdemQuery] = useState(0);
  const [uf, setUf] = useState("");
  const params = new URLSearchParams(query);
  const ordem = params.get("ordem");

  useEffect(() => {
    const fetchTableData = async () => {
      try {
        setAlteredTableData([]);
        setHaveResult(false);
        let path = `/correcoes/getcorrecoes`;
        const data_start = params.get("data_start");
        const data_final = params.get("data_final");
        const ordem = params.get("ordem");
        const uf = params.get("uf") ?? "";
        if (data_start && data_final) {
          path += `?data_start=${data_start}&data_final=${data_final}`;
        }
        if (ordem) {
          path += `&ordem=${ordem}`;
        }

        setOrdemQuery(ordem);
        setUf(uf);

        if (uf?.toUpperCase() === "TODOS") {
          const ufsPromises = allUfs.map(async (ufItem) => {
            const ufPath = `${path}&uf=${ufItem.uf}`;
            const response = await request(ufPath, {
              method: "GET",
            });
            return formatDate(response);
          });

          const formattedData = await Promise.all(ufsPromises);

          const mergedData = formattedData.flat();
          console.log(mergedData);

          setAlteredTableData(mergedData);
          await getCommodity();
        } else {
          if (uf) {
            path += `&uf=${uf}`;
          }
          const response = await request(path, {
            method: "GET",
          });
          const formattedData = formatDate(response);
          setAlteredTableData(formattedData);

          await getCommodity();
        }

        setHaveResult(true);
      } catch (error) {
        console.error("Erro na requisição:", error);
        message.error("Ocorreu um erro inesperado! Tente novamente.");
      }
    };
    fetchTableData();
  }, [query]);

  // req. da commmodity graf escolhida
  const getCommodity = async () => {
    await request(`/correcoes/getCommodityByOrdem/${ordem}`, {
      method: "GET",
    })
      .then((response) => {
        setCommodity(response[0]);
      })
      .catch((error) => {
        console.error("Erro na requisição:", error);
      });
  };

  const modificarElemento = (novoValor, indice) => {
    const novoArray = [...alteredTableData];
    novoArray[indice] = novoValor;
    setAlteredTableData(novoArray);
  };

  const saveData = async (alteredTableData) => {
    let query = `salvarCorrecoes`;
    query += `?ordem=${encodeURIComponent(ordemQuery)}`;
    if (uf) {
      query += `&uf=${encodeURIComponent(uf)}`;
    }

    // for dos dados da tabela final
    for (let i = 0; i < alteredTableData.length; i++) {
      let data = alteredTableData[i];

      Object.keys(data).forEach((el) => {
        if (typeof data[el] === "string") {
          data[el] = data[el].replace(/,/g, ".");
        }
      });

      try {
        await request(`/correcoes/${query}`, {
          method: "POST",
          body: JSON.stringify(data),
        }).then(async (response) => {
          console.log(response);
        });
      } catch (error) {
        message.error(`Erro na requisição: ${error}`);
        throw error;
      } finally {
        const dataFormat = new Date(data.campodt).toLocaleDateString("pt-BR", {
          timeZone: "America/Sao_Paulo",
        });
        message.success(
          `[${dataFormat}${
            data.campouf && " - " + data.campouf
          }]: Dados atualizados com sucesso!`
        );
      }
    }
  };

  const returnPage = () => {
    navigate(`/correcoes`);
  };

  return (
    <Content
      className="slide-right"
      style={{ width: "100%", zIndex: 0, overflowY: "auto" }}
    >
      {haveResult ? (
        alteredTableData.length > 0 ? (
          <Content style={{ padding: 10 }}>
            <Descriptions
              title={commodity?.nome}
              bordered
              layout="vertical"
              extra={
                <Button
                  icon={<FaSave />}
                  type="primary"
                  onClick={() => {
                    saveData(alteredTableData);
                  }}
                >
                  Atualizar
                </Button>
              }
            >
              <Descriptions.Item label={"UF"} span={1} />
              <Descriptions.Item label={"Data"} span={1} />
              <Descriptions.Item label={`Valor`} span={1} />
              {alteredTableData.map((cotacao, index) => (
                <>
                  <Descriptions.Item
                    span={1}
                    children={<Typography>{cotacao.campouf}</Typography>}
                  />
                  <Descriptions.Item
                    span={1}
                    children={
                      <Typography>
                        {new Date(cotacao.campodt).toLocaleDateString("pt-BR", {
                          timeZone: "America/Sao_Paulo",
                        })}
                      </Typography>
                    }
                  />
                  <Descriptions.Item
                    span={1}
                    children={
                      <Input
                        className="input-number"
                        value={cotacao.campo}
                        onChange={(event) => {
                          const inputValue = {
                            campouf: cotacao.campouf,
                            campodt: cotacao.campodt,
                            campo: event.target.value,
                          };
                          modificarElemento(inputValue, index);
                        }}
                      />
                    }
                  />
                </>
              ))}
            </Descriptions>
          </Content>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "80%",
            }}
          >
            <h2>Não encontramos resultados para essa busca.</h2>
            <AiOutlineSearch size={48} color="#888" />
            <p>Tente pesquisar novamente com outros filtros.</p>
            <Button
              onClick={returnPage}
              style={{ width: "25%" }}
              className="button-primary"
            >
              <AiOutlineSearch />
              Nova busca
            </Button>
          </div>
        )
      ) : (
        <Aguarde />
      )}
    </Content>
  );
}
