import { createStore } from 'redux';
import userReducer from './reducer/UserReducer'; // Importe o seu reducer principal ou reducers combinados
import { combineReducers } from 'redux';
import analisesReducer from './reducer/AnalisesReducer';


const rootReducer = combineReducers({
    user: userReducer,
    analises: analisesReducer,
    });
    

const store = createStore(rootReducer); // Crie o store com o reducer ou reducers combinados
export default store;
