import React from "react";
import MaterialReactTable from "material-react-table";
import { Content } from "antd/es/layout/layout";
import { MRT_Localization_PT_BR } from "material-react-table/locales/pt-BR";
import { Result } from "antd";

export function ConsultTable({
  data,
  columns,
  isLoading,
  hasError,
  children,
  positionActionsColumn = "last",
  ...props
}) {
  return (
    <>
      <Content
        className={
          data.length > 0 || isLoading
            ? "slide-in-right"
            : hasError
            ? "slide-out-right"
            : "hidden"
        }
        style={{ zIndex: 0 }}
      >
        {children}
        <MaterialReactTable
          {...props}
          columns={columns}
          data={data ?? []}
          defaultColumn={{
            minSize: 5,
            maxSize: 160,
            size: 100,
          }}
          state={{ isLoading: isLoading }}
          enableColumnResizing
          enableColumnSorting
          enableRowActions
          enableFacetedValues
          enableRowVirtualization
          localization={MRT_Localization_PT_BR}
          positionActionsColumn={positionActionsColumn}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              header: "",
              size: 100,
            },
          }}
          muiTableHeadCellFilterTextFieldProps={{
            placeholder: "Filtrar",
          }}
          muiTablePaperProps={{
            style: {
              boxShadow: "none",
            },
          }}
        />
      </Content>
      {hasError && (
        <Content>
          <Result
            title="Não há registros a serem exibidos"
            subTitle="Utilize outros filtros de pesquisa"
            style={{ width: "100%" }}
          />
        </Content>
      )}
    </>
  );
}
