import React, { useState, useEffect } from "react";
import {
  formatDateHourToBR,
  updateData,
} from "../../../functions/functionsUtility";
import { getDataWithEndpoint } from "../../../functions/request";
import { DescriptionPeriodValue } from "../../../layout";

export default function DolarCotacao() {
  const [cotacoesData, setCotacoesData] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getDataWithEndpoint("dolar", "getDolar", "updateDolar")
      .then((data) => {
        setCotacoesData(data);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setHasError(err?.message ?? "Ocorreu um erro.");
        console.log(err);
      });
  }, []);

  return (
    <>
      <DescriptionPeriodValue
        title={"Cotações Dolar"}
        onClickButton={() =>
          updateData({
            ...cotacoesData,
            path: "dolar",
            endpoint: "updateDolar",
          })
        }
        date={formatDateHourToBR(cotacoesData.dol_data)}
        inputValue={cotacoesData["dol_valor"]}
        state={cotacoesData}
        setState={setCotacoesData}
        stateKey={"dol_valor"}
        isLoading={isLoading}
        hasError={hasError}
      />
    </>
  );
}
