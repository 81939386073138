import React, { useEffect, useState } from "react";
import { getData } from "../../../functions/request";
import { DescriptionPeriodValue } from "../../../layout";
import {
  formatDateHourToBR,
  saveData,
} from "../../../functions/functionsUtility";

export default function PetroleoIce() {
  const [cotacoesData, setCotacoesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getData("petroleo_ice", "getLastPetroleoIce", "insertPetroleoIce")
      .then((data) => {
        console.log(data);
        setCotacoesData(data);
      })
      .finally(() => {
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setHasError(err?.message ?? "Ocorreu um erro.");
        console.log(err);
      });
  }, []);

  return (
    <>
      <DescriptionPeriodValue
        title={"Petróleo - Brent - ICE Futures"}
        onClickButton={() => saveData(cotacoesData)}
        date={formatDateHourToBR(cotacoesData.data)}
        // inputValue={cotacoesData.fechamento}
        state={cotacoesData}
        setState={setCotacoesData}
        stateKey={"fechamento"}
        isLoading={isLoading}
        hasError={hasError}
      />
    </>
  );
}
